import React from "react";
import classes from './Advert.module.css';
import phone1 from './Images/Phone-1.png'
import phone2 from './Images/Phone-2.png'
import googlePlay from './Images/Google Play - eng.png';
import appStore from './Images/App Store - eng.png'


const Advert = () => {
    return(
        <div className={classes.container}>
            <div className={classes["phone-container"]}>
                <div className={classes.phone}><img src={phone2} alt=""/></div>
                <div className={classes.phone1}><img src={phone1} alt=""/></div>
            </div>
            <div className={classes["content-container"]}>
                <h4>Try the Alpha App today</h4>
                <p>Buy and sell with ease on the mobile app</p>
                <h6>Download the app on</h6>
                <div className={classes.store}>
                    <div><img src={googlePlay} alt=""/></div>
                    <div><img src={appStore} alt=""/></div>
                </div>
            </div>
            <div className={classes["content-container-2"]}>
                <h6>Download the app on</h6>
                <div className={classes.store2}>
                    <div><img src={googlePlay} alt=""/></div>
                    <div><img src={appStore} alt=""/></div>
                </div>
            </div>
        </div>
    )
}

export default Advert;