import React from 'react';
import './Services.css';
import van from './Images/van.png';
import telephone from './Images/telephone.png';
import card from './Images/credit-card.png';
import priceTag from './Images/price-tag.png';

const Services = () => {
    return(
        <div className='services-container'>
            <div className='services-body'>
                <div className='services'>
                    <div className='service-image-box'><img className='van' src={van} alt=""/></div>
                    <div className='service-text'>Fast Delivery</div>
                </div>
            </div>
            <div className='services-body'>
                <div className='services'>
                    <div className='service-image-box'><img src={telephone} alt=""/></div>
                    <div className='service-text'>Customer Support</div>
                </div>
            </div>
            <div className='services-body'>
                <div className='services'>
                    <div className='service-image-box'><img src={card} alt=""/></div>
                    <div className='service-text'>Seamless Payment</div>
                </div>
            </div>
            <div className='services-body'>
                <div className='services'>
                    <div className='service-image-box'><img src={priceTag} alt=""/></div>
                    <div className='service-text'>Best Prices</div>
                </div>
            </div>
        </div>
    )
}

export default Services;