import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import classes from './Loader.module.css';

const Loader=()=>{
  return(
    <div className={classes.container}>
      <div><FontAwesomeIcon icon={faSpinner}/></div>
      <div>Show more</div>
    </div>
  )
}

export default Loader;