// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductsYouLike_container__1tSq\\+{
    background-color: white;
    padding-top: 0.75rem;
    margin-bottom: 1rem;
}

.ProductsYouLike_heading__9Jwhl{
    border-left: 4px solid #72FFC9;
    /* margin-bottom: 1rem; */
}

.ProductsYouLike_heading__9Jwhl p{
    margin: 0;
    margin-left: 0.8rem;
    text-align: left;
    padding: 0;
    font-size: 0.8rem;
    color: #192150;
}

.ProductsYouLike_products__KFbNJ{
    display: grid;
    width: 89%;
    margin: auto;
    grid-template-columns: 20.97% 20.97% 20.97% 20.97% ;
    grid-column-gap: 5.38%;
    grid-row-gap: 1rem;
    padding: 0.75rem 0;
}

@media screen and (min-width: 768px) {
    .ProductsYouLike_heading__9Jwhl{
        border-left: 4px solid #72FFC9;
        margin-bottom: 2.3rem;
    }

    .ProductsYouLike_products__KFbNJ{
        display: grid;
        width: 100%;
        grid-template-columns: 22.8% 22.8% 22.8% 22.8%;
        grid-column-gap: 3%;
        grid-row-gap: 1.8rem;
    }
}

@media screen and (min-width: 1024px) {
    .ProductsYouLike_heading__9Jwhl p{
        font-size: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/ProductsYouLike.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,yBAAyB;AAC7B;;AAEA;IACI,SAAS;IACT,mBAAmB;IACnB,gBAAgB;IAChB,UAAU;IACV,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,YAAY;IACZ,mDAAmD;IACnD,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI;QACI,8BAA8B;QAC9B,qBAAqB;IACzB;;IAEA;QACI,aAAa;QACb,WAAW;QACX,8CAA8C;QAC9C,mBAAmB;QACnB,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container{\n    background-color: white;\n    padding-top: 0.75rem;\n    margin-bottom: 1rem;\n}\n\n.heading{\n    border-left: 4px solid #72FFC9;\n    /* margin-bottom: 1rem; */\n}\n\n.heading p{\n    margin: 0;\n    margin-left: 0.8rem;\n    text-align: left;\n    padding: 0;\n    font-size: 0.8rem;\n    color: #192150;\n}\n\n.products{\n    display: grid;\n    width: 89%;\n    margin: auto;\n    grid-template-columns: 20.97% 20.97% 20.97% 20.97% ;\n    grid-column-gap: 5.38%;\n    grid-row-gap: 1rem;\n    padding: 0.75rem 0;\n}\n\n@media screen and (min-width: 768px) {\n    .heading{\n        border-left: 4px solid #72FFC9;\n        margin-bottom: 2.3rem;\n    }\n\n    .products{\n        display: grid;\n        width: 100%;\n        grid-template-columns: 22.8% 22.8% 22.8% 22.8%;\n        grid-column-gap: 3%;\n        grid-row-gap: 1.8rem;\n    }\n}\n\n@media screen and (min-width: 1024px) {\n    .heading p{\n        font-size: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProductsYouLike_container__1tSq+`,
	"heading": `ProductsYouLike_heading__9Jwhl`,
	"products": `ProductsYouLike_products__KFbNJ`
};
export default ___CSS_LOADER_EXPORT___;
