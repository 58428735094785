import React from "react";
import './Search.css'

const Search = () => {
    return(
        <div className='search-bar-container'>
            <form className='search-bar'>
                <input name='search' type='search' placeholder="Search for an item..."/>
            </form>
        </div>
    )
}

export default Search;