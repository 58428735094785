import {useContext} from "react"
import {MdCancel} from "react-icons/md"
import { useSelector } from 'react-redux';
import { DisplayContext } from "../../../AppContexts/DisplayContext";
const TransactionFailed = ({product}) => {
    const { verify_order} = useSelector(state => state.VerifyOrderReducer);
    const {showMobileDeliveryOption} = useContext(DisplayContext);
    const slug = product && product.results && product.results.store && product.results.store.slug;
    const originalUrl =  window.location.origin;
    
    return (
        <div className={ showMobileDeliveryOption === 7 ? "success__message_container d-block" : "d-none"}>
             <div className="transaction_success">
                 <div className="transaction_content">
                      <div className="success_image">
                         <MdCancel className="failed_icon"/>
                      </div>
                      
                      <h4 className="success_text">Order is unsuccessfully {verify_order && verify_order.message}</h4>
                    
                      <p className="success_message">Sorry we are not able to complete this order, please try again.</p>
                 </div>
                 <div className="store-link">
                     <a href={`${originalUrl}/stores/${slug}`} target="_blank" rel="noreferrer">
                         View other products from the store
                     </a>
                 </div>
            </div>
            <div className="content_footer_mobile">
                <hr className="download_hr"/>
                <div className="download_placeholder">Download Alpha APP</div>
                <div className="download_app_links">
                    <div className="appstore">
                        <a href="https://apps.apple.com/us/app/alphashop/id1615734219" target="_blank" rel="noreferrer">
                             <img src="/appstore.png" alt="playstore" className="download_app"/>
                         </a>
                    </div>
                    <div className="playstore">
                      <a href="https://play.google.com/store/apps/details?id=com.thealphashops" target="_blank" rel="noreferrer">
                           <img src="/playstore.png" alt="playstore" className="download_app"/>
                       </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionFailed;