import classes from './RecentlyViewedItem.module.css';
import { CiLocationOn } from 'react-icons/ci'
import shirt from '../HomePage/Images/shirts.png'

const RecentlyViewedItem =()=>{
    return(
        <div className={classes.item}>
            <div className={classes.cover}>
                <div className={classes.image}><img src={shirt} alt=''/></div>
                <p className={classes.name}>Wireless Charger</p>
                <p className={classes.code}>Alpha code: <span>838901</span> </p>
                <div className={classes.location}>
                    <div><CiLocationOn/></div>
                    <p>Maruwa</p>
                </div>
                <p className={classes.price}>N 24,000</p>
            </div>
        </div>
    )
}

export default RecentlyViewedItem;