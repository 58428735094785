// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_outlet__rruGv{
    min-height: 50vh;
}

.Layout_footer-mobile__ctdFL{
    display: block;
}

.Layout_footer-large__XrLiJ{
    display: none;
}

@media screen and (min-width: 768px) {
    .Layout_footer-mobile__ctdFL{
        display: none;
    }

    .Layout_footer-large__XrLiJ{
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".outlet{\n    min-height: 50vh;\n}\n\n.footer-mobile{\n    display: block;\n}\n\n.footer-large{\n    display: none;\n}\n\n@media screen and (min-width: 768px) {\n    .footer-mobile{\n        display: none;\n    }\n\n    .footer-large{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outlet": `Layout_outlet__rruGv`,
	"footer-mobile": `Layout_footer-mobile__ctdFL`,
	"footer-large": `Layout_footer-large__XrLiJ`
};
export default ___CSS_LOADER_EXPORT___;
