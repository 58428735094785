import {FaTimes} from "react-icons/fa";
import { useDispatch } from 'react-redux';
import {MdCancel} from "react-icons/md";
import { useContext} from 'react';
import { DisplayContext } from "../../AppContexts/DisplayContext";
import { Product } from "../../Actions/Product";
import { clearPaymentAndOrder } from "../../Actions/CreateOrder";

const FailedModal = ({id}) => {
   const {showDeliveryOption, handleMenuSwitch} =  useContext(DisplayContext);
   const dispatch = useDispatch()


    return (
        <div className={showDeliveryOption === 8 ? "modals-container success-modal" : "modals-container-hide"}>
            <div className="card card-modal-content-success modal-success">
            <div className="success-cancel"><FaTimes className="cancel-success-modal" onClick={() => {handleMenuSwitch(0); dispatch(Product(id)); dispatch(clearPaymentAndOrder())}}/></div>
                 <div className="success-modal-content">
                      <div className="success-image">
                         <MdCancel className="failure-icon"/>
                      </div>
                      <h4 className="danger-text">Order is unsuccessful</h4>
                      <p className="failure-message">Sorry! We are not able to complete this order, please try again</p>
                 </div>
               <div className="modal-content-footer">
                  <hr />
                  <div className="content-footer">
                    <p>Download Alpha APP</p>
                    <div className="download-app-links">
                    <a href="https://apps.apple.com/us/app/alphashop/id1615734219" target="_blank" rel="noreferrer">
                           <img src="/appstore.png" alt="playstore" className="download-app  ios-download"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.thealphashops" target="_blank" rel="noreferrer">
                           <img src="/playstore.png" alt="playstore" className="download-app android-download"/>
                        </a>
                    </div>
                </div>
               </div>
            </div>
        </div>
    )
}

export default FailedModal;