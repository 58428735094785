import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authActions} from "../../store/auth";
import { useDispatch } from "react-redux";

import classes from "./Login.module.css";
import user from './user.png';
import key from './key.png';
import { useUserLoginMutation } from "../../store/apiSlice";
import { AppContext } from "../../AppContexts/AppContext";

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const user_type = 'user'

    const [userLogin, {data, isLoading, isError, isSuccess}] = useUserLoginMutation()
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const {recentUrl} = useContext(AppContext)
    const loginHandler= async(event)=>{
        event.preventDefault()
        await userLogin({email, password, user_type})
    }

    useEffect(() => {
        if(!isError && isSuccess){
            dispatch(authActions.Login())
            localStorage.setItem('token', JSON.stringify(data?.results?.token))
            localStorage.setItem('cred', JSON.stringify(data?.results?.user))
            if(recentUrl === 'cart'){
                navigate('/cart')
            } else{
                navigate('/')
            }
        }
    }, [isError, isSuccess])

    return (
        <div className={classes.container}>
            <div className={classes["login-container"]}>
                <div>
                    <h1 className={classes.heading}>Login</h1>
                </div>
                <div>
                    <form className={classes["login-form"]} onSubmit={loginHandler}>
                        <div className={classes["email-field"]}>
                            <label htmlFor="email">EMAIL ADDRESS</label><br/>
                            <img src={user} alt=""/>
                            <input className={classes["input-box"]} name="email" type="email" id="email" value={email} placeholder="Email address" onChange={e=> setEmail(e.target.value)}/><br/>
                        </div>
                        <div className={classes["password-field"]}>
                            <label htmlFor="password">PASSWORD</label><br/>
                            <img src={key} alt=""/>
                            <input className={classes["input-box"]} name="password" type="password" id="password" value={password} placeholder="Password" onChange={e=> setPassword(e.target.value)}/><br/>
                        </div>
                        <div className={classes["form-bottom"]}>
                            <div>
                                <input type="checkbox" id="remember"/>
                                <label htmlFor="remember">Remember Me</label>
                            </div>
                            <Link to="/reset" style={{ textDecoration: 'none' }}><div className={classes["forgot-pass"]}>Forgot Password?</div></Link>
                        </div>
                        <p>Don't have an account? <Link to="/signup">Sign up</Link></p>
                        <button type="submit" disabled={isLoading}>{isLoading? "LOGGING IN" : "SIGN IN"}</button>
                    </form>
                </div>
            </div>
            <div className={classes['signup-container']}>
                <h1>Create your account</h1>
                <p>Create your GIG Alpha buyer account in just a few clicks! <br/>You can register either using your e-mail address or through your Facebook account.</p>
                <Link to="/signup"><button className={classes["signup-btn"]}>CREATE AN ACCOUNT</button></Link>
            </div>
        </div>
    );
}
 
export default Login;