import { Outlet } from "react-router-dom";
import Navbar from "../shared/Navbar";
import Footer from "../shared/Footer";
import FooterMobile from "../shared/FooterMobile";
import classes from "./Layout.module.css";

const Layout =()=>{
    return(
        <>
            <Navbar/>
            <div className={classes.outlet}><Outlet/></div>
            <div className={classes["footer-large"]}><Footer/></div>
            <div className={classes["footer-mobile"]}><FooterMobile/></div>
        </>
    )
}

export default Layout;