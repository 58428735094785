// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClosestToYou_container__FddtH{
    background-color: white;
    padding-top: 0.75rem;
    /* margin-bottom: 1rem; */
}

.ClosestToYou_heading__aY87I{
    border-left: 4px solid #72FFC9;
}

.ClosestToYou_heading__aY87I p{
    margin: 0;
    margin-left: 0.8rem;
    text-align: left;
    padding: 0;
    font-size: 0.8rem;
    color: #192150;
}

.ClosestToYou_products__1XEoh{
    display: grid;
    width: 89%;
    margin: auto;
    grid-template-columns: 21.2% 21.2% 21.2% 21.2% ;
    grid-column-gap: 5.38%;
    grid-row-gap: 1rem;
    padding: 0.75rem 0;
}   

@media screen and (min-width: 768px) {
    .ClosestToYou_container__FddtH{
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        background-color: white;
    }
    
    .ClosestToYou_heading__aY87I{
        border-left: 4px solid #72FFC9;
        margin-bottom: 2.3rem;
    }
    
    .ClosestToYou_heading__aY87I p{
        margin: 0;
        margin-left: 0.8rem;
        padding: 0;
        font-size: 0.8rem;
        color: #192150;
    }
    
    .ClosestToYou_products__1XEoh{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 3%;
        grid-row-gap: 1.8rem;
        padding: unset;
    }
}

@media screen and (min-width: 1024px) {
    .ClosestToYou_heading__aY87I p{
        font-size: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/ClosestToYou.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,SAAS;IACT,mBAAmB;IACnB,gBAAgB;IAChB,UAAU;IACV,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,YAAY;IACZ,+CAA+C;IAC/C,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI;QACI,kBAAkB;QAClB,mBAAmB;QACnB,uBAAuB;IAC3B;;IAEA;QACI,8BAA8B;QAC9B,qBAAqB;IACzB;;IAEA;QACI,SAAS;QACT,mBAAmB;QACnB,UAAU;QACV,iBAAiB;QACjB,cAAc;IAClB;;IAEA;QACI,WAAW;QACX,aAAa;QACb,8BAA8B;QAC9B,mBAAmB;QACnB,oBAAoB;QACpB,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container{\n    background-color: white;\n    padding-top: 0.75rem;\n    /* margin-bottom: 1rem; */\n}\n\n.heading{\n    border-left: 4px solid #72FFC9;\n}\n\n.heading p{\n    margin: 0;\n    margin-left: 0.8rem;\n    text-align: left;\n    padding: 0;\n    font-size: 0.8rem;\n    color: #192150;\n}\n\n.products{\n    display: grid;\n    width: 89%;\n    margin: auto;\n    grid-template-columns: 21.2% 21.2% 21.2% 21.2% ;\n    grid-column-gap: 5.38%;\n    grid-row-gap: 1rem;\n    padding: 0.75rem 0;\n}   \n\n@media screen and (min-width: 768px) {\n    .container{\n        margin-top: 1.5rem;\n        margin-bottom: 1rem;\n        background-color: white;\n    }\n    \n    .heading{\n        border-left: 4px solid #72FFC9;\n        margin-bottom: 2.3rem;\n    }\n    \n    .heading p{\n        margin: 0;\n        margin-left: 0.8rem;\n        padding: 0;\n        font-size: 0.8rem;\n        color: #192150;\n    }\n    \n    .products{\n        width: 100%;\n        display: grid;\n        grid-template-columns: 1fr 1fr;\n        grid-column-gap: 3%;\n        grid-row-gap: 1.8rem;\n        padding: unset;\n    }\n}\n\n@media screen and (min-width: 1024px) {\n    .heading p{\n        font-size: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ClosestToYou_container__FddtH`,
	"heading": `ClosestToYou_heading__aY87I`,
	"products": `ClosestToYou_products__1XEoh`
};
export default ___CSS_LOADER_EXPORT___;
