// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating-n-name {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0;
}
.display-review-mb:first-child {
    margin-top: 0.5rem;
}
.display-review-mb:nth-child(n+2){
    margin-top: -1rem;
}
.rating-date-n-name {
    display: flex;
    flex-direction: column;
}
.rating-date-n-name .rate-name {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
}
.rating-date-n-name .rated-on {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 8px;
    line-height: 14px;
    letter-spacing: -0.743307px;
    color: #807D7D;
}
.display-review-mb .review-msg, .no-reviews-yet {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 10px;
    line-height: 14px;
    text-align: justify;  
    color: var(--alpha-80)
}
`, "",{"version":3,"sources":["webpack://./src/pages/Product/styles/rating.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,8BAA8B;IAC9B,SAAS;AACb;AACA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,6BAA6B;AACjC;AACA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,2BAA2B;IAC3B,cAAc;AAClB;AACA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB;AACJ","sourcesContent":[".rating-n-name {\n    display: flex;\n    align-items: baseline;\n    justify-content: space-between;\n    margin: 0;\n}\n.display-review-mb:first-child {\n    margin-top: 0.5rem;\n}\n.display-review-mb:nth-child(n+2){\n    margin-top: -1rem;\n}\n.rating-date-n-name {\n    display: flex;\n    flex-direction: column;\n}\n.rating-date-n-name .rate-name {\n    font-family: 'Circular Std';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 12px;\n    line-height: 14px;\n    letter-spacing: 0.5px;\n    color: var(--alpha-blue-back);\n}\n.rating-date-n-name .rated-on {\n    font-family: 'Circular Std';\n    font-style: normal;\n    font-weight: 450;\n    font-size: 8px;\n    line-height: 14px;\n    letter-spacing: -0.743307px;\n    color: #807D7D;\n}\n.display-review-mb .review-msg, .no-reviews-yet {\n    font-family: 'Circular Std';\n    font-style: normal;\n    font-weight: 450;\n    font-size: 10px;\n    line-height: 14px;\n    text-align: justify;  \n    color: var(--alpha-80)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
