import { useContext } from 'react';
import {FaTimes, FaBuilding} from "react-icons/fa"
import {RiEBike2Fill} from "react-icons/ri"
import { DisplayContext } from '../../AppContexts/DisplayContext';
const PickUpModal = () => {
    const {handleCheck, handleMenuSwitch, showDeliveryOption, closeModal, name, email, phone, locationValues} = useContext(DisplayContext)
   const pickUpAddress = locationValues && locationValues.Address
    return (
        <div className={showDeliveryOption === 5 ? "modals-container door-delivery-modal" : "modals-container-hide"}>
            <div className="card card-modal-content">
                <div className="card-header card__header"><span></span><span className="checkout-header">Checkout</span><span className="close-modal"><FaTimes  onClick={closeModal}/></span></div>
                <div className="card-body">
                    <p className="card__title">
                        Select a delivery method
                    </p>
                    <div className="delivery-container">
                       
                            <div className={showDeliveryOption === 5 ? "img-container-disabled delivery_icon_container" : "delivery_icon_container"}>
                                <RiEBike2Fill className={showDeliveryOption === 5 ? "media-img-disabled " : ""}/>
                            </div>
                            <label className="radio-c" htmlFor="home_delivery_details">
                                <div className="delivery-details">
                                    <div className={showDeliveryOption === 5 ? "delivery_title_name delivery-title-disabled" : "delivery_title name"}>Door delivery</div>
                                    <div className={showDeliveryOption === 5 ? "delivery-title-disabled delivery_title_address" : "delivery_title_address"}>Choose this option to have a partner rider deliver to your provided address</div>
                                </div>
                                <div>
                                <input type="radio" value="pick-up-value" name="delivery-details-pick" className="delivery_radio_disabled" onChange={handleCheck} disabled={showDeliveryOption === 5}/>
                                </div>
                            </label>
                        </div>
                        <div className="mobile-delivery-hr"></div>
                        <div className="delivery-container pickup-delivery-container">
                            <div className="icon_container">
                                <FaBuilding className="radio__icon"/>
                            </div>
                            <label className="radio__pickup" htmlFor="pickup_delivery_details">
                                <div className="delivery_details_holder">
                                    <div className="delivery_name">{name}</div>
                                    <div className="delivery_address ">{pickUpAddress}</div>
                                    <div className="delivery_email">{phone} | {email}</div>
                                
                                </div>
                       
                               <input type="radio" value="pick-up" checked name="delivery" className="delivery-checkbox-pickup" onChange={handleCheck} id="pickup_delivery_details"/>
                               <div className="radio_pickup_btn_btn"></div>
                            </label>
                       </div>
                  
                    <div className= "process-btn" onClick={() => handleMenuSwitch(9)}>
                       <button>Proceed to Payment</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PickUpModal;