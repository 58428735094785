// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message {
     min-height: 60vh;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     
}
.error-message .error-header {
     font-weight: bold;
}
.error-message p {
     font-size: 1.2rem;
}

.error-message .back-home-btn {
      width: 150px;
      height: 50px;
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      align-items: center;
      transition: all 0.5s ease-in;
      background: var(--alpha-blue-back);
      text-decoration: none;
      color: var(--alpha-white);
      font-size: 16px;
}

.error-message .back-home-btn:hover {
     border: 1px solid var(--alpha-blue-back);
     background: transparent;
     color: var(--alpha-blue-back);
     gap: 0.85rem;
     width: 160px;
}`, "",{"version":3,"sources":["webpack://./src/helpers/error.css"],"names":[],"mappings":"AAAA;KACK,gBAAgB;KAChB,aAAa;KACb,sBAAsB;KACtB,mBAAmB;KACnB,uBAAuB;;AAE5B;AACA;KACK,iBAAiB;AACtB;AACA;KACK,iBAAiB;AACtB;;AAEA;MACM,YAAY;MACZ,YAAY;MACZ,aAAa;MACb,uBAAuB;MACvB,WAAW;MACX,mBAAmB;MACnB,4BAA4B;MAC5B,kCAAkC;MAClC,qBAAqB;MACrB,yBAAyB;MACzB,eAAe;AACrB;;AAEA;KACK,wCAAwC;KACxC,uBAAuB;KACvB,6BAA6B;KAC7B,YAAY;KACZ,YAAY;AACjB","sourcesContent":[".error-message {\n     min-height: 60vh;\n     display: flex;\n     flex-direction: column;\n     align-items: center;\n     justify-content: center;\n     \n}\n.error-message .error-header {\n     font-weight: bold;\n}\n.error-message p {\n     font-size: 1.2rem;\n}\n\n.error-message .back-home-btn {\n      width: 150px;\n      height: 50px;\n      display: flex;\n      justify-content: center;\n      gap: 0.5rem;\n      align-items: center;\n      transition: all 0.5s ease-in;\n      background: var(--alpha-blue-back);\n      text-decoration: none;\n      color: var(--alpha-white);\n      font-size: 16px;\n}\n\n.error-message .back-home-btn:hover {\n     border: 1px solid var(--alpha-blue-back);\n     background: transparent;\n     color: var(--alpha-blue-back);\n     gap: 0.85rem;\n     width: 160px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
