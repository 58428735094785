import React, { useState, useRef, useEffect } from 'react';
import classes from './Carousel.module.css';
import dghsjk from '../../assets/dghsjk 1.png'
import greater from './Images/greater.png';
import less from './Images/less.png';
import CarouselItem from './CarouselItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

function CarouselFadeExample() {
  const [activeIndex, setActiveIndex] = useState(0)
  const [auto, setAuto] = useState(0)

  const carouselContent = [
    {
      id: 1,
      image: dghsjk
    },
    {
      id: 2,
      image: dghsjk
    },
    {
      id: 3,
      image: dghsjk
    }
  ]

  const updateIndex =(newIndex)=>{
    if(newIndex < 0){
      newIndex = 0
    } else if(newIndex >= carouselContent.length){
      newIndex = carouselContent.length - 1
    }
      
    setActiveIndex(newIndex)
  }

  const timerRef = useRef(null)

  useEffect(() => {
      if(timerRef.activeIndex){
          clearTimeout(timerRef.activeIndex);
      }
      timerRef.activeIndex = setTimeout(() => {
          if (auto) {
              setActiveIndex(c=>c+1);
          }
      }, 2000);
      return ()=>clearTimeout(timerRef.activeIndex);
  }, [auto, activeIndex]);

  return (
    <div className={classes.carousel}>
      <div className={classes.inner1}>
      <Swiper modules={[Pagination, Autoplay]}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                autoplay={<CarouselItem/> ? {
                    delay: 4000,
                    disableOnInteraction: true
                }: {
                    delay: 4000,
                    disableOnInteraction: true
                }}
                >{carouselContent.map((con) =><SwiperSlide key={con.id}><CarouselItem  item={con}/></SwiperSlide>)}</Swiper>
      </div>
      <div className={classes.inner2} style={{transform: `translate(-${activeIndex * 100}%)`}}>
        {carouselContent.map((con) =><CarouselItem key={con.id} item={con}/>)}
      </div>
      <div className={classes['carousel-buttons']}>
        <button onClick={()=>{updateIndex(activeIndex - 1)}}><img src={less} alt='less'/></button>
        <button onClick={()=>{updateIndex(activeIndex + 1)}}><img src={greater} alt='greater'/></button>
      </div>
    </div>
  );
}

export default CarouselFadeExample;