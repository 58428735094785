import { combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
// import { composeWithDevTools } from "redux-devtools-extension";
import { LoginReducer } from '../Reducer/LoginReducer';
import { GetProductReducer } from '../Reducer/GetProductReducer';
import { GetHomeDeliveryReducer } from '../Reducer/GetHomeDelivery';
import { LocalStationReducer } from '../Reducer/AgilityLocalStation';
import { PaymentMethodReducer } from '../Reducer/PaymentMethod';
import { GigPickUpReducer } from '../Reducer/GigPickupLocation';
import { ShipmentPriceReducer,ShipmentPriceByQty } from '../Reducer/ShipmentPrice';
import { InitiateTransactionReducer } from '../Reducer/InitiateTransaction';
import { GetServiceCenterDetailsReducer } from '../Reducer/GetServiceCenterDetails';
import { VerifyOrderReducer } from '../Reducer/VerifyOrder';
import { GetStoreReducer } from '../Reducer/GetStore';
import { CreateOrderReducer } from '../Reducer/CreateOrder';
import { RegisterGuestReducer } from '../Reducer/RegisterGuest';
import { TrackOrderReducer } from '../Reducer/TrackOrder';
import { WaitListCreate } from '../Reducer/WaitList';
import { RefundPayment,BankList } from '../Reducer/RefundPayment';
import { searchAlphaProducts } from '../Reducer/SearchAlphaProducts';
import { getEta } from '../Reducer/GetEta';
import { NODE_ENV } from "../helpers/getUrl";
import { allCategories } from '../Reducer/CategoryReducer/Category';
import { getSubscription } from '../Reducer/Subscription/Subscription';
import cartSlice from "./cart-slice";
import auth from "./auth";
import userSlice from "./user-slice";
import { cartApi, productsApi, userApi } from './apiSlice';
import { configureStore } from "@reduxjs/toolkit";

const middleware = NODE_ENV === "production" ? [thunk] : [thunk, logger];

const reducers = combineReducers({
     LoginReducer,
     GetProductReducer,
     GetHomeDeliveryReducer,
     LocalStationReducer, 
     PaymentMethodReducer,
     GigPickUpReducer,
     ShipmentPriceReducer,
     ShipmentPriceByQty,
     InitiateTransactionReducer,
     GetServiceCenterDetailsReducer,
     VerifyOrderReducer,
     CreateOrderReducer,
     GetStoreReducer, 
     RegisterGuestReducer,
     TrackOrderReducer,
     WaitListCreate,
     RefundPayment,
     BankList,
     searchAlphaProducts,
     getEta,
     allCategories,
     getSubscription,
     cartSlice,
     userSlice,
     auth,
     [productsApi.reducerPath]: productsApi.reducer,
     [userApi.reducerPath]: userApi.reducer,
     [cartApi.reducerPath]: cartApi.reducer
});

// const store = createStore(
//   reducers,
//   {},
//   composeWithDevTools(applyMiddleware(...middleware))
// );

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware)=> getDefaultMiddleware().concat(productsApi.middleware, userApi.middleware, cartApi.middleware)
})

export default store;
