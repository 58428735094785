import { useState } from "react";
import classes from "./ResetPasswordOtp.module.css"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/user-slice";

const ResetPasswordOtp = () => {
    const [otp, setOtp] = useState(new Array(6).fill(""));

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        //Focus next input
        if (element.nextSibling && element.value !== '') {
            element.nextSibling.focus();
        }

        if (element.previousSibling && element.value === '') {
            element.previousSibling.focus();
        }    
        
    };

    const newOTP = otp.join("")
    localStorage.setItem('otp', newOTP)

    const submitOtp=()=>{
        // dispatch(userActions.getOtp(newOTP))
        navigate("/new-password")
    }

    return(
        <div className={classes.page}>
            <div className={classes.container}>
                <h2>Enter the OTP sent to your email</h2>

                {otp.map((data, index) => {
                    return (
                        <input
                            className={classes["otp-field"]}
                            type="text"
                            name="otp"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={e => handleChange(e.target, index)}
                            onFocus={e => e.target.select()}
                        />
                    );
                })}

                <p>OTP Entered - {otp.join("")}</p>
                <button
                        onClick={e => setOtp([...otp.map(v => "")])}
                    >
                        Clear
                    </button>
                    <button type='submit' onClick={submitOtp}>
                        Proceed
                    </button>
            </div>
        </div>
        )
}

export default ResetPasswordOtp;