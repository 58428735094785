import { GET_SHIPMENT_PRICE_BY_QUANTITY_FAIL, GET_SHIPMENT_PRICE_BY_QUANTITY_REQUEST, GET_SHIPMENT_PRICE_BY_QUANTITY_SUCCESS, SHIPMENT_PRICE_GET_FAIL, SHIPMENT_PRICE_GET_REQUEST, SHIPMENT_PRICE_GET_SUCCESS } from "../constants/constants";
import { getUrl } from "../helpers/getUrl";
import { getAuthToken } from "../utility/utility";

export function ShipmentPrice(dataToSubmit) {
   const token = getAuthToken() && JSON.parse(getAuthToken())
   return async (dispatch) => {
    try {
       dispatch({type: SHIPMENT_PRICE_GET_REQUEST});
      //  const res = await fetch(`${getUrl("https://order.thealphashops.com/api/v1","https://orderservice.click/api/v1")}/order/guest/shipping_price`, {
       const res = await fetch(`https://order.thealphashops.com/api/v1/order/shipping_price`, {
           method: "POST",
           headers: {
               "Content-Type": "application/json",
               ACCEPT: "application/json",
               "Authorization" : "Bearer " + token
           },
           body: JSON.stringify(dataToSubmit)
       })
       const  data  = await res.json()
       if(data.results.Code === "404"){
          dispatch({type: SHIPMENT_PRICE_GET_FAIL, payload: data.results.ShortDescription})
       }else if(data.results.Code === "500"){
         dispatch({type: SHIPMENT_PRICE_GET_FAIL, payload: data.results.ShortDescription})
       }else {
        dispatch({type: SHIPMENT_PRICE_GET_SUCCESS, payload: data})    
       }
    
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data.message
       : error.message;
       dispatch({type: SHIPMENT_PRICE_GET_FAIL, payload: message})
    }
 }
}


export function ShipmentPriceByQty({id, qty}) {
   return async (dispatch) => {
    try {
       dispatch({type: GET_SHIPMENT_PRICE_BY_QUANTITY_REQUEST});
       const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1","https://productservice.click/api/v1")}/product/price_by_quantity?productId=${id}&quantity=${qty}`, {
           method: "GET",
           headers: {
               "Content-Type": "application/json",
               ACCEPT: "application/json"
           },
       })
       const  data  = await res.json()
       if(data.results.Code === "404"){
          dispatch({type: GET_SHIPMENT_PRICE_BY_QUANTITY_FAIL, payload: data.results.ShortDescription})
       }else if(data.results.Code === "500"){
         dispatch({type: GET_SHIPMENT_PRICE_BY_QUANTITY_FAIL, payload: data.results.ShortDescription})
       }else {
        dispatch({type: GET_SHIPMENT_PRICE_BY_QUANTITY_SUCCESS, payload: data})    
       }
    
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data.message
       : error.message;
       dispatch({type: SHIPMENT_PRICE_GET_FAIL, payload: message})
    }
 }
}