import classes from './CartItem.module.css';
import { HiPlusSmall, HiMinusSmall } from "react-icons/hi2"
import { CiLocationOn } from 'react-icons/ci'
import bin from '../../assets/Bin.png'
import { useSelector, useDispatch } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';
import { Product } from '../../Actions/Product';
import numbro from 'numbro';
import { usePriceByQuantityQuery, useRemoveCartItemMutation, useUpdateCartMutation } from '../../store/apiSlice';
import { GetColorName } from 'hex-color-to-color-name';
import { getCartId } from '../../utility/utility';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContexts/AppContext';
import Geocode from "react-geocode";
import { Spinner } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

const CartItem = ({item}) => {
  const [removeModal,setRemoveModal] = useState(false)
  const modalRef = useRef(null)
  const [removeCartItem, {data, isLoading, isError, error, isSuccess}] = useRemoveCartItemMutation()
  const [updateCart, {data: updateData, isLoading: updateIsLoading, isError: updateIsError, error: updateError, isSuccess: updateIsSuccess}] = useUpdateCartMutation()
  const {normalizeCase} = useContext(AppContext)    
  // const cartId = JSON.parse(getCartId())
  const cartId = getCartId()
  let {front_view, size, alphaCode, price, productName, color, _id, productId, locality, lat, long, quantity, quantity_in_stock} = item
  const {data: priceData, isLoading: priceLoading} = usePriceByQuantityQuery({productId, quantity})

  const removeFromCart =async()=>{
    await removeCartItem({
        cartId: cartId,
        item_id: _id
    })
  }

  const increaseProdQty = async()=>{
    await updateCart({
      cartId,
      productId,
      quantity: quantity+=1
    })
  }

  const decreaseProdQty = async()=>{
    await updateCart({
      cartId,
      productId,
      quantity: quantity-=1
    })
  }

  const newprice = priceData?.results;

  const closeRemoveModal = () => {
       modalRef?.current?.close();
       setRemoveModal(false)
  }

  useEffect(()=>{
    if(updateData && updateIsSuccess && !updateIsError){
        console.log(updateData, updateIsSuccess);
    }
  }, [updateData, updateIsSuccess, updateIsError]);

  useEffect(() => {
       if(removeModal){
         modalRef?.current?.showModal()
       }else {
          modalRef?.current?.close()
       }
  }, [removeModal])

  useEffect(()=>{
    if(isSuccess){
      modalRef?.current?.close()
    }
  }, [isSuccess])

  return(
      <>
    <div className={classes.container}>
      <div className={classes.cover}>
        <div className={classes.under}>
          <div className={classes.image}>
            <img src={front_view} alt=''/>
          </div>
          <div className={classes.detail}>
            <h5><Link to={`/${productId}`}>{normalizeCase(productName)}</Link></h5>
            {size.size !== ''? <p>Size: {size.size}</p>: <p>Color: {GetColorName(color)}</p>}
            <p>Alpha code: {alphaCode}</p>
            <span className={classes.location}>
              <div><CiLocationOn/></div>
              <div>{locality}</div>
            </span>
          </div>
        </div>
        <div className={classes.detail2}>
          {priceLoading? <Spinner size='sm'/>:<h5>&#x20A6; {numbro(newprice).format({thousandSeparated: true})}</h5>}
          <div className={classes.switch}>
            <button className={quantity === 1 ? "btn-minus-desktop item-add-desktop-disabled" : "btn-minus-desktop"} disabled={quantity <= 1} onClick={()=> {decreaseProdQty(); quantity--}}><HiMinusSmall/></button>
            <span> {updateIsLoading? <Spinner size='sm'/>: quantity} </span>
            <button disabled={quantity === quantity_in_stock} onClick={()=> {increaseProdQty(); quantity++}}><HiPlusSmall/></button>
          </div>
          <div className={classes.remove} onClick={() => setRemoveModal(true)}>
            <span>Remove item</span>
            <span><img src={bin} alt=''/></span>
          </div>
        </div>
      </div>
    </div>
    {removeModal && <dialog ref={modalRef} className={classes.modal}>
                      <div className={classes['remove__modal']}>
                            <p>Are you sure you want to remove this item?</p>
                            <div className={classes.buttons}>
                              <button onClick={closeRemoveModal}>Cancel</button>
                              <button onClick={removeFromCart}><FaTrash />{" "} Remove item</button>
                            </div>
                      </div>
                    </dialog>}
    
    </>
  )
};

export default CartItem;