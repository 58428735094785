export const getAuthToken =()=>{
    const token = localStorage.getItem('token') && localStorage.getItem('token');
    return token
}

export const getUserCred =()=>{
    const userCred = localStorage.getItem('cred') && localStorage.getItem('cred');
    return userCred
}

export const getOtp =()=>{
    const otp = localStorage.getItem('otp') && localStorage.getItem('otp');
    return otp
}

export const getCartId =()=>{
    const cartId =  localStorage.getItem('cart_id') && localStorage.getItem('cart_id');
    return cartId
}