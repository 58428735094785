import { TRACK_ORDER_FAIL, TRACK_ORDER_REQUEST } from "../constants/constants"
import { TRACK_ORDER_SUCCESS } from './../constants/constants';

export const TrackOrderReducer = (state={}, action) => {
     switch(action.type){
         case TRACK_ORDER_REQUEST:
             return {
                 tracking_loading: true
            };
         case TRACK_ORDER_SUCCESS:
             return {
                 ...state,
                 tracking_loading: false,
                 tracked_data: action.payload
             }
        case TRACK_ORDER_FAIL:
            return {
                ...state,
                tracking_loading: false,
                tracked_error: action.payload
            }
        default:
        return {
            state
        }
     }
}