import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/store';
import AppContextProvider from './AppContexts/AppContext';
import DisplayContextProvider from './AppContexts/DisplayContext';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "../node_modules/video-react/dist/video-react.css";
import "swiper/swiper.min.css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "react-tooltip/dist/react-tooltip.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <AppContextProvider>
    <DisplayContextProvider>
      <App />
    </DisplayContextProvider>
    </AppContextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
