import { CLEAR_ETA_DATA, GET_ETA_FAIL, GET_ETA_REQUEST, GET_ETA_SUCCESS } from "../constants/constants"
import { getUrl } from "../helpers/getUrl"

export function GetEta(dataToSubmit){
      return async (dispatch) => {
          dispatch({type: GET_ETA_REQUEST})
          try {
            const res = await fetch(`${getUrl("https://user.thealphashops.com/api/v1","https://userservice.click/api/v1")}/setting/eta`, {
                method: "POST",
                headers: {
                   "Content-Type": "application/json",
                   ACCEPT: "application/json"
                },
                body: JSON.stringify(dataToSubmit)
            });
            const data = await res.json();
            if(data.error){
                return dispatch({type: GET_ETA_FAIL, payload: data.message})
            }
            dispatch({type: GET_ETA_SUCCESS, payload: data}) 
          } catch (error) {
            const message = error.response && error.response.data
              ? error.response.data.message
              : error.message;
                dispatch({type: GET_ETA_FAIL, payload: message})
          }
      }
}


export const clearEtaState = () => async (dispatch) => {
  dispatch({type: CLEAR_ETA_DATA})
}