// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetPasswordOtp_page__X3dlm{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.ResetPasswordOtp_container__kewYm{
    width: 80%;
    margin: auto;
    text-align: center;
}

.ResetPasswordOtp_container__kewYm h2{
    margin-bottom: 1rem;
}

.ResetPasswordOtp_otp-field__tYFJX{
    width: 1.5rem;
    margin-right: .5rem;
}

button{
    margin-right: 1rem;
    border-radius: 0.625rem;
    padding: 0.5rem;
}

@media screen and (min-width: 768px) {
    .ResetPasswordOtp_otp-field__tYFJX{
        width: 4%;
        margin-right: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/vaidationPage/ResetPasswordOtp.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI;QACI,SAAS;QACT,kBAAkB;IACtB;AACJ","sourcesContent":[".page{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    min-height: 100vh;\n}\n\n.container{\n    width: 80%;\n    margin: auto;\n    text-align: center;\n}\n\n.container h2{\n    margin-bottom: 1rem;\n}\n\n.otp-field{\n    width: 1.5rem;\n    margin-right: .5rem;\n}\n\nbutton{\n    margin-right: 1rem;\n    border-radius: 0.625rem;\n    padding: 0.5rem;\n}\n\n@media screen and (min-width: 768px) {\n    .otp-field{\n        width: 4%;\n        margin-right: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `ResetPasswordOtp_page__X3dlm`,
	"container": `ResetPasswordOtp_container__kewYm`,
	"otp-field": `ResetPasswordOtp_otp-field__tYFJX`
};
export default ___CSS_LOADER_EXPORT___;
