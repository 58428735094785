import {GET_CATEGORY_FAIL, GET_CATEGORY_REQUEST, GET_CATEGORY_SUCCESS} from "../constants/constants";
import { getUrl } from '../helpers/getUrl';

export function ListCategories () {
    return async (dispatch) => {
       dispatch({type: GET_CATEGORY_REQUEST});
       try {
         const res = await fetch(`https://product.thealphashops.com/api/v1/category/guest/list`, {
             method: "GET",
             headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
             }
         })

         const data = await res.json();
         if(data.error) return dispatch({type: GET_CATEGORY_FAIL, payload: data.message})
        dispatch({type: GET_CATEGORY_SUCCESS, payload: data.results})     
         
       }catch (error) {
       const message =
       error.response && error.response.data
         ? error.response.data.message
         : error.message;
         dispatch({type: GET_CATEGORY_FAIL, payload: message})
       }
   }
}
