import { INITIATE_TRANSACTION_FAIL, INITIATE_TRANSACTION_REQUEST, INITIATE_TRANSACTION_SUCCESS } from "../constants/constants";
import { getAuthToken } from "../utility/utility";
import { getUrl } from './../helpers/getUrl';

export function InitiateTransaction (dataToSubmit) {
  const token = getAuthToken() && JSON.parse(getAuthToken())
     return async (dispatch) => {
        dispatch({type: INITIATE_TRANSACTION_REQUEST});
        try {
          const res = await fetch(`${getUrl("https://order.thealphashops.com/api/v1","https://orderservice.click/api/v1")}/transaction/initiate`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": "Bearer " + token
              },
              body: JSON.stringify(dataToSubmit)
          })
           
          const  data = await res.json();
            if(data.error){
                return dispatch({type: INITIATE_TRANSACTION_FAIL, payload: data.message})
            }
          dispatch({type: INITIATE_TRANSACTION_SUCCESS, payload: data})    
        } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
          dispatch({type: INITIATE_TRANSACTION_FAIL, payload: message})
        }
    }
}