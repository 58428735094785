// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchLarge_search-bar__3JRMC{
    position: relative;
    height: 3rem;
}

.SearchLarge_search-bar__3JRMC input{
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    outline: none; 
    border: none;
    padding-left: 2.5rem;
    font-size: 0.8rem;
}

.SearchLarge_search-bar__3JRMC img{
    position: absolute;
    width: 1rem;
    transform: translate(50%, -50%);
    top: 1.5rem;
    left: 0.5rem;
}

.SearchLarge_search-bar__3JRMC div{
    position: absolute;
    transform: translate(50%, -50%);
    top: 1.5rem;
    color: gray;
    margin-right: auto;
    right: 4rem;
    font-size: 0.8rem;
}

.SearchLarge_categories__rPN7r select{
    border: none;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/shared/SearchLarge.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,YAAY;IACZ,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,+BAA+B;IAC/B,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,+BAA+B;IAC/B,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".search-bar{\n    position: relative;\n    height: 3rem;\n}\n\n.search-bar input{\n    width: 100%;\n    height: 100%;\n    border-radius: 0.5rem;\n    outline: none; \n    border: none;\n    padding-left: 2.5rem;\n    font-size: 0.8rem;\n}\n\n.search-bar img{\n    position: absolute;\n    width: 1rem;\n    transform: translate(50%, -50%);\n    top: 1.5rem;\n    left: 0.5rem;\n}\n\n.search-bar div{\n    position: absolute;\n    transform: translate(50%, -50%);\n    top: 1.5rem;\n    color: gray;\n    margin-right: auto;\n    right: 4rem;\n    font-size: 0.8rem;\n}\n\n.categories select{\n    border: none;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-bar": `SearchLarge_search-bar__3JRMC`,
	"categories": `SearchLarge_categories__rPN7r`
};
export default ___CSS_LOADER_EXPORT___;
