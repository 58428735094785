import React from "react";
import classes from './FooterMobile.module.css';
import { IoIosArrowUp } from 'react-icons/io'

const FooterMobile=()=>{
    return(
        <div className={classes.container}>
            <div className={classes.top}>
                <div><IoIosArrowUp/></div>
                <div>BACK TO TOP</div>
            </div>
            <div className={classes.bottom}>All rights reserved</div>
        </div>
    )
}

export default FooterMobile;