import { createSlice } from "@reduxjs/toolkit";

const initialState = {isAuthenticated: false}

// initialState: {token: '', isLoggedIn: false, isLoggedOut: true},
  

const authSlice = createSlice({
    name: 'Authentication',
    initialState,
    reducers: {
        Login (state, action){
            state.isAuthenticated = true
            console.log(state.isAuthenticated)
        },
        Logout (state, action){
            state.isAuthenticated = false
            localStorage.removeItem('first-name')
        }
    }
})

// export const userLogin =(email, password)=>{
//     return async()=>{
//         try{
//             const response = await fetch('https://userservice.click/api/v1/auth/login', {
//                 method: 'POST',
//                 headers: {'Content-Type': 'application/json'},
//                 body: JSON.stringify({
//                     email: email,
//                     password: password,
//                     user_type: "user"
//                 })
//             })
//             const data = await response.json()
//             console.log(data)
//             if(data.error){
//                 throw new Error('Something went wrong')
//             } else{
                
//             }
    
//             const token = data.results.token
//             const firstName = data.results.user.first_name
    
//             localStorage.setItem('token', token)
//             localStorage.setItem('first-name', firstName)
            
//         } catch (error) {
//             const message = error.response && error.response.data? error.response.data.message: error.message;
//         }
//     }
// }

export const authActions = authSlice.actions;

export default authSlice.reducer;