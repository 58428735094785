import React, {createContext, useState, useRef, useMemo} from 'react';
import { patterns, validateForDestopDoorDelivery, validateForDestopPickup, ValidateForMobileDoorDelivery, ValidateForMobilePickup } from '../utility/Validations';

export const AppContext = createContext(null)

const AppContextProvider = ({children}) => {
    const [mobileCheckoutModal, setMobileCheckoutModal] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [ itemLimit, setItemLimit] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [recentUrl, setRecentUrl] = useState("")
    const [copied, setCoppied] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [socialMediaShareOpen,setSocialMediaShareOpen] = useState(false);
    const [openDownload, setOpenDownload] = useState(false)
    const [cartId, setCartId] = useState()
    const [page,setPage] = useState(1)
    const about = useRef();
    const faq = useRef();
    const price = useRef();
    const phoneDoorDeliveryRef = useRef(null);
    const emailDoorDeliveryRef = useRef(null);
    const phonePickupRef = useRef(null);
    const emailPickupRef = useRef(null);
    const mobileeDoorDeliveryPhoneRef = useRef(null);
    const mobileeDoorDeliveryEmailRef = useRef(null);
    const mobilePickupPhoneRef = useRef(null);
    const mobilePickupEmailRef = useRef(null);
    

    const keywords = new Set( ["for","and", "nor", "but", "or", "yet", "so", "if", "although", "though", "after", "before", "because", "as if", "unless", "until", "when", "while"]);
    
    const scrollToSection = (elementRef) => {
        window.scrollTo({
             top: elementRef.current.offsetTop,
             behavior: "smooth"
        })
  }
  const slugify = (str) => {
      return str.trim().replace(/\s+/g, "-").toLowerCase()
  }
  const removeSlug = (str) => {
    return str.trim().replace(/-+/g, " ").toLowerCase()
}
function normalizeCase(t) {
  const words = t?.toLowerCase().split(' ');
  return words?.map(w => 
    keywords.has(w) 
     ? w
     : w[0] 
        ? w[0].toUpperCase() + w.slice(1)
        : ''        
    ).join(' ');
}
    const numberSeperator = (num) => {
        return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


  
    const handleErrorForDesktopDoorDelivery = (fieldValue, allValue) => {
        validateForDestopDoorDelivery(fieldValue, patterns[allValue])
    }
    const handleErrorForDesktopPickup = (fieldValue, allValue) => {
        validateForDestopPickup(fieldValue, patterns[allValue])
    }
    const handleErrorForMobileDoorDelivery = (fieldValue, allValue) => {
        ValidateForMobileDoorDelivery(fieldValue, patterns[allValue])
    }
    const handleErrorForMobilePickup = (fieldValue, allValue) => {
        ValidateForMobilePickup(fieldValue, patterns[allValue])
    }
 
    function genPassword() {
        var chars = "0123456789abcdefghijklmnopqrstuvwxyz@#$%&ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var passwordLength = 12;
        var password = "";
     for (var i = 0; i <= passwordLength; i++) {
       var randomNumber = Math.floor(Math.random() * chars.length);
       password += chars.substring(randomNumber, randomNumber +1);
      }
        return password
     }
     function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
    const copyToClipBoard = async copyMe => {
        try {
            if(navigator?.clipboard?.writeText){
                await navigator.clipboard.writeText(copyMe);
            }
          setCopySuccess('Copied!');
          setCoppied(true)
          setTimeout(() => {
             setCopySuccess("")
             setCoppied(false)
          }, 3000)
        } catch (err) {
          setCopySuccess('Failed to copy!');
          setTimeout(() => {
            setCopySuccess("");
            setCoppied(false)
         }, 3000)
        }
      };
      const shareOnSocialMedia = async (url, text) => {
        try {
            await navigator.share({
                title: document.title,
                url : url,
                text : text});
          
          } catch (err) {
            console.error("Share failed:", err.message);
          }
    }
    const appMemoization = useMemo(() => ({numberSeperator, cartId, setCartId, mobileCheckoutModal, setMobileCheckoutModal, phoneDoorDeliveryRef, emailDoorDeliveryRef, phonePickupRef, emailPickupRef, handleErrorForDesktopDoorDelivery, handleErrorForDesktopPickup, handleErrorForMobileDoorDelivery, handleErrorForMobilePickup, mobileeDoorDeliveryPhoneRef, mobileeDoorDeliveryEmailRef, mobilePickupPhoneRef, mobilePickupEmailRef, genPassword,scrollToSection,about,faq,price, isEmpty,page,setPage,copyToClipBoard,copySuccess,copied,openShareModal, setOpenShareModal,socialMediaShareOpen,setSocialMediaShareOpen, shareOnSocialMedia,slugify,removeSlug,normalizeCase, itemLimit, setItemLimit,pageNumber, setPageNumber,openDownload, setOpenDownload,recentUrl, setRecentUrl}), [
      mobileCheckoutModal, setMobileCheckoutModal,copySuccess, setCopySuccess,copied, setCoppied,openShareModal, setOpenShareModal,socialMediaShareOpen,setSocialMediaShareOpen,page,setPage,about,faq,price,slugify,removeSlug,normalizeCase, itemLimit, setItemLimit,pageNumber, setPageNumber,openDownload, setOpenDownload,recentUrl, setRecentUrl
    ])
    const {Provider} = AppContext
  return <Provider value={appMemoization}>
      {children}
  </Provider>;
};
export default AppContextProvider