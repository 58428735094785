// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage_container__D\\+gkS{
    width: 80%;
    margin: 3rem auto;
    text-align: center;
}

.ErrorPage_container__D\\+gkS h2, h3{
    margin-bottom: 1rem;
}

.ErrorPage_container__D\\+gkS p{
    color: black;
    margin: auto;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/ErrorPage.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".container{\n    width: 80%;\n    margin: 3rem auto;\n    text-align: center;\n}\n\n.container h2, h3{\n    margin-bottom: 1rem;\n}\n\n.container p{\n    color: black;\n    margin: auto;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ErrorPage_container__D+gkS`
};
export default ___CSS_LOADER_EXPORT___;
