import React, { useState, useLayoutEffect } from 'react';
import classes from './ClosestToYou.module.css';
import ClosestToYouList from "./ClosestToYouList";
import { useClosestProductsQuery } from '../../store/apiSlice';
// import { useGetAllProductsQuery } from '../../store/apiSlice';

const ClosestToYou=()=>{
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(12)
    const [position, setPosition] = useState({lat: 0, long: 0})
    const {lat, long} = position
    const {data, isError, isLoading, isSuccess, isFetching} = useClosestProductsQuery({lat, long, page, limit})
    const successCallback = (position) => {
        setPosition({lat: position.coords.latitude, long: position.coords.longitude})
      };
      
      const errorCallback = (error) => {
        console.log(error);
      };
      
      console.log('lat:', lat, 'long:', long);

    useLayoutEffect(()=>{
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }, [])

    console.log(position);
    // const {data, isLoading, isError, isFetching, isSuccess} = useGetAllProductsQuery({page, limit})

    const products = data?.results?.docs

    console.log('close: ',data);

    return(
        <div className={classes.container}>
            <div className={classes.heading}>
                <p>Closest to you</p>
            </div>
            <div className={classes.products}>
                {isLoading && <p>Loading...</p>}
                {isFetching && <p>Fetching...</p>}
                {isError && <p>Error</p>}
                {isSuccess && products.length > 0 && products.map(product=><ClosestToYouList key={product._id} id={product._id} image={product.front_view} name={product.productName} amount={product.price} alphaCode={product.alphaCode} location={product.neighborhood} locality={product.locality} address={product.address}/>)}
            </div>
        </div>
    )
}

export default ClosestToYou;