import { useContext} from "react"
import { FaArrowLeft} from "react-icons/fa"
import { useSelector, useDispatch } from 'react-redux';
import { usePaystackPayment } from 'react-paystack';
import { useState, useEffect } from "react";
import { VerifyOrder } from './../../../Actions/VerifyOrder';
import { DisplayContext } from "../../../AppContexts/DisplayContext";
import { InitiateTransaction } from './../../../Actions/InitiateTransaction';
import AppDownloadSection from "./AppDownloadSection";
import { clearPaymentAndOrder, CreateOrder } from "../../../Actions/CreateOrder";
import { AppContext } from "../../../AppContexts/AppContext";
import { getUrl } from "../../../helpers/getUrl";
import { Product } from "../../../Actions/Product";

const Payment = ({id,price, product, results, channelName,vehicleType}) => {
    const { email, phone, handleMobileMenuSwitch, qty,  address, stationId, senderLat,senderLong, senderName, senderPhoneNumber, senderStationId, senderAddress, itemWeight, itemName, receiverCoordinate, senderLocality, size, color, setShowMobileDeliveryOption, showMobileDeliveryOption, userId, buyerId, fullName,recieverLGA,recieverCity, handleCheck, check} = useContext(DisplayContext)
    const {numberSeperator} = useContext(AppContext)
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [trxResult, setTrxResult] = useState("")
    const {payment_method_loading, payment_method_error, payment_method} = useSelector(state => state.PaymentMethodReducer);
    const {  shipment_price_error, shipment_price} = useSelector(state => state.ShipmentPriceReducer)
   const shipmentPrice = shipment_price && shipment_price.results && shipment_price.results.Object;
   const {initiate_loading, transaction_initiate,initiate_success} = useSelector(state => state.InitiateTransactionReducer);
   const { verify_order_loading, verify_order_success,verify_order_error} = useSelector(state => state.VerifyOrderReducer)
   const {create_order_error, create_order_success,create_order_loading} = useSelector(state => state.CreateOrderReducer)
   const shippingCost = shipmentPrice && Math.ceil(shipmentPrice.GrandTotal)
   const mainDeliveryFee = shipmentPrice && shipmentPrice.Merchants && shipmentPrice.Merchants[0] && Math.ceil(shipmentPrice.Merchants[0].DeliveryPrice)
   const totalAmount = (price * qty) + shippingCost;
   const storeId = product && product.results && product.results.store && product.results.store.storeId;
   const trnxRef = transaction_initiate && transaction_initiate.results && transaction_initiate.results.trxnRef; 
   const prodId = results && results._id;
   const prodImage = results && results?.front_view;

   
   const dispatch = useDispatch()
   
   const getTrxId = paymentMethod.name === "flutterwave" ? trxResult.tx && trxResult.tx.id : "";
   const getTrxRef = paymentMethod.name === "flutterwave" ? trxResult.tx && trxResult.tx.txRef : trxResult && trxResult.reference

   const dataToSubmit = {
    "IsHomeDelivery": true,
    "src_channel": channelName || "",
    "Merchants": [
      {
        "user_id": userId,
        "store_id": storeId,
        "ShippingFee": shippingCost,
        "DestinationStationId": stationId,
        "LGA": recieverLGA,
        "SenderCity": senderLocality,
        "ReceiverCity": recieverCity,
        "CustomerCode": getUrl("ACC001164", "IND265936"),
        "PaymentType": "Cash",
        "CashOnDeliveryAmount": 0,
        "IsCashOnDelivery": false,
        "account_type": "guest",
        "PreShipmentItems": [
          {
            "buyer_id": buyerId,
            "ItemName": itemName,
            "ShippingFee": shippingCost,
            "ItemValue": price,
            "ItemType": "Normal",
            "product_id": prodId,
            "Quantity": qty,
            "ShipmentType": "Regular",
            "size": size || "",
            "SpecialPackageId": "0",
            "Value": price,
            "product_image": prodImage,
            "Weight": itemWeight,
            "WeightRange": "0",
            "color": color || ""
          }
        ],
        "SenderAddress": senderAddress,
        "SenderLocality": senderLocality,
        "SenderLocation": {
            "Latitude": senderLat &&  senderLat.toString(), 
            "Longitude": senderLong && senderLong.toString()
        },
        "SenderName": senderName,
        "SenderPhoneNumber": senderPhoneNumber,
        "SenderStationId": +senderStationId
      }
    ],
    "PaymentType": "Cash",
    "PickupOptions": "HOMEDELIVERY",
    "ReceiverAddress": address,
    "ReceiverEmail": email,
    "ReceiverLocation": {
        "Latitude": receiverCoordinate?.lat && receiverCoordinate.lat.toString(), 
        "Longitude": receiverCoordinate?.lng && receiverCoordinate.lng.toString()
    },
    "device_platform" : "guest_app",
    "ReceiverName": fullName,
    "ReceiverPhoneNumber": phone,
    "ReceiverStationId": stationId,
    "DepartureStationId" : +senderStationId,
    "totalCost": totalAmount,
    "trxnRef": trnxRef && trnxRef,
    "VehicleType":  vehicleType?.toUpperCase() || "BIKE"
  }
    
  const paystackConfigMobile = {
    reference: transaction_initiate && transaction_initiate.results && transaction_initiate.results.trxnRef,
    email: email,
    amount: Math.ceil(totalAmount * 100),
    publicKey: process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PAYSTACK_PUBKEY_LIVE : process.env.REACT_APP_PAYSTACK_PUBKEY,
    // channels: ['card']
};

    const createNewOrder = (payment) => {
        setPaymentMethod(payment);  
  } 
    
    // you can call this function anything
    const onPaystackSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      setTrxResult(reference)
    };

  
    // you can call this function anything
    const onPaystackClose = () => {
      dispatch(Product(id))
      dispatch(clearPaymentAndOrder())
      handleMobileMenuSwitch(0);
      setPaymentMethod("")
    }
    const initializePaystackPayment =  usePaystackPayment(paystackConfigMobile);
    

    const allPaymentMethod = payment_method && payment_method.results;

const handlePaystackPayment = () => {
  if(verify_order_success && create_order_success){
      return
  }else {
    dispatch(CreateOrder(dataToSubmit)); 
  }

}
   
useEffect(() => {
  if(!verify_order_success && create_order_success &&  check === "paystack-home-mobile"){
    initializePaystackPayment(onPaystackSuccess, onPaystackClose)
}
}, [verify_order_success,create_order_success, check])

      useEffect(() => {
        if(getTrxRef && paymentMethod.name === "Paystack"){
            dispatch(VerifyOrder( {
              "trxnRef": trnxRef && trnxRef,
              "id": getTrxId, 
              "totalCost": totalAmount
            }))
          
        }
      
    }, [getTrxRef, paymentMethod.name, trnxRef, totalAmount, getTrxId]);
    
    

    return (
         <>
            {verify_order_success && initiate_success ? setShowMobileDeliveryOption(6) : verify_order_error ? setShowMobileDeliveryOption(7) : null}
            
       
         <div className={showMobileDeliveryOption === 5 ? "d-block mobile-payment-style" : "d-none"}>
            {
                 initiate_loading === false && create_order_error &&
                      <div className="container alert alert-danger" role="alert">
                          {create_order_error}
                      </div>
            }
            {verify_order_loading ?  <div className="loader-page-mobile">
                    <div 
                      className="spinner-border shipment-spinner text-center"
                      role="status"
                    ></div>
                    <p className= "text-center text-dark" >
                      Verifying your order... 
                    </p></div> : <>
                <div className="card__header_pay"><div onClick={() => handleMobileMenuSwitch(3)}><FaArrowLeft /></div><span className="checkout-header">Make Payment</span></div>
                <div className="test">
                    <p className="payment_title_card p-3">
                         Choose payment method
                    </p>
                          {
                                payment_method_error &&
                                    <div className="container alert alert-danger" role="alert">
                                        {payment_method_error}
                                    </div>
                             }
                             {
                                shipment_price_error !== "The Mapping of Route to Zone does not exist" &&
                                    <div className="container alert alert-danger" role="alert">
                                        {shipment_price_error}
                                    </div>
                             }
                             {payment_method_loading ? <p className="text-center">Loading payment gateways...</p> :
                             <>
                        <div className="payment__type__mobile" >
                        {allPaymentMethod && allPaymentMethod.map(payment => (
                           <label  key={payment._id}  className="payment_section_mobile" htmlFor={payment.name === "flutterwave" ? "flutterwave__radio" : payment.name==="Paystack" ? "paystack__radio" : payment.name ==="Cash on Delivery" ? "cod__radio d-none" : ""}>
                             {payment.name === "Paystack" && <img src={payment.image_url} alt={payment.name} />}
                            {payment.name === "Cash on Delivery" && <img src={payment.image_url} alt={payment.name} className="d-none"/>}
                            {payment.name === "Paystack" && <p className="paystack-text">{payment.name}</p>}
                            {payment.name === "Cash on Delivery" && <p className="cod-text d-none">{payment.name}</p>}
                            <input type="radio" name="payment-now" className={payment.name === "Cash on Delivery" ? "cod_input_input d-none" : payment.name === "flutterwave" ? "flutterwave_input_input d-none" : payment.name==="Paystack" ? "paystack_input_input" : "d-none"} onClick={() => {createNewOrder(payment)}} onChange={(e) => {dispatch(InitiateTransaction({amount: totalAmount, payment_gateway: payment._id, payment_title: `Purchase of ${itemName}`})); handleCheck(e)}} id={payment.name === "flutterwave" ? "flutterwave__radio d-none" : payment.name==="Paystack" ? "paystack__radio" : payment.name === "Cash on Delivery" ? "cod__radio d-none" : ""} value="paystack-home-mobile"/>
                            <div className={ payment.name === "flutterwave" ?  "flutterwave_radio_button d-none" : payment.name === "Paystack" ? "paystack_radio_button" : payment.name === "Cash on Delivery" ? "cod_radio_button d-none" : ""}></div>
                            {" "}
                            
                            
                            </label>
                        ))}

                    </div>               
                    <div>
                        <hr className="hr-color payment-hr mt-3"/>
                        <div className= "card__info">
                            <div className="items">
                                <p className="item-key">Product Price</p>
                                <p className="item-value">NGN {numberSeperator(price)}</p>
                            </div>
                            <div className="items">
                                <p className="item-key">Quantity</p>
                                <p className="item-value">{qty}</p>
                            </div>
                          
                            <div className="items">
                                <p className="item-key">Delivery Fee</p>
                                {shippingCost !== mainDeliveryFee ? <div className="delivery-fee-values"><p className="item-value main-delivery-price">&#8358;{numberSeperator(mainDeliveryFee)}</p><p className="item-value">&#8358;{numberSeperator(shippingCost)}</p></div> : <div><p className="item-value">&#8358;{numberSeperator(mainDeliveryFee)}</p></div> }
                            </div>
                            <div className="items">
                                <p className="item-key total-key">Total</p>
                                {totalAmount && <p className="item-value total-value">NGN {numberSeperator(totalAmount)}</p> } 
                            </div>
                        </div> 

                        <div>
                       <div className= { paymentMethod.name !== "Paystack" || check !== "paystack-home-mobile" || trnxRef === "" ? "payment__btn-homedelivery__disabled" : "payment-btn card__info"}>
                              <button disabled={paymentMethod.name !== "Paystack"  || initiate_loading || check !== "paystack-home-mobile"} onClick={handlePaystackPayment}>{create_order_loading ? "Please wait..." : "Pay"}</button> 
                          </div>
                        </div>    
                    </div>
                   </>
                   }
                </div>
     
                </>
               }
            {/* <AppDownloadSection /> */}
        </div>
        </>
    )
}

export default Payment;