import { useState, useEffect, useContext, useCallback } from "react"
import {FaTimes, FaArrowLeft} from "react-icons/fa"
import { useSelector, useDispatch } from 'react-redux';
import { clearEtaState, GetEta } from "../../Actions/GetEta";
import { GetServiceCenterDetails } from "../../Actions/ServiceCenterDetails";
import { ShipmentPrice } from "../../Actions/ShipmentPrice";
import { DisplayContext } from "../../AppContexts/DisplayContext";
import { getUrl } from "../../helpers/getUrl";
import { MdInfoOutline } from "react-icons/md";
import { formatDate } from "../../helpers/formatDate";
import { AppContext } from "../../AppContexts/AppContext";

const SelectLocation = ({showDeliveryOption, handleCheck, check, handleMenuSwitch, closeModal, email, phone, local_station_error, local_station, local_station_loading, senderLat,senderLong, senderName, senderPhoneNumber, senderStationId,senderAddress, itemWeight, itemName,  price, qty, senderLocality, locationValues, setLocationValues,vehicleType,product}) => {
    const { fullName, search, setSearch,setStationId, setRecieverCity,setRecieverLGA,setDestinationServiceCenterId} = useContext(DisplayContext)
    const {isEmpty} = useContext(AppContext)
    const [pickupId, setPickupId] = useState(null)
    const [loadShipment, setLoadShipment] = useState(false)
    const getPickupLocations = local_station && local_station.results && local_station.results.Object;

    const {service_center_loading, service_center} = useSelector(state => state.GetServiceCenterDetailsReducer);
    const { shipment_price_loading, shipment_price_error, shipment_price} = useSelector(state => state.ShipmentPriceReducer);
    const {get_eta_loading,eta} =useSelector (state => state.getEta)
    const departure_state = product?.results?.locality === "Lekki" ? "Lagos" : product?.results?.locality;

   

    const getId = pickupId && JSON.parse(pickupId)
    const id = getId && getId.StationId && Number(getId.StationId);  
    const destination_state = getId && getId.StateName === "FCT" ? "Abuja - FCT" : getId && getId.StateName
    const dispatch = useDispatch()
    const serviceCenter = service_center && service_center.results && service_center.results.Object;

    const filteredCenters =
    serviceCenter &&
    serviceCenter.filter((center) =>
      center.Name?.toLowerCase().includes(search?.toLowerCase()) || center.Address?.toLowerCase().includes(search?.toLowerCase())
    );
  const dataToDisplay = search ? filteredCenters :  serviceCenter;
  const checkShipmentCode = shipment_price && shipment_price.code;
  const checkShipCodeSuccess = shipment_price && shipment_price.results && shipment_price.results.Code;

    const dataToSubmit = {
        "ReceiverName": fullName,
        "ReceiverPhoneNumber": phone,
        "ReceiverEmail": email,
        "ReceiverAddress": locationValues && locationValues.Address,
        "ReceiverStationId": locationValues && locationValues.StationId,
        "ReceiverLocation": {
            "Latitude": locationValues && locationValues?.Latitude, 
            "Longitude": locationValues && locationValues?.Longitude
        },
        "Merchants": [
            {
                "CustomerCode": getUrl("ACC001164", "IND265936"),  
                "SenderName": senderName,
                "SenderPhoneNumber": senderPhoneNumber,
                "SenderStationId": +senderStationId,
                "SenderLocality": senderLocality,
                "SenderAddress": senderAddress, 
                "SenderLocation": {
                    "Latitude": senderLat &&  senderLat.toString(), 
                    "Longitude": senderLong && senderLong.toString()
                },
                "PaymentType": "Cash",
                "PreShipmentItems": [
                    { "SpecialPackageId": "0", "Quantity": qty && qty.toString(), "Weight": itemWeight, "ItemType": "Normal", "WeightRange": "0", "ItemName": itemName, "Value": price, "ShipmentType": "Regular" }
                ]
            },
            
        ],
        "PickupOptions": "HOMEDELIVERY",
        "IsHomeDelivery": false,
        "VehicleType": vehicleType?.toUpperCase() || "BIKE",
        "PaymentType": "Cash"
    }
    const getShippingFee = () => {
        if(check !== ""){
            dispatch(ShipmentPrice(dataToSubmit));
        }
    }
   const getEta = () => {
        if(destination_state !== undefined  && departure_state !== undefined && check !== ""){
            dispatch(GetEta({ "departure_state": departure_state, 
            "destination_state":  destination_state}))
    }
   }
    useEffect(() => {
        dispatch(GetServiceCenterDetails(id))
    }, [dispatch, id]);

    useEffect(() => {
         if((serviceCenter && serviceCenter.length > 0) && shipment_price_error){
            dispatch(ShipmentPrice(dataToSubmit))
         }
    }, [shipment_price_error]);
    useEffect(() => {
        setStationId(locationValues?.StationId)
        setRecieverLGA(locationValues?.Name);
        setRecieverCity(locationValues?.City)
     }, [locationValues?.StationId, locationValues?.City,setStationId,setRecieverLGA,setRecieverCity,locationValues?.Name]);


   
   const goToPayment = useCallback(() => {
    if(checkShipmentCode === 200 &&  checkShipCodeSuccess === "200" && showDeliveryOption === 3){
        handleMenuSwitch(9)
    }
}, [checkShipCodeSuccess,checkShipmentCode, showDeliveryOption]);
useEffect(() => {
    goToPayment()
}, [goToPayment])
    return (
        <div className={showDeliveryOption === 3 ? "modals-container-pickup" : "modals-container-hide"}>
            {local_station_error &&  
                <div className="container alert alert-danger" role="alert" style={{zIndex: "9999999999"}}>
                    {local_station_error}
                </div>
            }

            {local_station_loading ? <div style={{ zIndex: "9999999999999"}}
                      className="spinner-border shipment-spinner"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div> :
            <div className="card card-modal-content-pickup">
                <div className="card-header card__header"><span onClick={() => {handleMenuSwitch(1);dispatch(clearEtaState())}}><FaArrowLeft /></span><span className="checkout-header">SELECT PICKUP LOCATION</span><span className="close-modal"><FaTimes  onClick={closeModal}/></span></div>
                <div className="card-body">
                <select className="select-elem form-select" onChange={(e) => {setPickupId(e.target.value); dispatch(clearEtaState())}}>
                        <option>Select a GIG Service Center</option>
                        {getPickupLocations && getPickupLocations.map((pickup, index) => {
                            return <option key={index} value={JSON.stringify(pickup)}>{pickup.StationName}</option>
                        })}     
                    </select>
                  
                   
                    {serviceCenter === undefined || serviceCenter?.length === 0 ? "" :  <div className="search-location-input mt-2">
                     
                     <input type="text" placeholder="Search for Service centers..." onChange={(e) => setSearch(e.target.value)} value={search} className="service-center-search"/>
                   </div>}
                   {/* {shipment_price_error && <p className="text-danger text-center">{shipment_price_error}</p>} */}
                    {service_center_loading ? 
                        <div className="shipment-spinner-desktop-sc">
                            <div className="spinner-border shipment-spinner " role="status">
                            <span className="visually-hidden">Loading...</span>
                            </div> 
                        </div>
                        : 
                        <>
                           {serviceCenter && serviceCenter.length === 0 ? <p className="text-center text-danger mt-2">Please we do not have a pickup station here, select another service center</p> : dataToDisplay && dataToDisplay.map((center, index) => (
                               <div  key={index} className="mt-0">
                                    <label className="media-elem-pickup" htmlFor="pickup_stations">    
                                         <div className="delivery-details-pickup">
                                               <div className="delivery-title">{center.Name}</div>
                                               <div className="delivery-note service-station-address">{center.Address}</div>
                                               {phone &&  <span className="station-number d-block"><strong>Phone number: </strong>{phone}</span>}  
                                         </div>
                                         <input type="radio" name="pickup-address" className="address-checkbox-pickup"  onClick={() => {setLocationValues(center);setLoadShipment(true);getEta(); setDestinationServiceCenterId(center.ServiceCentreId)}} onChange={handleCheck} id="pickup_stations" value="pickup-station"/>    
                                 
                                    </label>
                                <hr />
                                  </div>
                           ))}
                        </>
                    
                    }
                    {get_eta_loading && destination_state !== undefined && departure_state !== undefined && <div   className="loader-style-eta">                           
                                <div className="spinner-border loader-spinner-eta" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="loading-text">Please wait...</div>
                            </div>}
                            {
                               !isEmpty(eta) && serviceCenter?.length > 0 && check !== "" && !service_center_loading && destination_state !== undefined && departure_state !== undefined ?  <div className="eta-display"><MdInfoOutline /> {`Expected time of arrival is ${formatDate(eta.results)}`}</div> : null
                            }
                    {
                       serviceCenter !== undefined && serviceCenter?.length > 0 &&   <div className="location-btn" onClick={getShippingFee}>
                       <button>{shipment_price_loading && loadShipment ? <div className="fetch-shipment"><div style={{width: "1rem !important", height: "1rem !important"}}
                      className="spinner-border shipment-spinner" role="status"
                    ></div> <span>Fetching shipment price</span></div>:  "Use Pickup Station"}</button>
                    </div>
                    }
                
                    
                </div>
            </div>
            }
        </div>
    )
}

export default SelectLocation;