import { Fragment } from 'react';
import classes from './RecentlyViewed.module.css';
import RecentlyViewedItem from './RecentlyViewedItem';


const RecentViewed =()=>{
    return(
        <Fragment>
            <h4 className={classes.head}>Recently viewed</h4>
            <div className={classes.items}>
                <RecentlyViewedItem/>
                <RecentlyViewedItem/>
                <RecentlyViewedItem/>
                <RecentlyViewedItem/>
            </div>
        </Fragment>
    )
}

export default RecentViewed;