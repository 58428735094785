import { useState, useEffect, useContext, useCallback } from "react"
import { FaArrowLeft} from "react-icons/fa"
import { useSelector, useDispatch } from 'react-redux';
import { GetServiceCenterDetails } from "../../../Actions/ServiceCenterDetails";
import { ShipmentPrice } from "../../../Actions/ShipmentPrice";
import { getUrl } from "../../../helpers/getUrl";
import { DisplayContext  } from './../../../AppContexts/DisplayContext';
import AppDownloadSection from "./AppDownloadSection";
import { MdInfoOutline } from "react-icons/md";
import { formatDate } from "../../../helpers/formatDate";
import { clearEtaState, GetEta } from "../../../Actions/GetEta";
import { AppContext } from "../../../AppContexts/AppContext";

const ServiceCenterMobile = ({VehicleType, product}) => {
    const {showMobileDeliveryOption, handleCheck, check, handleMobileMenuSwitch, email, phone, local_station_error, local_station, local_station_loading, senderLat,senderLong, senderName, senderPhoneNumber, senderStationId,senderAddress, itemWeight,itemName,  price, qty, senderLocality, locationValues, setLocationValues, fullName,search, setSearch,setStationId, setRecieverCity,setRecieverLGA,destinationServiceCenterId, setDestinationServiceCenterId} = useContext(DisplayContext);
    const {isEmpty} = useContext(AppContext)
    const [pickupId, setPickupId] = useState("")
    const [secondLoad, setSecondLoad] = useState(false)
    const getPickupLocations = local_station && local_station.results && local_station.results.Object;

    const {service_center_loading, service_center} = useSelector(state => state.GetServiceCenterDetailsReducer);
    const { shipment_price_loading, shipment_price, shipment_price_error} = useSelector(state => state.ShipmentPriceReducer)

    const getId = pickupId && JSON.parse(pickupId)
    const id = getId && getId.StationId && Number(getId.StationId);  
    const destination_state = getId && getId.StateName === "FCT" ? "Abuja - FCT" : getId && getId.StateName;
    const {get_eta_loading,eta} =useSelector (state => state.getEta)
    const departure_state = product?.results?.locality;

    const dispatch = useDispatch()
    const serviceCenter = service_center && service_center.results && service_center.results.Object;
    
    const filteredCenters =
    serviceCenter &&
    serviceCenter.filter((center) =>
      center.Name?.toLowerCase().includes(search?.toLowerCase()) || center.Address?.toLowerCase().includes(search?.toLowerCase())
    );
  const dataToDisplay = search ? filteredCenters :  serviceCenter;
  const checkShipmentCode = shipment_price && shipment_price.code;
  const checkShipCodeSuccess = shipment_price && shipment_price.results && shipment_price.results.Code;

    const dataToSubmit =  {
        "ReceiverName": fullName,
        "ReceiverPhoneNumber": phone,
        "ReceiverEmail": email,
        "ReceiverAddress": locationValues && locationValues.Address,
        "ReceiverStationId": locationValues && locationValues.StationId,
        "ReceiverLocation": {
            "Latitude": locationValues && locationValues?.Latitude, 
            "Longitude": locationValues && locationValues?.Longitude
        },
        "Merchants": [
            {
                "CustomerCode": getUrl("ACC001164", "IND265936"),  
                "SenderName": senderName,
                "SenderPhoneNumber": senderPhoneNumber,
                "SenderStationId": +senderStationId,
                "SenderLocality": senderLocality,
                "SenderAddress": senderAddress, 
                "SenderLocation": {
                    "Latitude": senderLat &&  senderLat.toString(), 
                    "Longitude": senderLong && senderLong.toString()
                },
                "PaymentType": "Cash",
                "PreShipmentItems": [
                    { "SpecialPackageId": "0", "Quantity": qty && qty.toString(), "Weight": itemWeight, "ItemType": "Normal", "WeightRange": "0", "ItemName": itemName, "Value": price, "ShipmentType": "Regular" }
                ]
            },
            
        ],
        "PickupOptions": "HOMEDELIVERY",
        "IsHomeDelivery": false,
        "VehicleType": VehicleType?.toUpperCase() || "BIKE",
        "PaymentType": "Cash"
    }
    const getShippingFee = () => {
        if(check !== ""){
            dispatch(ShipmentPrice(dataToSubmit));
        }
       
    }
    useEffect(() => {
        dispatch(GetServiceCenterDetails(id))
    }, [dispatch, id]);
    
    
    useEffect(() => {
        if((serviceCenter && serviceCenter.length > 0) && shipment_price_error){
           dispatch(ShipmentPrice(dataToSubmit))
        }
   }, [shipment_price_error]);
   useEffect(() => {
    setStationId(locationValues?.StationId)
    setRecieverLGA(locationValues?.Name);
    setRecieverCity(locationValues?.City)
 }, [locationValues?.StationId, locationValues?.City,setStationId,setRecieverLGA,setRecieverCity]);

 const getEta = () => {
    if(destination_state !== undefined  && departure_state !== undefined && check !== ""){
        dispatch(GetEta({ "departure_state": departure_state, 
        "destination_state":  destination_state}))
}
}

const goToPaymentMobileService = useCallback(() => {
if(checkShipmentCode === 200 &&  checkShipCodeSuccess === "200" && showMobileDeliveryOption === 9){
    handleMobileMenuSwitch(10)
}
}, [checkShipCodeSuccess,checkShipmentCode, showMobileDeliveryOption]);
useEffect(() => {
   goToPaymentMobileService()
}, [goToPaymentMobileService])


    return (
        <div className={showMobileDeliveryOption === 9 ? "d-block" : "d-none"}>
            {local_station_error &&  
                <div className="container alert alert-danger" role="alert" style={{zIndex: "9999999999"}}>
                    {local_station_error}
                </div>
            }
           {/* {
                shipment_price_error &&
                <div className="container alert alert-danger" role="alert">
                    {shipment_price_error}
                </div>
            } */}
            {local_station_loading ? <div style={{ zIndex: "9999999999999"}}
                      className="spinner-border shipment-spinner"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div> :
            <div className="service-center-mobile">
                <div className="service__header"><span onClick={() => {handleMobileMenuSwitch(8); dispatch(clearEtaState())}}><FaArrowLeft className="service_back_nav"/></span><span className="checkout-header">SELECT PICKUP LOCATION</span></div>
                <div className="card-body">
                
                    <select className="select-elem form-select" onChange={(e) => {setPickupId(e.target.value);  dispatch(clearEtaState())}}>
                        <option>Select a GIG Service Center</option>
                        {getPickupLocations && getPickupLocations.map((pickup, index) => {
                            return <option key={index} value={JSON.stringify(pickup)}>{pickup.StationName}</option>
                        })}     
                    </select>
                    {serviceCenter === undefined || serviceCenter?.length === 0 ? "" :  <div className="search-location-input mt-2">
                     
                     <input type="text" placeholder="Search for Service centers..." value={search} onChange={(e) => setSearch(e.target.value)} className="service-center-search"/>
                   </div>}
                    {service_center_loading ? 
                          <div className="shipment-spinner-desktop-sc">
                          <div className="spinner-border shipment-spinner " role="status">
                          <span className="visually-hidden">Loading...</span>
                          </div> 
                      </div> : 
                        <>
                           {serviceCenter && serviceCenter.length === 0 ? <p className="text-center text-danger mt-2">Please we do not have a pickup station here, select another service center</p> : dataToDisplay && dataToDisplay.map((center, index) => (
                               <div  key={index} className="mt-0">
                                    <label className="media-elem-pickup" htmlFor="pickup_stations">    
                                         <div className="delivery-details-pickup">
                                               <div className="delivery-title">{center.Name}</div>
                                               <div className="delivery-note service-station-address">{center.Address}</div>
                                                 <span className="station-number d-block"><strong>Phone number: </strong>{phone}</span> 
                                         </div>
                                         <input type="radio" name="pickup-address" className="address-checkbox-pickup"  onClick={() => {setLocationValues(center); setSecondLoad(true); getEta();  setDestinationServiceCenterId(center.ServiceCentreId)}} onChange={handleCheck} id="pickup_stations" value="pickup-station" />    
                                 
                                    </label>
                                <hr />
                                  </div>
                           ))}
                        </>
                    
                    }
                       {get_eta_loading && destination_state !== undefined && departure_state !== undefined && <div   className="loader-style-eta">                           
                                <div className="spinner-border loader-spinner-eta" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="loading-text">Please wait...</div>
                            </div>}
                            {
                               !isEmpty(eta) && serviceCenter?.length > 0 && check !== "" && !service_center_loading && destination_state !== undefined && departure_state !== undefined ?  <div className="eta-display"><MdInfoOutline /> {`Expected time of arrival is ${formatDate(eta.results)}`}</div>  : null
                            }
                     {
                       serviceCenter !== undefined && serviceCenter?.length > 0 &&   <div className="location-btn" onClick={getShippingFee}>
                       <button>{shipment_price_loading && secondLoad ? <div className="fetch-shipment"><div style={{width: "1rem !important", height: "1rem !important"}}
                      className="spinner-border shipment-spinner" role="status"
                    ></div> <span>Fetching shipment price</span></div>:  "Use Pickup Station"}</button>
                    </div>
                    }
                
                </div>
            </div>
            }
            {/* <AppDownloadSection /> */}
        </div>
    )
}

export default ServiceCenterMobile;