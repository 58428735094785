import React, { useContext } from "react";
import classes from './ProductsYouLikeList.module.css';
import { Link } from "react-router-dom";
import numbro from "numbro";
import { AppContext } from "../../AppContexts/AppContext";

const ProductsYouLikeList=(props)=>{
    const {normalizeCase} = useContext(AppContext)    
    return(
        <Link to={`${props.id}`}>
        <div className={classes.container}>
            <div className={classes['product-image']}><img src={props.image} alt=""/></div>
            <div className={classes['details']} title={props.name}>
                <h4>{normalizeCase(props.name)}</h4>
                <p>&#x20A6; {numbro(props.amount).format({thousandSeparated: true})}</p>
                <div className={classes['alpha-code']}>Alpha code: {props.alphaCode}</div>
            </div>
        </div>
        </Link>
    )
}

export default ProductsYouLikeList;