import React, { useState } from 'react';
import classes from './ProductsYouLike.module.css';
import ProductsYouLikeList from './ProductsYouLikeList';
import { useGetAllProductsQuery } from '../../store/apiSlice';

const ProductsYouLike=()=>{
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(12)

    const {data, isLoading, isError, isFetching, isSuccess} = useGetAllProductsQuery({page, limit})

    const products = data?.results?.docs

    return(
        <div className={classes.container}>
            <div className={classes.heading}>
                <p>Products you may like</p>
            </div>
            <div className={classes.products}>
                {isLoading && <p>Loading...</p>}
                {isFetching && <p>Fetching...</p>}
                {isError && <p>Error</p>}
                {isSuccess && products.length > 0 && products.map(product=><ProductsYouLikeList key={product._id} id={product._id} image={product.front_view} name={product.productName} amount={product.price} alphaCode={product.alphaCode}/>)}
            </div>
        </div>
        
    )
}

export default ProductsYouLike;