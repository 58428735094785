import { useState,useEffect, useContext, useRef } from "react";
import { useParams } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { Tooltip as ReactTooltip } from "react-tooltip";
import moment from "moment";
import Modal from './Modal';
import DoorDeliveryModal from "./DoorDeliveryModal";
import PickUpModal from "./PickUpModal";
import DeliveryDetails from "./DeliveryDetails";
import SelectLocation from "./SelectLocation";
import PaystackPaymentModal from "./PaystackPayment";
import { useDispatch, useSelector } from "react-redux";
import { Product } from "../../Actions/Product";
import {FaCheck,FaPlayCircle,FaCopy, FaShareAlt,FaShare} from "react-icons/fa";
import {IoLocationSharp} from "react-icons/io5";
import SuccessModal from "./SuccessModal";
import FailedModal from "./FailedModal";
import ServiceCenterPayment from "./ServiceCenterPayment";
import Reviews from "./Reviews";
import { AppContext } from "../../AppContexts/AppContext";
import CheckoutModal from './MobileDevice/CheckoutModal';
import { DisplayContext } from "../../AppContexts/DisplayContext";
import MobileDeliveryOptions from "./MobileDevice/MobileDeliveryOptions";
import DeliveryDetailMobile from './MobileDevice/DeliveryDetailMobile';
import DoorDeliveryMobile from "./MobileDevice/DoorDeliveryMobile";
import Payment from "./MobileDevice/Payment";
import TransactionSuccess from "./MobileDevice/TransactionSuccess";
import TransactionFailed from "./MobileDevice/TransactionFailed";
import PickupDeliveryDetailsMobile from "./MobileDevice/PickupDeliveryDetailsMobile";
import ServiceCenterMobile from "./MobileDevice/ServiceCenterMobile";
import PickupDelivery from "./MobileDevice/PickupDelivery";
import ServiceCenterPaymentSection from "./MobileDevice/ServiceCenterPaymentSection";
import {Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination  } from "swiper";
import { GetColorName } from 'hex-color-to-color-name';
import "./styles/rating.css"

import VideoModal from "./VideoModal/VideoModal";
import VideoCard from "./VideoModal/VideoCard";
import { ShipmentPriceByQty } from "../../Actions/ShipmentPrice";
import ShareOptionsModal from "./Share/ShareOptionsModal";
import Loader from "../../utility/Loader";
import { clearAlphaSearchState } from "../../Actions/AlphaSearchResult";
import { useAddToCartMutation, useUpdateCartMutation } from "../../store/apiSlice";
import { getCartId } from "../../utility/utility";



const ProductDetails = () => { 
    const [showVideoText, setShowVideoText] = useState(false);
    const [readMore, setReadMore] = useState(false)
    const {id} = useParams()
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showVideoModalMobile, setShowVideoModalMobile] = useState(false);
    const [validateError, setValidateError] = useState("");
    const {shipment_price_by_qty_loading,shipment_price_by_qty} = useSelector(state => state.ShipmentPriceByQty)
    const ref = useRef();
    const myVideo = useRef()
    const dispatch = useDispatch();
    const {numberSeperator,copyToClipBoard,copySuccess,copied,setOpenShareModal,shareOnSocialMedia,normalizeCase, cartId, setCartId} = useContext(AppContext);
    const {showDeliveryOption, setShowDeliveryOption, check, handleCheck, handleMenuSwitch, closeModal, name, setName, email, setEmail, phone, setPhone, address, setAddress, qty, setQty, color, setColor, size, setSize,  local_station_error, local_station,  local_station_loading, stationId, styleColor, setStyleColor, styleSize, setStyleSize, senderLat,setSenderLat,  senderLong, setSenderLong, senderName, setSenderName, senderPhoneNumber, setSenderPhoneNumber, senderStationId, setSenderStationId, senderLocality, setSenderLocality, senderAddress, setSenderAddress, itemWeight, setItemWeight, customerCode, senderNeighborhood, setSenderNeighborhood,itemName, setItemName, setCustomerCode, receiverCoordinate, setReceiverCoordinate, locationValues, setLocationValues, setProductImg, setProductId, itemColor, setItemColor, verifyOrder, setVerifyOrder, handleMobileMenuSwitch, showMobileDeliveryOption,  setUserId, initialQty, setInitialQty } = useContext(DisplayContext)
    const [addToCart, {data, isLoading, isError, error, isSuccess}] = useAddToCartMutation()
    const [updateCart, {data: newData, isError: newIsError}] = useUpdateCartMutation()

    const {product_loading, product_error, product} = useSelector(state => state.GetProductReducer);
    const originalUrl =  window.location.origin;
    const results = product && product.results;
    const prodId = results && results._id;
    const store = results && results.store;
    const storeName = store && store.name;
    const slug = store && store.slug;
    const storeLogo = store && store.picture;
    const alphaCode = results && results.alphaCode
    const productName = results && results.productName;
    const price = results && results.price;
    const newColor = itemColor && itemColor.split(",")
    const itemSize = results && results.size;
    const sizeCheck = itemSize && itemSize[0] && itemSize[0].value
    const frontView = results && results.front_view;
    const rightView = results && results.right_view;
    const leftView = results && results.left_view;
    const rearView = results && results.rear_view;
    const quantity = results && results.quantity;
    const merchantAddress = results && results.address;
    const merchantCode = results && results.customer_code;
    const merchantFirstName = results && results.merchant_first_name
    const merchantLastName = results && results.merchant_last_name
    const merchantPhoneNumber = results && results.merchant_phone_number
    const merchantStationId = results && results.merchant_station_id
    const merchantLocality = results && results.locality;
    const merchantNeighborhood = results && results.neighborhood;
    const merchantLat = results && results.lat
    const merchantLong = results && results.long
    const merchantItemWeight = results && results.weight && results.weight.replace(/[^\d.-]/g, '');
    const user_id = results && results.createdBy
    const availableColor = results && results.color
    const prodImage = results && results.front_view;
    const prodDescription = results && results.description;
    const getReviews = results && results.ratings_reviews;
    const vehicleType = results && results.vehicleType
    const giftWrapping = results && results.gift_wrapping;
    const channelName = results && results.channel_links && results.channel_links[0] && results.channel_links[0].name;
    const videoUrl = results && results.watermark_video;
    const storeLink = `${originalUrl}/stores/${slug}`;
    const productLink = `${originalUrl}/product/guest/${prodId}`;
    const category = results && results.category;
    const categoryColor = category && category.color;
    const categorySize = category && category.size;
    const [tab, setTab] = useState(0);
    const [productTab, setProductTab] = useState(0);
    const tabItem = ["Description", "Review(s)"]
    const images = [{id: 1, url: videoUrl}, {id: 2, url: frontView}, {id: 3, url: rightView}, {id: 4, url: leftView}, {id: 5, url: rearView}];
    const newImage = images.filter((image) => image.url !== "");
    const len = newImage.length - 1;
    const image = len === 0 ? newImage[0].url : newImage[tab].url;
    const latestSize = itemSize? size : ''
    const storedCartId = getCartId()
    // const newCartId = JSON.parse(storedCartId)
    // console.log(newCartId);
    const getRatingStars = getReviews && getReviews.map(star => {
        return star.rating
    });
    const getAverageRatings = getRatingStars && getRatingStars.reduce((a,b) => {
       return  a + b/(getRatingStars.length)
    }, 0)
    const isProdutActive = (index) => {
        return productTab === index ? "product-tab active-prod-tab" : "product-tab"
    }
    const isActive = (index) => {
        return tab === index ? "image-thumbnails active" : "image-thumbnails rounded"
    }
    const isActiveSize = index => {
         return styleSize === index ? "selected-active-size-btn" : "selected-size-btn"
    }

    const isActiveColor = index => {
        return styleColor ===  index ? "show-active-color" : "hide-active-color"
   }
    const colorVariation = (color) => {
        return color === "#FFFFFF" ? "color-check-white-bg"  : color === "#F7FBFF" ? "color-check-white-bg" : color === "#F5F5F5" ? "color-check-white-bg" : color === "#E5E5E5"  ? "color-check-white-bg" : color === "#FFEA00" ? "color-check-white-bg" : color === "#FFBF00" ? "color-check-white-bg" : color === "#FBCEB1" ? "color-check-white-bg" : color === "#F5F5DC" ? "color-check-white-bg"  : color === "#EADDCA" ? "color-check-white-bg"  : color === "#F5F5DC" ? "color-check-white-bg" : color === "#E1C16E" ? "color-check-white-bg" : color === "#FDDA0D" ? "color-check-white-bg"  : color === "#FFFF8F" ? "color-check-white-bg" : color === "#DFFF00" ? "color-check-white-bg"  : color === "#E4D00A" ? "color-check-white-bg" : color === "#FFF8DC" ? "color-check-white-bg" : color === "#FFFDD0" ? "color-check-white-bg" : color === "#FCF55F" ? "color-check-white-bg"  : color === "#FFFFF0" ? "color-check-white-bg" : color === "#F8DE7E" ? "color-check-white-bg" : color === "#F0E68C" ? "color-check-white-bg" : color === "#FAFA33" ? "color-check-white-bg"  : color === "#FBEC5D" ? "color-check-white-bg" : color === "#F4BB44" ? "color-check-white-bg" : color === "#FADA5E" ? "color-check-white-bg" : color === "#FFDEAD" ? "color-check-white-bg" : color === "#ECFFDC" ? "color-check-white-bg" : color === "#FFFAA0" ? "color-check-white-bg" : color === "#FFE5B4" ? "color-check-white-bg" : color === "#C9CC3F" ? "color-check-white-bg" : color === "#B4C424" ? "color-check-white-bg" : color === "#93C572" ? "color-check-white-bg" : color === "#F3E5AB" ? "color-check-white-bg" : color === "#C4B454" ? "color-check-white-bg"  : color === "#F5DEB3" ? "color-check-white-bg" : color === "#FFFF00" ? "color-check-white-bg" : color === "#FFFF99" ? "color-check-white-bg" : color === "#FFAA33" ? "color-check-white-bg" : color === "#FFFF00"  ? "color-check-white-bg"  : "color-check-other-bg" 
    }

    console.log("product", product,itemColor, categoryColor)

    const validateOrderCriteria = (func,index) => {
           if(itemSize.length > 0 && itemSize[0].value !== "" && categorySize && size === ""){
              setValidateError("Please choose a size.*");
              return;
           }else if(categorySize && itemSize.length > 0 && color === ""){
              setValidateError("Please choose a color.*");
              return;
           }else if(qty === 0){
              setValidateError("select item quantity.*");
              return;
           }else {
               func(index)
           }
    }

    const addItemToCart = async() => {
        // event.preventDefault()
        await addToCart({
            cartId: storedCartId !== ''? storedCartId: cartId,
            alphaCode: alphaCode,
            category: category,
            color: color,
            customer_code: customerCode,
            front_view: frontView,
            gender: product.gender,
            gift_wrapping: false,
            lat: results.lat,
            locality: merchantLocality,
            long: results.long,
            merchant_email: results.merchant_email,
            merchant_first_name: merchantFirstName,
            merchant_id: user_id,
            merchant_last_name: merchantLastName,
            merchant_phone_number: merchantPhoneNumber,
            merchant_station_id: merchantStationId,
            price: price,
            product_address: merchantAddress,
            productId: prodId,
            productName: productName,
            quantity: qty,
            size: {latestSize, qty},
            store_id: store.storeId,
            weight: merchantItemWeight
        })
    }
    console.log(store?.storeId, store)
    useEffect(() => {
        if(!isError && isSuccess && data){
            if(data?.results._id !== ""){
                setCartId(data?.results._id)
                localStorage.setItem('cart_id', cartId)
            }
            console.log("cart", data);
        }
    }, [isError, isSuccess, data, cartId])
    
    let newQty = qty

    const updateCartItems =async()=>{
        await updateCart({
            cartId: cartId,
            productId: prodId,
            quantity: newQty+=1
        })
    }

    const decreaseCartItems =async()=>{
        await updateCart({
            cartId: cartId,
            productId: prodId,
            quantity: newQty-=1
        })
    }

    useEffect(()=>{
        if(!newIsError && newData){
            console.log(newData);
        }
    })

useEffect(() => {
    if(prodDescription?.length > 55){
        setReadMore(true)
    }else {
        setReadMore(false)
    }
}, [prodDescription])

    useEffect(() => {
        if(id){
            dispatch(Product(id))
        }
     
    }, [dispatch, id]);
    useEffect(() => {
        const data = {id,qty};
         dispatch(ShipmentPriceByQty(data))
    }, [dispatch,qty,id]);


    useEffect(() => {
        setItemName(productName);
        setSenderAddress(merchantAddress)
        setCustomerCode(merchantCode)
        setSenderName(`${merchantFirstName} ${merchantLastName}`)
        setSenderLat(merchantLat);
        setSenderLong(merchantLong);
        setSenderNeighborhood(merchantNeighborhood);
        setSenderPhoneNumber(merchantPhoneNumber);
        setSenderLocality(merchantLocality)
        setItemWeight(merchantItemWeight)
        setSenderStationId(merchantStationId)
        setProductImg(prodImage)
        setProductId(prodId);
        setUserId(user_id);
        setInitialQty(initialQty || quantity)
       
    }, [merchantLastName, merchantLat, merchantLocality, merchantLong, merchantNeighborhood, merchantPhoneNumber, merchantStationId, merchantFirstName, merchantAddress, merchantCode, productName, setCustomerCode, setSenderAddress, setItemName, setSenderName, setSenderLat, setSenderLong, setSenderNeighborhood, setSenderPhoneNumber, setSenderLocality, setItemWeight, setSenderStationId, merchantItemWeight, setProductImg, setProductId, prodImage, prodId, setUserId, setInitialQty, user_id, initialQty, quantity]);
   const mouseOverEffect = () => {
        ref.current.style.width = ref.current.clientWidth + 152 + "px";
        ref.current.style.backgroundColor = "#72FFC9";
        ref.current.style.color = "#FFFFFF";
        ref.current.style.borderRadius = "12px"
        ref.current.style.padding = "4px 5px"
   }
   const mouseLeaveEffect = () => {
      ref.current.style.width = "48px";
      ref.current.style.backgroundColor = "";
      ref.current.style.padding = "0px"
  }

    useEffect(() => {
        if(len === 0){
            return;
        }
       const interval = setInterval(() => {
             setTab(tab === len ? 0 : tab + 1)
        }, 25000);
        return () => clearInterval(interval)
    }, [tab, len]);

    useEffect(() => {
        dispatch(clearAlphaSearchState())
     },[dispatch]);
   
     
     return (
        <>
      
            <>
                {
                            product_error ?
                            <div className="error_detail_page">
                                <div className="container alert alert-danger" role="alert">
                                    {product_error}
                                </div> 
                            </div>
                            :
                
                    <div>
                    <div className="product-card" id="top">   
                    <div>
                        {
                            
                            product_loading ? (<Loader />)
                        :
                        <>
                        
                            <div className="mobile-display">
                                <div className={showMobileDeliveryOption === 2  || (showMobileDeliveryOption !== 0 && showMobileDeliveryOption !== 1) || check === "door-delivery-mobile" || check === "pickup-station-mobile" ? "d-none": "d-block"}>
                                    <Swiper
                                            modules={[Pagination, Autoplay]}
                                            spaceBetween={0}
                                            slidesPerView={1}
                                            pagination={{ clickable: true }}
                                            autoplay={videoUrl && videoUrl ? {
                                                delay: 40000,
                                                disableOnInteraction: true
                                            }: {
                                                delay: 4000,
                                                disableOnInteraction: true
                                            }}
                                            
                                            className="image-slider"
                                >
                                        {
                                            newImage && newImage.map((image, index) => (
                                             
                                                <SwiperSlide key={index + image.id} className="current-image">
                                                    {image && image.url && (image.url?.endsWith(".jpg") || image.url?.endsWith(".jpeg") || image.url?.includes(".png") || image.url?.endsWith(".gif") || image.url?.endsWith(".svg") ) ?  <img src={image.url} alt={image}/> :
                                                    <video ref={myVideo} className="video-image" autoPlay={!image.url?.endsWith(".jpg") || !image.url?.endsWith(".jpeg") || !image.url?.includes(".png") || !image.url?.endsWith(".gif") || !image.url?.endsWith(".svg")} muted>
                                                        <source src={image.url} muted/>
                                                    </video>
                                                    
                                                    }
                                                    </SwiperSlide>
                                             
                                            ))
                                        } 
                                </Swiper>
                            
                                <div className="mobile-page-content">
                                    <div className="mobile-player-sec">
                                    <>
                                        <div className="alpha-code" onClick={() => {copyToClipBoard(alphaCode);}}>Alpha Code: {alphaCode} <div className="copy-alpha-code-mobile">{copied ? <span className="copied-text">{copySuccess}</span> : <FaCopy />}</div></div>
                                    
                                    </>
                                    {videoUrl &&
                                    <div className="item-video-mobile" onClick={() => setShowVideoModalMobile(true)}><FaPlayCircle className="video-icon"/><span>Watch product video</span></div>
                                    }
                                    </div>
                                    <div className="out-of-stock"><div><h5 className="product-name-for-mobile">{normalizeCase(productName)}</h5>{ quantity === 0  ? <h5 className="out-of-stock-text">Out of Stock</h5> : ""}</div> <div className="share-product" onClick={() => setOpenShareModal(true)}><FaShareAlt className="share-product-mobile"/></div></div>
                                    <div className="location-address-section"><div><IoLocationSharp /></div><div>{merchantAddress}</div></div>
                                    <div className="price-rating-section">
                                        <h6 className="product-price-for-mobile">&#8358; {numberSeperator(price)}</h6>
                                        <div className="all-ratings">
                                            {getReviews?.length > 0 && <div className="ratings-space">
                                            <div><StarRatings starDimension={"20px"} starSpacing={"1px"} numberOfStars={5} starRatedColor="#FFC107" rating={getAverageRatings}/></div><div className="total-reviews">{getReviews?.length === 1 ? `${getReviews.length} review` : `${getReviews?.length} Reviews`}</div>
                                            </div> }

                                            {getReviews?.length === 0 &&  <div className="ratings-space">
                                            <div><StarRatings starDimension={"20px"} starSpacing={"1px"} numberOfStars={5} starRatedColor="#FFC107" rating={getAverageRatings}/></div><div className="total-reviews">0 Review</div>
                                            </div>}
                                            
                                        </div>
                                    </div>
                                    
                                    {/* <div className={quantity === 0 ? "pay-button-on-mobile-device checkout-btn-disabled" : "pay-button-on-mobile-device"}>
                                        <button className={quantity === 0 ? "pay-button-on-mobile-device checkout-btn-disabled" : "pay-button-on-mobile-device"} onClick={(e) => {e.preventDefault(); handleMobileMenuSwitch(1)}} disabled={quantity === 0}>Pay and Ship now</button>
                                    </div> */}
                                    <div className={quantity === 0 ? "pay-button-on-mobile-device checkout-btn-disabled" : "pay-button-on-mobile-device"}>
                                        <button className={quantity === 0 ? "pay-button-on-mobile-device checkout-btn-disabled" : "pay-button-on-mobile-device"} onClick={(e) => {e.preventDefault(); addItemToCart()}} disabled={quantity === 0}>Add To Cart</button>
                                    </div>
                                    
                                    
                                </div>
                            
                                <a href={`${originalUrl}/stores/${slug}`} target="_blank" rel="noreferrer" style={{cursor: "pointer", textDecoration: "none"}}>
                                    <div className="mobile-device-store-name">
                                        <div className="mobile-display-store-image"><img src={storeLogo} alt={storeLogo} /></div>
                                        <div className="mobile-device-store">
                                            <div>Product store</div>
                                            <h5>{normalizeCase(storeName)}</h5>
                                        </div>
                                    </div>
                                </a>
                                <div className="mobile-device-item-description">
                                    <div className="tab-item">
                                        {tabItem.map((item, index) => (
                                                <div key={index} className={`${isProdutActive(index)}`} onClick={() => setProductTab(index)}>{item}</div>
                                        ))}
                                    </div>
                                    {productTab === 0 && <p className="product-description-det">{prodDescription}</p>}
                                    {
                                        productTab === 1 && <div>{ getReviews.length > 0 ? getReviews.map(review => (
                                            <div key={review._id} className="display-review-mb">
                                                <div className="rating-n-name">
                                                    <div className="rating-date-n-name">
                                                        <span className="rate-name">{review.name}</span>
                                                        <span className="rated-on">{moment(review.createdAt).format("MMM DD, YYYY")}</span>
                                                    </div>
                                                    <div className="review-stars">
                                                    <StarRatings starDimension={"14px"} starSpacing={"1px"} numberOfStars={5} starRatedColor="#FFC107" rating={review.rating}/></div>
                                            </div>
                                            <p className="review-msg">{review.message}</p>
                                            </div>
                                        )) : <p className="no-reviews-yet">No review yet!</p>
                                        }
                                    </div>
                                    }
                                    
                                </div>
                                </div>
                                <CheckoutModal itemSize={itemSize} isActiveSize={isActiveSize}  quantity={quantity} colorVariation={colorVariation} isActiveColor={isActiveColor} newColor={newColor} giftWrapping={giftWrapping} availableColor={availableColor} color={color} initialQty={initialQty} setInitialQty={setInitialQty} price={price} categoryColor={categoryColor} categorySize={categorySize} id={id} validateOrderCriteria={validateOrderCriteria} setValidateError={setValidateError} validateError={validateError}/>
                                <MobileDeliveryOptions price={price} qty={qty} vehicleType={vehicleType}/>
                                <DeliveryDetailMobile product={product}/>
                                <DoorDeliveryMobile  vehicleType={vehicleType}/>
                                <Payment price={price} product={product} results={results} channelName={channelName} vehicleType={vehicleType} id={id}/>
                                <ServiceCenterPaymentSection price={price} product={product} results={results} channelName={channelName} vehicleType={vehicleType} id={id}/>
                                <PickupDeliveryDetailsMobile vehicleType={vehicleType} product={product}/>
                                <ServiceCenterMobile vehicleType={vehicleType} product={product}/>
                                <PickupDelivery vehicleType={vehicleType} product={product}/>
                                <VideoModal setShowVideoModal={setShowVideoModal} showVideoModal={showVideoModalMobile} >
                                    <VideoCard setShowVideoModal={setShowVideoModalMobile} showVideoModal={showVideoModalMobile} videoUrl={videoUrl}/>
                            </VideoModal>
                                {showMobileDeliveryOption === 6 && <TransactionSuccess id={id} product={product} slug={slug}/>}
                                {showMobileDeliveryOption === 7 && <TransactionFailed id={id} product={product} slug={slug}/>}
                               
                                <ShareOptionsModal>
                                    <div className="share-modal-page">
                                        {videoUrl && <div className="share-video-option" onClick={() => {shareOnSocialMedia(videoUrl, "Click to watch product video"); setOpenShareModal(false)}}><div>Share product video</div><div><FaShare /></div></div>}
                                        <div className="share-video-option" onClick={() => {shareOnSocialMedia(storeLink, "Click to view more products on merchants store"); setOpenShareModal(false)}}><div>Share store link</div><div><FaShare /></div></div>
                                        <div className="share-video-option" onClick={() => {shareOnSocialMedia(productLink, "Click to purchase product"); setOpenShareModal(false)}}><div>Share product link</div><div><FaShare /></div></div>
                                    </div>
                                </ShareOptionsModal>
                             
                            </div>
                            {/* Desktop display */}
                            <div className="product-card-details desktop-display">        
                                    <div className="row">
                                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 product-img-desktop-slide">
                                        
                                            <div className="desktop__slider-section">
                                                <div className="thumbnail-images-styles" style={{cursor: "pointer"}}>
                                                    {newImage.map((img, index) => (
                                                        <div key={index}>
                                                        {img && img.url && (img.url?.endsWith(".jpg") || img.url?.endsWith(".jpeg") || img.url?.includes(".png") || img.url?.includes(".svg") || img.url?.includes(".gif")) ? <img 
                            
                                                            src={img.url}
                                                            alt={img.url}
                                                            className={`${isActive(index)}`}
                                                            style={{height: "65px", width: "20%"}}
                                                            onClick={() => setTab(index)}
                                                    /> :  <video
                                                    autoPlay
                                                    muted
                                                    className={`${isActive(index)}`}
                                                    style={{height: "65px", width: "20%"}}
                                                    onClick={() => setTab(index)}
                                                    >
                                                        <source src={img.url} />
                                                    </video>
                                                    
                                                    }
                                                    </div>
                                                    )
                                                    
                                                )}
                                                    
                                                </div>
                                                <div className="card card-image">
                                                    {(image?.includes("jpeg") || image?.includes("jpg")) || image?.includes("png") || image?.includes("svg") || image?.includes("gif") ? <img src={image} alt={image}/> : <video
                                                        muted
                                                        autoPlay
                                                        className="video-image-desktop"
                                                    >
                                                        <source src={image} />
                                                    </video>
                                                }
                                                    
                                                </div>
                                            </div>
                                            { !product_loading &&
                
                                              <Reviews prodDescription={prodDescription} getReviews={getReviews} giftWrapping={giftWrapping}/>
                                            }
                                    </div>
                                    <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 offset-md-1 desktop-prod-details-col">
                                      
                                       
                                            <p className="product-name">{normalizeCase(productName)}</p>
                                            {/* <div className="location-address-section"><div><IoLocationSharp /></div><div>{merchantAddress}</div></div> */}
                                        
                                    
                                        {/* <div className="store-reviews"> */}
                                            {/* <div className="store-section"> */}
                                                <div className="store-name-placeholder">
                                                    
                                                    <div className="reviews-n-store-name">
                                                    <a href={`${originalUrl}/stores/${slug}`} rel="noreferrer" target="_blank" style={{cursor: "pointer", textDecoration: "none"}}>
                                                        <div className="store-img-n-name">
                                                            <div>
                                                              <img src={storeLogo} alt={storeLogo} />
                                                            </div>
                                                            <span className="store-desc">{normalizeCase(storeName)}</span>
                                                        </div>
                                                        </a>
                                                    </div>
                                                {/* </div> */}
                                            {/* </div> */}
                                        
                                        </div>
                                        <div> 
                                            
                                     
                                            <div className="alpha-code-desktop" onClick={() => {copyToClipBoard(alphaCode);}}><span>Alpha Code:</span> <span>{alphaCode}</span> <span className="copy-alpha-code">{copied ?  <span className="copied-text">{copySuccess}</span>   : <FaCopy />}</span></div> 
                                        </div>
                                        <div className="desktop__item-price">
                                            <div className="price">&#x20A6; {numberSeperator(qty === 1 || qty === 0 ? price : (shipment_price_by_qty && shipment_price_by_qty.results))}</div>
                                            {
                                         quantity === 0 &&
                                            <div className="check-available"> 
                                                 <div className="text-danger" style={{textDecoration: "line-through"}}><strong>Out of Stock</strong></div></div>
                                           }
                                        </div>
                                        {
                                        quantity > 0 && <div className="qty-btn-section"><div className="add-remove-qty"><button className={qty === 0 ? "btn-minus-desktop item-add-desktop-disabled" : "btn-minus-desktop"} onClick={() => {setQty(prev => prev - 1); setValidateError(""); decreaseCartItems()}} disabled={qty === 0}>-</button><button className="btn-qty-desktop">{qty && qty}</button><button className={qty === initialQty || qty === quantity  ? "btn-plus-desktop item-add-desktop-disabled" : "btn-plus-desktop"} onClick={() => {setQty(prev => prev + 1); setValidateError(""); updateCartItems()}} disabled={qty === initialQty || qty === quantity}>+</button></div></div>
                                        }
                                        
                                    
                                            {itemSize && itemSize.length > 0 && itemSize[0].value !== "" && categorySize &&
                                                <div className="available-size-desktop">
                                                    <div className= "sizes-select sizes-select-desktop">
                                                        {
                                                    categorySize &&  itemSize?.length > 0 && itemSize.map((size, index) => {
                                                            return size.value !== "" &&
                                                            <button key={size._id + index} className={isActiveSize(index)} onClick={() => {setSize(size.value); setStyleSize(index); setItemColor(size.color);  setInitialQty(size.quantity); setValidateError("")}}>{size.value}</button>
                                                        })  
                                                        }
                                                    </div>
                                                
                                                </div>
                                            }
                                            {itemColor && categoryColor &&
                                                <div className="prefered-color-desktop">
                                                    <div className="color-picker">
                                                        {
                                                        categoryColor && itemColor && newColor.map((color, index) => (
                                                                <button key={color + `-${index}`} style={{backgroundColor: color}}  onClick={() => {setColor(color); setStyleColor(index); setValidateError("")}} ><FaCheck className={`${colorVariation(color)}  ${isActiveColor(index)}`}/></button>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            }

                                        {
                                            availableColor && !sizeCheck && <div className="prefered-color-desktop">
                                                <div className="color-picker">
                                                    {
                                                        availableColor.includes(",") ? availableColor.split(",").map((newColor, index) => (
                                                            <button key={newColor + index} style={{backgroundColor: newColor}}  onClick={() => {setColor(newColor); setStyleColor(index)}} ><FaCheck className={`${colorVariation(newColor)}  ${isActiveColor(index)}`}/></button>
                                                        )) :
                                                        <button style={{backgroundColor: availableColor}}  onClick={() => {setColor(availableColor); setStyleColor(1)}} ><FaCheck className={`${colorVariation(color)} ${isActiveColor(1)}`}/></button>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            readMore ?
                                            <div className="desktop__product-description" id="prod_description">{`${prodDescription.slice(0, 56)}...`} <span className="description_readmore" onClick={() => setReadMore(false)}>Read more...</span></div> : <div className="desktop__product-description">{ prodDescription + "."} {" "}<span className="description_readmore" onClick={() => setReadMore(true)}>Read less...</span></div>
                                        }
                                 
                                        {/* <ReactTooltip
                                            anchorSelect="#prod_description"
                                            place="bottom"
                                            variant="info"
                                            content={prodDescription}
                                        />
                                   */}
                                        {validateError && <div className="text-danger">{validateError}</div>}
                                     
                                            
                                        
                                            <div className={quantity === 0 ||  shipment_price_by_qty_loading ? "checkout-btn-desktop checkout-btn-disabled": "checkout-btn-desktop"} onClick={(e) => {e.preventDefault(); addItemToCart()}}>
                                                <button disabled={quantity === 0 || shipment_price_by_qty_loading}>Add To Cart</button>
                                            </div>
                                            {/* <div className={quantity === 0 ||  shipment_price_by_qty_loading ? "checkout-btn-desktop checkout-btn-disabled": "checkout-btn-desktop"} onClick={() => validateOrderCriteria(handleMenuSwitch, 1)}>
                                                <button disabled={quantity === 0 || shipment_price_by_qty_loading}>Pay and Ship now</button>
                                            </div> */}
                                           <div className="player-n-payment">
                                               <div className="share-product-desktop" onClick={() => setOpenShareModal(true)}><FaShareAlt /> Share this product</div>
                                                  <div className={videoUrl ? "watch-prod-video" : "d-none"} onClick={() => setShowVideoModal(true)} ref={ref} onMouseEnter={(e) => {e.stopPropagation(); setShowVideoText(true); mouseOverEffect()}} onMouseLeave={() => {setShowVideoText(false); mouseLeaveEffect()}}>
                                                 <FaPlayCircle className={showVideoText ? "video-play-btn player_bg" : "video-play-btn"}/>{showVideoText && <span>Watch product video</span>}
                                                  </div>
                                           </div>
                                           <div className="color__variation">
                                                 <div className="item-model">
                                                     <div className="item">
                                                        <div className="variation-label">Model</div> <div>-</div>
                                                     </div>
                                                </div> 
                                                <div className="item-color">
                                                     <div className="item">
                                                        <div className="variation-label">Color</div> <div>{color ? GetColorName(color) : "-"}</div>
                                                     </div>
                                                </div> 
                                                <div className="item-location">
                                                     <div className="item">
                                                        <div className="variation-label">Product Location</div> <div>{merchantNeighborhood + ", " + merchantLocality}</div>
                                                     </div>
                                                </div> 
                                           </div>
                                    </div>
                                </div> 
                                <ShareOptionsModal>
                                    <div className="share-modal-page">
                                        {videoUrl && <div className="share-video-option" onClick={() => {shareOnSocialMedia(videoUrl, "Click to watch product video"); setOpenShareModal(false)}}><div>Share product video</div><div><FaShare /></div></div>}
                                        <div className="share-video-option" onClick={() => {shareOnSocialMedia(storeLink, "Click to view more products on merchants store"); setOpenShareModal(false)}}><div>Share store link</div><div><FaShare /></div></div>
                                        <div className="share-video-option" onClick={() => {shareOnSocialMedia(productLink, "Click to purchase product"); setOpenShareModal(false)}}><div>Share product link</div><div><FaShare /></div></div>
                                    </div>
                                </ShareOptionsModal>   

                            </div>
                        <Modal/> 
                        <VideoModal setShowVideoModal={setShowVideoModal} showVideoModal={showVideoModal}>
                            <VideoCard setShowVideoModal={setShowVideoModal} showVideoModal={showVideoModal} videoUrl={videoUrl}/>
                        </VideoModal>
                        <DeliveryDetails product={product}/> 
                        <DoorDeliveryModal price={price}  vehicleType={vehicleType}/>
                        <SelectLocation handleCheck={handleCheck} check={check} handleMenuSwitch={handleMenuSwitch} setShowDeliveryOption={setShowDeliveryOption} showDeliveryOption={showDeliveryOption} closeModal={closeModal}  name={name} setName={setName} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} address={address} setAddress={setAddress} price={price} quantity={quantity} qty={qty} local_station_error={local_station_error} local_station={local_station} local_station_loading={local_station_loading}  senderLat={senderLat} senderLong={senderLong} senderName={senderName} senderPhoneNumber={senderPhoneNumber} senderStationId={senderStationId} senderLocality={senderLocality} senderAddress={senderAddress} itemWeight={itemWeight} itemName={itemName} customerCode={customerCode} senderNeighborhood={senderNeighborhood} receiverCoordinate={receiverCoordinate} setReceiverCoordinate={setReceiverCoordinate}  locationValues={locationValues} setLocationValues={setLocationValues} vehicleType={vehicleType} product={product}
            />
                        <PickUpModal handleCheck={handleCheck} check={check} handleMenuSwitch={handleMenuSwitch} setShowDeliveryOption={setShowDeliveryOption} showDeliveryOption={showDeliveryOption} closeModal={closeModal} price={price} quantity={quantity} qty={qty} address={address}  senderLat={senderLat} senderLong={senderLong} senderName={senderName} senderPhoneNumber={senderPhoneNumber} senderStationId={senderStationId} senderLocality={senderLocality} senderAddress={senderAddress} itemWeight={itemWeight} itemName={itemName} customerCode={customerCode} senderNeighborhood={senderNeighborhood} receiverCoordinate={receiverCoordinate} setReceiverCoordinate={setReceiverCoordinate} name={name} email={email} phone={phone} locationValues={locationValues} id={id}/>
                        <PaystackPaymentModal handleCheck={handleCheck} check={check} handleMenuSwitch={handleMenuSwitch} setShowDeliveryOption={setShowDeliveryOption} showDeliveryOption={showDeliveryOption} closeModal={closeModal} price={price} quantity={quantity} name={name} email={email} phone={phone} qty={qty} address={address}  senderLat={senderLat} senderLong={senderLong} senderName={senderName} senderPhoneNumber={senderPhoneNumber} senderStationId={senderStationId} senderLocality={senderLocality} senderAddress={senderAddress} itemWeight={itemWeight} itemName={itemName} customerCode={customerCode} senderNeighborhood={senderNeighborhood}  receiverCoordinate={receiverCoordinate} setReceiverCoordinate={setReceiverCoordinate} color={color} size={size}  prodImage={frontView} prodId={prodId} verifyOrder={verifyOrder} setVerifyOrder={setVerifyOrder} locationValues={locationValues} product={product} channelName={channelName} vehicleType={vehicleType} id={id}
            />
            <ServiceCenterPayment handleCheck={handleCheck} check={check} handleMenuSwitch={handleMenuSwitch} setShowDeliveryOption={setShowDeliveryOption} showDeliveryOption={showDeliveryOption} closeModal={closeModal} price={price} quantity={quantity} name={name} email={email} phone={phone} qty={qty} address={address}  senderLat={senderLat} senderLong={senderLong} senderName={senderName} senderPhoneNumber={senderPhoneNumber} senderStationId={senderStationId} senderLocality={senderLocality} senderAddress={senderAddress} itemWeight={itemWeight} itemName={itemName} customerCode={customerCode} senderNeighborhood={senderNeighborhood}  receiverCoordinate={receiverCoordinate} setReceiverCoordinate={setReceiverCoordinate} color={color} size={size}  prodImage={frontView} prodId={prodId} stationId={stationId} verifyOrder={verifyOrder} setVerifyOrder={setVerifyOrder} locationValues={locationValues} product={product} channelName={channelName} vehicleType={vehicleType} id={id}/>
            <SuccessModal showDeliveryOption={showDeliveryOption} closeModal={closeModal} id={id} />
            <FailedModal showDeliveryOption={showDeliveryOption} closeModal={closeModal} id={id}/>
                </>
            }
                    </div>
                    
                    </div>
                    {/* { !product_loading &&
                
                    <Reviews prodDescription={prodDescription} getReviews={getReviews} giftWrapping={giftWrapping}/>
                } */}
                </div>
                }
            
            </>
         </>
     )
}
export default ProductDetails;