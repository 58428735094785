import { GET_SERVICE_CENTER_DETAILS_FAIL, GET_SERVICE_CENTER_DETAILS_REQUEST, GET_SERVICE_CENTER_DETAILS_SUCCESS } from "../constants/constants";
import { getUrl } from './../helpers/getUrl';

export function GetServiceCenterDetails(id){ 
    return async (dispatch) => {
      dispatch({type: GET_SERVICE_CENTER_DETAILS_REQUEST});
        try {
          // const res = await fetch(`${getUrl("https://order.thealphashops.com/api/v1","http://alpha-order-sandbox-api.com/api/v1")}/order/guest/service_centers?stationId=${id}`, {
          const res = await fetch(`https://order.thealphashops.com/api/v1/order/guest/service_centers?stationId=${id}`, {
               method: "GET",
               "Content-Type": "application/json",
               ACCEPT: "application/json",
          })
    
          const data = await res.json();
          console.log("data", data, id);   

          if(data.error){
                return dispatch({type: GET_SERVICE_CENTER_DETAILS_FAIL, payload: data.message})
          }
          dispatch({type: GET_SERVICE_CENTER_DETAILS_SUCCESS, payload: data}) 
        } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
          dispatch({type: GET_SERVICE_CENTER_DETAILS_FAIL, payload: message})
        }
    }
}