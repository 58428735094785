// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_container__HBegB{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F1F1F1;
    height: 2rem;
}

.Loader_container__HBegB div{
    color: #979797;
    margin-right: 0.6rem;
    cursor: pointer;
    font-size: 0.6rem;
}

@media screen and (min-width: 1024px) {
    .Loader_container__HBegB{
        height: 2.5rem;
        margin-bottom: 6.25rem;
    }
    
    
    .Loader_container__HBegB div{
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 1200px) {
    .Loader_container__HBegB{
        margin-bottom: 6.75rem;
        height: 3rem;
    }
    
    
    .Loader_container__HBegB div{
        font-size: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/shared/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI;QACI,cAAc;QACd,sBAAsB;IAC1B;;;IAGA;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,YAAY;IAChB;;;IAGA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid #F1F1F1;\n    height: 2rem;\n}\n\n.container div{\n    color: #979797;\n    margin-right: 0.6rem;\n    cursor: pointer;\n    font-size: 0.6rem;\n}\n\n@media screen and (min-width: 1024px) {\n    .container{\n        height: 2.5rem;\n        margin-bottom: 6.25rem;\n    }\n    \n    \n    .container div{\n        font-size: 0.8rem;\n    }\n}\n\n@media screen and (min-width: 1200px) {\n    .container{\n        margin-bottom: 6.75rem;\n        height: 3rem;\n    }\n    \n    \n    .container div{\n        font-size: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Loader_container__HBegB`
};
export default ___CSS_LOADER_EXPORT___;
