// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecentlyViewedItem_item__yvkOW{
    border: 1px solid #E4E4E4;
    box-shadow: 0px 4px 14px 7px rgba(121, 121, 121, 0.0527344);
    border-radius: 4px;
}

.RecentlyViewedItem_cover__hH4pb{
    width: 71.43%;
    margin: 1.5rem auto .75rem;
    text-align: center;
}

.RecentlyViewedItem_name__QzDKi{
    margin: auto;
    text-align: center;
    font-size: 0.875rem;
    color: #4D4D4D;
    margin-bottom: .5rem;
}

.RecentlyViewedItem_code__iAKoc{
    margin: auto;
    text-align: center;
    font-size: 0.875rem;
    color: #333333;
    margin-bottom: .5rem;
}

.RecentlyViewedItem_code__iAKoc span{
    color: #849614;
    font-weight: 700;
}

.RecentlyViewedItem_location__b59Qi{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    margin-bottom: .5rem;
}

.RecentlyViewedItem_location__b59Qi div{
    display: flex;
    align-items: center;
}

.RecentlyViewedItem_location__b59Qi p{
    width: -moz-fit-content;
    width: fit-content;
    color: #333333;
    font-size: 0.875rem;
    margin-left: 0.609375rem;
}

.RecentlyViewedItem_price__sZsqB{
    margin: auto;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    color: #333333;
}

.RecentlyViewedItem_image__r6mHW{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.RecentlyViewedItem_image__r6mHW img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/pages/Cart/RecentlyViewedItem.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,2DAA2D;IAC3D,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".item{\n    border: 1px solid #E4E4E4;\n    box-shadow: 0px 4px 14px 7px rgba(121, 121, 121, 0.0527344);\n    border-radius: 4px;\n}\n\n.cover{\n    width: 71.43%;\n    margin: 1.5rem auto .75rem;\n    text-align: center;\n}\n\n.name{\n    margin: auto;\n    text-align: center;\n    font-size: 0.875rem;\n    color: #4D4D4D;\n    margin-bottom: .5rem;\n}\n\n.code{\n    margin: auto;\n    text-align: center;\n    font-size: 0.875rem;\n    color: #333333;\n    margin-bottom: .5rem;\n}\n\n.code span{\n    color: #849614;\n    font-weight: 700;\n}\n\n.location{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 0.875rem;\n    margin-bottom: .5rem;\n}\n\n.location div{\n    display: flex;\n    align-items: center;\n}\n\n.location p{\n    width: fit-content;\n    color: #333333;\n    font-size: 0.875rem;\n    margin-left: 0.609375rem;\n}\n\n.price{\n    margin: auto;\n    text-align: center;\n    font-size: 1rem;\n    font-weight: 700;\n    color: #333333;\n}\n\n.image{\n    display: flex;\n    align-items: center;\n    margin-bottom: 1rem;\n}\n\n.image img{\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `RecentlyViewedItem_item__yvkOW`,
	"cover": `RecentlyViewedItem_cover__hH4pb`,
	"name": `RecentlyViewedItem_name__QzDKi`,
	"code": `RecentlyViewedItem_code__iAKoc`,
	"location": `RecentlyViewedItem_location__b59Qi`,
	"price": `RecentlyViewedItem_price__sZsqB`,
	"image": `RecentlyViewedItem_image__r6mHW`
};
export default ___CSS_LOADER_EXPORT___;
