const AppDownloadSection = () => {
    return (
        <div className="mobile-app-download">
        <div className="app-download-container">
            <div className="app-icons-show">
                <img src="/img-2.png" alt="Google Pixel 2-XL" className="pixel-one"/>
                <img src="/img-1.png" alt="Google Pixel 2 XL" className="pixel-two"/>
             </div>
             <div className="gig-alpha-download">
                 <>
                    <h4>Try the GIG Alpha App Today</h4>
                    <p>Buy and sell with ease on the mobile app</p>
                    <div>Download the app on </div>
                 </>
                 <div className="download-icons-mobile">
                     <a href="https://play.google.com/store/apps/details?id=com.thealphashops" rel="noreferrer"><img src="/playstore.png" alt="playstore" className="mobile-download-playstore"/></a>
                     <a href="https://apps.apple.com/us/app/alphashop/id1615734219" target="_blank" rel="noreferrer"><img src="/Download_on_the_App_Store_Badge.svg" alt="apple-store-logo" className="mobile-download-appstore"/></a>
                 </div>
             </div>
             <div className="gig-alpha-download-desktop">
                 <div className="try-alpha">
                    <h4>Try the Alpha App Today</h4>
                    <p>Buy and sell with ease on the mobile app</p>
                    <div className="hide-on-desktop">Download the app on </div>
                 </div>
                 
                 <div className="alpha-vertical-divider"/>
                 <div className="download-icons-desktop">
                     <a href="https://play.google.com/store/apps/details?id=com.thealphashops" rel="noreferrer"><img src="/playstore.png" alt="playstore" className="mobile-download-playstore"/></a>
                     <a href="https://apps.apple.com/us/app/alphashop/id1615734219" target="_blank" rel="noreferrer"><img src="/Download_on_the_App_Store_Badge.svg" alt="apple-store-logo" className="mobile-download-appstore"/></a>
                 </div>
             </div>
        </div>
   </div>
    )
}

export default AppDownloadSection