import { Link } from 'react-router-dom';
import classes from './Carousel.module.css';

const CarouselItem =({item})=>{
    return(
        <div className={classes['carousel-item']}>
            <div>
                <h1>
                <span>GROW YOUR</span> <br />
                <span>BUSINESS ONLINE</span>
                </h1>
                <p>
                You can reach over a million buyers across the country, Sign up
                as a store owner on GIG ALPHA today!
                </p>
                <Link to="signup">
                <button>SIGN UP</button>
                </Link>
            </div>
            <div className={classes.carImg}>
                <img src={item.image} alt="cd" />
            </div>
        </div>
    )
}

export default CarouselItem;