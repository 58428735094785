import { PAYMENT_METHOD_GET_FAIL, PAYMENT_METHOD_GET_REQUEST, PAYMENT_METHOD_GET_SUCCESS } from "../constants/constants";
import { getUrl } from "../helpers/getUrl";

export function PaymentMethod() {
    return  async (dispatch) => {
        dispatch({type: PAYMENT_METHOD_GET_REQUEST});
        try {
          const res = await fetch(`${getUrl("https://order.thealphashops.com/api/v1","https://orderservice.click/api/v1")}/gateway/list`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json"
              }
          })
          const data = await res.json();
          if(data.error){
               return dispatch({type: PAYMENT_METHOD_GET_FAIL, payload: data.message})
          }
          dispatch({type: PAYMENT_METHOD_GET_SUCCESS, payload: data})    
        } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
          dispatch({type: PAYMENT_METHOD_GET_FAIL, payload: message})
        }
    }
}