import { useState, useEffect } from "react"
import classes from './ResetPassword.module.css';
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "../../store/apiSlice";

const ResetPassword = () => {
    const [email, setEmail] = useState("")
    const navigate = useNavigate()
    const [forgotPassword, {data, isLoading, isError, error, isSuccess}] = useForgotPasswordMutation()

    const resetPassword=async(event)=>{
        event.preventDefault()
        await forgotPassword({
            email: email,
            user_type: 'user'
        })
    }

    useEffect(() => {
        if(!isError && isSuccess && data){
            navigate("/reset-otp")
            console.log(data);
        }
    }, [isError, isSuccess, data])

    return(
        <div className={classes.page}>
            <div className={classes.container}>
                <div>
                    <h1>Forgot Password?</h1>
                    <p>That's alright, let's make a new one. Please, enter your registered email address</p>
                </div>
                <form onSubmit={resetPassword}>
                    <label htmlFor="reset-password">Email Address</label><br/>
                    <input type="email" name="email" id="reset-password" placeholder="Email" onChange={e=> setEmail(e.target.value)}/><br/>
                    <button type="submit">Reset Password</button>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword;