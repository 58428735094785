export const getUrl = (prod, dev) => {
    let env = "development";
    let baseURL;
    if(env === "production"){
        baseURL = prod
    }else {
        baseURL = dev
    }
    return baseURL
}
export const NODE_ENV = "production";