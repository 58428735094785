import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import {MdArrowBack} from "react-icons/md";
import { DisplayContext } from "../../../AppContexts/DisplayContext";
import { Product } from '../../../Actions/Product';
import "../MobileDevice/mobile.css";
import { AppContext } from '../../../AppContexts/AppContext';
import { ShipmentPriceByQty } from '../../../Actions/ShipmentPrice';
const CheckoutModal = ({itemSize, isActiveSize, colorVariation, isActiveColor, newColor, quantity, availableColor, color, price, categoryColor,categorySize, id}) => {
    const {setSize, setStyleSize, setItemColor, setStyleColor, setColor, itemColor, qty, setQty, handleMobileMenuSwitch, showMobileDeliveryOption, initialQty, setInitialQty} = useContext(DisplayContext); 
    const {numberSeperator} = useContext(AppContext);
    const {shipment_price_by_qty_loading,shipment_price_by_qty,shipment_price_by_qty_error} = useSelector(state => state.ShipmentPriceByQty)
    const dispatch = useDispatch(null);
    const getUrlVal = window.location.href;
    const afterLastSlash = getUrlVal.substring(getUrlVal.lastIndexOf('/') + 1);
    const sizeCheck = itemSize && itemSize[0] && itemSize[0].value;
    useEffect(() => {
        const data = {id,qty};
         dispatch(ShipmentPriceByQty(data))
    }, [dispatch,qty,id]);
    return (
        <div className={showMobileDeliveryOption === 1 ? "mobile-checkout-screen" : "hide-checkout-modal"}>
             <div className="available-sizes available-sizes-mobile">
                   <div className="my-4">
                            {itemSize && itemSize.length === 0 && categorySize && <p className="sizes-home" onClick={() => {handleMobileMenuSwitch(0);dispatch(Product(afterLastSlash))}}><MdArrowBack className="back-to-home text-dark"/>Back</p>}
                            {categorySize && itemSize && itemSize.length > 0 && itemSize[0].value !== "" && 
                            <>

                                {categorySize && itemSize[0].value !== "" &&  <p className="sizes" onClick={() => {handleMobileMenuSwitch(0);dispatch(Product(afterLastSlash))}}><span><MdArrowBack className="back-to-home"/></span>Sizes available</p>}
                                <div className= "sizes-select">
                                    {
                                   categorySize &&  itemSize?.length > 0 && itemSize.map((size, index) => {
                                        return size.value !== "" &&
                                        <button key={"size-" + index} className={isActiveSize(index)} onClick={() => {setSize(size.value); setStyleSize(index); setItemColor(size.color); setInitialQty(size.quantity);}}>{size.value}</button>
                                    })  
                                    }
                                </div>
                            
                            </>
                            }
                                        
                    </div>
             </div>
             <div className="available-colors mobile-color-select">
                        {categoryColor && itemColor && 
                                        <div className="prefered-color">
                                            
                                            <p className="prefer">choose preferred color</p>
                                            <div className="color-picker">
                                            {
                                              categoryColor &&  itemColor && newColor.map((color, index) => (
                                                    <button key={"bg-" + index} style={{backgroundColor: color}}  onClick={() => {setColor(color); setStyleColor(index)}} ><FaCheck className={`${colorVariation(color)}  ${isActiveColor(index)}`}/></button>
                                                ))
                                            }
                                            </div>
                                        
                                        </div>
                                    }
                                    {
                                        availableColor && !sizeCheck &&
                                            <div className="prefered-color">
                                              <p className="prefer">choose preferred color</p>
                                                   <div className="color-picker">
                                                        {
                                                            availableColor.includes(",") ? availableColor.split(",").map((newColor, index) => (
                                                                <button key={"color--" + index} style={{backgroundColor: newColor}}  onClick={() => {setColor(newColor); setStyleColor(index)}} ><FaCheck className={`${colorVariation(newColor)}  ${isActiveColor(index)}`}/></button>
                                                            )) :
                                                            <button style={{backgroundColor: availableColor}}  onClick={() => {setColor(availableColor); setStyleColor(1)}} ><FaCheck className={`${colorVariation(color)} ${isActiveColor(1)}`}/></button>
                                                        }
                                                    </div>
                                            </div>
                                    }
            
             </div>
             <hr />
             <div className="alpha-price-section">
                 <h2>Total Price</h2>
                 {shipment_price_by_qty_error && <div className="text-danger">{shipment_price_by_qty_error}</div>}
                 <h1>&#8358; {numberSeperator(qty > 1 ? (shipment_price_by_qty && shipment_price_by_qty.results) : price)}</h1>
             </div>
             <hr />
             <div className="qty-btn-section-phone"> 
              <button className={qty === 0 ? "btn-minus-mobile item-add-disabled-mobile" : "btn-minus-mobile"} onClick={() => setQty(prev => prev - 1)} disabled={qty === 0}>-</button>
              <button className="btn-qty-mobile">{qty && qty}</button>
              <button className={qty === initialQty  ? "btn-plus-mobile item-add-disabled-mobile" : "btn-plus-mobile"} onClick={() => setQty(prev => prev + 1)} disabled={qty === initialQty}>+</button></div>
              <div className={(categorySize && itemSize?.length > 0 && color === "") || quantity === 0 || qty === 0 || shipment_price_by_qty_loading ? "phone-checkout-btn checkout-btn-disabled" : "phone-checkout-btn"} onClick={() =>  handleMobileMenuSwitch(2)}>
                    <button disabled={(categorySize && itemSize?.length > 0 && color === "" || shipment_price_by_qty_loading) || quantity === 0 || qty === 0}>Checkout</button>
              </div>
        </div>
        )
}


export default CheckoutModal;