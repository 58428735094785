import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'; 

export const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: fetchBaseQuery({baseUrl: 'https://product.thealphashops.com/'}),
    tagTypes: ['products'],
    endpoints: (builder) => ({
        getAllProducts: builder.query({
            query: ({page, limit})=> `api/v1/product/all?page=${page}&limit=${limit}`,
            providesTags: ['products']
        }),
        closestProducts: builder.query({
            query: ({lat, long, page, limit})=> `api/v1/product/search_location?lat=${lat}&long=${long}&page=${page}&limit=${limit}`,
            providesTags: ['products']
        }),
        priceByQuantity: builder.query({
            query: ({productId, quantity})=> `api/v1/product/price_by_quantity?productId=${productId}&quantity=${quantity}`,
            providesTags: ['products']
        }),
    })
})

export const cartApi = createApi({
    reducerPath: 'cartApi',
    baseQuery: fetchBaseQuery({baseUrl: 'https://product.thealphashops.com/'}),
    tagTypes: ['cart'],
    endpoints: (builder) => ({
        addToCart: builder.mutation({
            query: (details)=> ({
                url: `api/v1/cart/add_to_cart`,
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    ...details
                })
            }),
            invalidatesTags: ['cart']
        }),
        removeCartItem: builder.mutation({
            query: (details)=> ({
                url: `api/v1/cart/remove_cart_item`,
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    ...details
                })
            }),
            invalidatesTags: ['cart']
        }),
        updateCart: builder.mutation({
            query: (cartDetails)=> ({
                url: `api/v1/cart/update_quantity`,
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    ...cartDetails
                })
            }),
            invalidatesTags: ['cart']
        }),
        getCartItems: builder.query({
            query: ({cartId})=> `api/v1/cart/items/${cartId}`,
            providesTags: ['cart']
        })
    })
})

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({baseUrl: 'https://userservice.click/'}),
    endpoints: (builder) => ({
        userLogin: builder.mutation({
            query: ({email, password, user_type})=> ({
                url: `api/v1/auth/login`,
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email,
                    password,
                    user_type
                })
            })
        }),
        signup: builder.mutation({
            query: ({first_name, last_name, email, phone, password})=> ({
                url: `api/v1/user/guest/new`,
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    first_name,
                    last_name,
                    email,
                    password,
                    phone
                })
            })
        }),
        accountType: builder.mutation({
            query: ({email, user_type})=> ({
                url: `api/v1/user/user_type`,
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email,
                    user_type
                })
            })
        }),
        confirmOTP: builder.mutation({
            query: ({activation_code})=> ({
                url: `api/v1/user/email_activation`,
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    activation_code
                })
            })
        }),
        forgotPassword: builder.mutation({
            query: ({email, user_type})=> ({
                url: `api/v1/auth/forgot_password`,
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email,
                    user_type
                })
            })
        }),
        newPassword: builder.mutation({
            query: ({otp, password, user_type})=> ({
                url: `api/v1/auth/reset_password`,
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    otp,
                    password,
                    user_type
                })
            })
        })
    }),
})

export const { useGetAllProductsQuery, useClosestProductsQuery, usePriceByQuantityQuery } = productsApi
export const { useUserLoginMutation, useSignupMutation, useAccountTypeMutation, useConfirmOTPMutation, useForgotPasswordMutation, useNewPasswordMutation } = userApi
export const { useAddToCartMutation, useGetCartItemsQuery, useUpdateCartMutation, useRemoveCartItemMutation } = cartApi