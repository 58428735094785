// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.alpha-video-post {
    border-radius: 5px;
    min-height: 400px;
    height: 100%;
    width: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #000;
}
.clickable {
    cursor: pointer;
}
.video-play {
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
}

.controls {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.controls .video-controls {
    font-size: 5em;
    color: var(--alpha-white);
    transition: opacity 200ms linear;
}
.control-shown {
    opacity: 1;
}
.control-hidden{
    opacity: 0;
}
.share-video {
    position: absolute;
    bottom: 3%;
    right: 5%;
    cursor: pointer;
    z-index: 9999999;
    font-size: 1.2em;
    color: var(--alpha-green);
}`, "",{"version":3,"sources":["webpack://./src/pages/Product/VideoModal/alphavideoplayer.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,+BAA+B;IAC/B,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;AACA;IACI,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,QAAQ;IACR,OAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;AAEvB;AACA;IACI,cAAc;IACd,yBAAyB;IACzB,gCAAgC;AACpC;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;AAC7B","sourcesContent":["\n.alpha-video-post {\n    border-radius: 5px;\n    min-height: 400px;\n    height: 100%;\n    width: 100%;\n    position: relative;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%,-50%);\n    background: #000;\n}\n.clickable {\n    cursor: pointer;\n}\n.video-play {\n    width: 100%;\n    display: block;\n    object-fit: cover;\n    height: 100%;\n}\n\n.controls {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n   \n}\n.controls .video-controls {\n    font-size: 5em;\n    color: var(--alpha-white);\n    transition: opacity 200ms linear;\n}\n.control-shown {\n    opacity: 1;\n}\n.control-hidden{\n    opacity: 0;\n}\n.share-video {\n    position: absolute;\n    bottom: 3%;\n    right: 5%;\n    cursor: pointer;\n    z-index: 9999999;\n    font-size: 1.2em;\n    color: var(--alpha-green);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
