import { useContext  } from 'react';
import { useDispatch } from 'react-redux';
import {FaTimes,FaCheckCircle} from "react-icons/fa"
import { Product } from '../../Actions/Product';
import { DisplayContext } from '../../AppContexts/DisplayContext';
import { clearPaymentAndOrder } from '../../Actions/CreateOrder';


const SuccessModal = ({id}) => {
    const {showDeliveryOption, handleMenuSwitch} = useContext(DisplayContext);
    const dispatch = useDispatch()
    return (
        <div className={showDeliveryOption === 7 ?  "success-modal" : "modals-container-hide"} >
             <div className="card card-modal-content-success modal-success">
             <div className="success-cancel"  onClick={() => {handleMenuSwitch(0); dispatch(Product(id)); dispatch(clearPaymentAndOrder())}}><FaTimes className="cancel-success-modal" /></div>
                 <div className="success-modal-content">
                   
                    
                      {/* <div className="success-circle"></div> */}
                      <div className="success-image">
                         <FaCheckCircle className="success-icon"/>
                      </div>
                  
                      
                      <h4 className="success-text">Order has been placed successfully</h4>
                    
                      <p className="success-message">Your order has been placed successfully.<br />The order is on the way to the seller for acceptance, your tracking ID will be sent to your email.</p>

                 </div>
               
               <div className="modal-content-footer">
               <hr />
                  <div className="content-footer">
                    <p>Download Alpha APP</p>
                    <div className="download-app-links">
                        <a href="https://apps.apple.com/us/app/alphashop/id1615734219" target="_blank" rel="noreferrer">
                           <img src="/appstore.png" alt="playstore" className="download-app  ios-download"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.thealphashops" target="_blank" rel="noreferrer">
                           <img src="/playstore.png" alt="playstore" className="download-app android-download"/>
                        </a>
                    </div>
                </div>
               </div>
            </div>
        </div>
    )
}

export default SuccessModal;