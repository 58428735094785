import { useContext } from 'react';
import { DisplayContext } from "../../../AppContexts/DisplayContext";
import { RiEBike2Fill } from 'react-icons/ri';
import {FaArrowLeft, FaBuilding} from "react-icons/fa"
import AppDownloadSection from './AppDownloadSection';

const PickupDelivery = () => {
    const {showMobileDeliveryOption, handleMobileMenuSwitch, handleCheck, locationValues, fullName, email, phone} = useContext(DisplayContext);
  
    const pickUpAddress = locationValues && locationValues.Address
    return (
        <div className={showMobileDeliveryOption === 10 ? "d-block" : "d-none"}>
            <div className="door-delivery-details">
                <div className="delivery-section-mobile">
                    <div onClick={() => handleMobileMenuSwitch(8)}><FaArrowLeft /></div> 
                    <div className="delivery-title-mobile">
                            Select a delivery method
                    </div>
                </div>
                    <div className="option-delivery-details">
                           <div className={showMobileDeliveryOption === 10 ? "door-img-container-disabled" : "door-img-container-mobile"} >
                               < RiEBike2Fill  className={showMobileDeliveryOption === 10 ? "door-media-img-disabled delivery-bike" : "delivery-bike"}/>
                           </div>
                            <label className="door_delivery_radio_label" htmlFor="home_delivery_info">
                            <div className="delivery-details-inactive">
                                <div className={showMobileDeliveryOption === 10 ? "delivery_title door-delivery-title-disabled" : "delivery_title"}>Door delivery</div>
                                <div className={showMobileDeliveryOption === 10 ? "door-delivery-title-disabled delivery_note" : "delivery_note"}>Choose this option to have a partner rider deliver to your provided address</div>
                            </div>
                               <input type="radio" name="delivery-details" value="door-mobile-delivery-values" className="door_delivery_radio_disabled" onChange={handleCheck} disabled={showMobileDeliveryOption === 10}/>
                            
                            </label>
                        </div>
                    <div className="mobile-delivery-hr"></div>
                        <div className="option-delivery-details">
                       
                            <div className="door-img-container-mobile">
                                <FaBuilding  className="delivery-bike"/>
                            </div>
                            <label className="door_delivery_radio_label" htmlFor="home_delivery_details">
                                <div className="delivery_details">
                                    <div className="delivery_title">{fullName}</div>
                                    <div className="delivery_note">{pickUpAddress}</div>
                                    <div className="delivery_note">{phone} | {email}</div>
                                </div>
                                <div>
                                  <input type="radio" value="pick-up-value" name="delivery-details" className="door_delivery_radio" onChange={handleCheck} checked/>
                                  <div className="mobile_pickup_radio"></div>
                                </div>
                            </label>
                        </div>
                    <>
                   <div className= "process-btn process-btn-width"onClick={() => handleMobileMenuSwitch(11)}>
                       <button>Proceed to Payment</button>
                    </div>
                   
                    </>
                </div>
                {/* <AppDownloadSection /> */}
        </div>
    )
}

export default PickupDelivery