// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cart_container__c6ByQ{
  width: 95%;
  margin: auto;
}

.Cart_container__c6ByQ h1{
  font-size: 24px;
  color: #192150;
  margin: 1rem 0 0.5rem 0;
}

.Cart_cart__Oiziz{
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  align-self:flex-start;
}

.Cart_spinner__MS1yr{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .Cart_container__c6ByQ{
    width: 83.06%;
    margin: auto;
  }
  
  .Cart_container-top__BRcgO{
    display: grid;
    grid-template-columns: 70.49% 27.51%;
    grid-gap: 2%;
    margin-bottom: 3.3125rem;
  }
  
  .Cart_container__c6ByQ h1{
    color: #192150;
    margin: 1.75rem 0;
  }
  
  .Cart_checkout__kS0ud{
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    align-self:flex-start;
  }
  
  .Cart_container-bottom__z9pUv{
    margin-bottom: 6.8125rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Cart/Cart.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE;IACE,aAAa;IACb,YAAY;EACd;;EAEA;IACE,aAAa;IACb,oCAAoC;IACpC,YAAY;IACZ,wBAAwB;EAC1B;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,0CAA0C;IAC1C,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".container{\n  width: 95%;\n  margin: auto;\n}\n\n.container h1{\n  font-size: 24px;\n  color: #192150;\n  margin: 1rem 0 0.5rem 0;\n}\n\n.cart{\n  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);\n  border-radius: 6px;\n  align-self:flex-start;\n}\n\n.spinner{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@media screen and (min-width: 768px) {\n  .container{\n    width: 83.06%;\n    margin: auto;\n  }\n  \n  .container-top{\n    display: grid;\n    grid-template-columns: 70.49% 27.51%;\n    grid-gap: 2%;\n    margin-bottom: 3.3125rem;\n  }\n  \n  .container h1{\n    color: #192150;\n    margin: 1.75rem 0;\n  }\n  \n  .checkout{\n    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);\n    border-radius: 6px;\n    align-self:flex-start;\n  }\n  \n  .container-bottom{\n    margin-bottom: 6.8125rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Cart_container__c6ByQ`,
	"cart": `Cart_cart__Oiziz`,
	"spinner": `Cart_spinner__MS1yr`,
	"container-top": `Cart_container-top__BRcgO`,
	"checkout": `Cart_checkout__kS0ud`,
	"container-bottom": `Cart_container-bottom__z9pUv`
};
export default ___CSS_LOADER_EXPORT___;
