import { useState } from 'react';
import React from 'react';
import Search from './Images/Search-icon.png';
import classes from './SearchLarge.module.css';

const SearchLarge=()=>{
    const [products, setProducts] = useState("")

    const search =async()=>{
        const response = await fetch('https://productservice.click/api/v1/product/all?page=1&limit=10', {
                method: 'GET',
                headers: {
                    "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJiZTgyMTQyOTg4OTg2ODM0MThiMjUiLCJlbWFpbCI6Im5pbWEuYXNoYWlAZG9vamF6ei5jb20iLCJyb2xlIjp7Im5hbWUiOiJjdXN0b21lciIsInJvbGVfaWQiOiI2MWRjNDU0MTdlYjk0ODg5NjllOWI3ZjUifSwidXNlcl90eXBlIjoiY3VzdG9tZXIiLCJzdWJzY3JpcHRpb25fc3RhdHVzIjoibm8gc3Vic2NyaXB0aW9uIiwiaWF0IjoxNjgwNTk5Njc0LCJleHAiOjE2ODA2ODYwNzR9.A7CrEFnmLBmbEPL0xRXRL_YZrPIZ0ZLSCvtdAD3jk8WW9d6wfalI7hfIUJbvMfn8dyyJ6XxYCtGVEmh908Q8fA"
                }
            });
            const data = await response.json()
            console.log(data);
    }
    const searchProducts=(event)=>{
        setProducts(event.target.value)
        search()
    }
    
    return(
        <div className={classes['search-container']}>
            <form className={classes['search-bar']}>
                <img src={Search} alt='search-icon'/>
                <input type='search' placeholder="Search for an item..." value={products} onChange={searchProducts}/>
                <div className={classes.categories}>
                    <label htmlFor= 'categories'>All Categories</label>
                    <select name='categories' id='categories'>
                    
                    </select>
                </div>
            </form>
        </div>
    )
}

export default SearchLarge;