import { useContext } from 'react';
import {FaCheckCircle} from "react-icons/fa"
import { DisplayContext } from "../../../AppContexts/DisplayContext";
const TransactionSuccess = ({slug}) => {
    const {showMobileDeliveryOption} = useContext(DisplayContext);
    const originalUrl =  window.location.origin;

    return (
        <div className={showMobileDeliveryOption === 6 ? "success__message_container d-block" : "d-none"}>
             <div className="transaction_success">
                 <div className="transaction_content">
                      <div className="success_image">
                         <FaCheckCircle className="success_icon"/>
                      </div>
                      
                      <h4 className="success_text">Order has been placed successfully</h4>
                    
                      <p className="success_message">Your order has been placed successfully. <br />The order is on the way to the seller for acceptance, your tracking ID will be sent to your email.</p>
                 </div>
                 <div className="store-link">
                     <a href={`${originalUrl}/stores/${slug}`} target="_blank" rel="noreferrer">
                         View other products from the store
                     </a>
                 </div>
            </div>
            <div className="content_footer_mobile">
                <hr className="download_hr"/>
                <div className="download_placeholder">Download Alpha APP</div>
                <div className="download_app_links">
                    <div className="appstore">
                         <a href="https://apps.apple.com/us/app/alphashop/id1615734219" target="_blank" rel="noreferrer">
                            <img src="/appstore.png" alt="playstore" className="download_app"/>
                         </a>
                    </div>
                    <div className="playstore">
                      <a href="https://play.google.com/store/apps/details?id=com.thealphashops" target="_blank" rel="noreferrer">
                          <img src="/playstore.png" alt="playstore" className="download_app"/>
                       </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionSuccess;