import Carousel from './Carousel';
import classes from './HomePage.module.css'
import Services from './Services';
import PopularNearYou from './PopularNearYou';
import Categories from './Categories';
import ProductsYouLike from './ProductsYouLike';
import Advert from '../../Components/shared/Advert';
import ClosestToYou from './ClosestToYou';
import Loader from '../../Components/shared/Loader';
import ScrollToTop from '../../Components/UI/ScrollToTop';
import { useState } from 'react';

const HomePage = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    

    return(
        <div className={classes['home-page-container']}>
            <div className={classes['page-top']}>
                <div className={classes['home-category']}><Categories/></div>
                <div className={classes.carousel}>
                    <Carousel/>
                </div>
            </div>
            <div className={classes['page-body-container']}>
                <div className={classes['page-body']}>
                    <div className={classes['page-body-1']}>
                        <div className={classes['services-1']}><Services/></div>
                        <div className={classes['near-you']}><PopularNearYou/></div>
                        <div>
                            <div className={classes['services-2']}><Services/></div>
                            <div className={classes['products-you-like']}><ProductsYouLike/></div>
                        </div>
                    </div>
                    {/* <div className={classes['page-body-2']}>
                        <div></div>
                        <div className={classes.advert}>
                            <Advert/>
                        </div>
                    </div> */}
                    <div className={classes['page-body-3']}>
                        <div></div>
                        <div className={classes['close-to-you']}>
                            <ClosestToYou/>
                        </div>
                    </div>
                    <div className={classes['page-body-4']}>
                    <div></div>
                        <div>
                            <Loader/>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop/>
        </div>
    )
}

export default HomePage;