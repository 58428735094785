// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FooterMobile_container__jHfPh{
    background-color: #192150;
    text-align: center;
    padding-bottom: 0.6rem;
}

.FooterMobile_top__pNmJQ{
    width: 91.7%;
    margin: auto;
    border-bottom: 1px solid white;
    margin-bottom: 3rem;
    padding-bottom: 0.2rem;
}

.FooterMobile_top__pNmJQ div{
    color: white;
    font-size: 0.625rem;
}

.FooterMobile_bottom__mf6gB{
    font-size: 0.625rem;
}

@media screen and (min-width: 480px) {
    .FooterMobile_top__pNmJQ div{
        font-size: 0.8rem;
    }
    
    .FooterMobile_bottom__mf6gB{
        font-size: 0.8rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/shared/FooterMobile.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,8BAA8B;IAC9B,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".container{\n    background-color: #192150;\n    text-align: center;\n    padding-bottom: 0.6rem;\n}\n\n.top{\n    width: 91.7%;\n    margin: auto;\n    border-bottom: 1px solid white;\n    margin-bottom: 3rem;\n    padding-bottom: 0.2rem;\n}\n\n.top div{\n    color: white;\n    font-size: 0.625rem;\n}\n\n.bottom{\n    font-size: 0.625rem;\n}\n\n@media screen and (min-width: 480px) {\n    .top div{\n        font-size: 0.8rem;\n    }\n    \n    .bottom{\n        font-size: 0.8rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FooterMobile_container__jHfPh`,
	"top": `FooterMobile_top__pNmJQ`,
	"bottom": `FooterMobile_bottom__mf6gB`
};
export default ___CSS_LOADER_EXPORT___;
