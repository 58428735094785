import classes from './PopularNearProducts.module.css';
import locationTag from './Images/location.png';
import { Link } from 'react-router-dom';
import numbro from 'numbro';
import { AppContext } from '../../AppContexts/AppContext';
import { useContext } from 'react';

const PopularNearProducts=(props)=>{
    const {normalizeCase} = useContext(AppContext)    
    return(
        <Link to={props.id}>
            <div className={classes.container}>
                <div className={classes['near-product-image']}><img src={props.image} alt="yru"/></div>
                <div className={classes['details']} title={props.name}>
                    <h4>{normalizeCase(props.name)}</h4>
                    <p>&#x20A6; {numbro(props.amount).format({thousandSeparated: true})}</p>
                    <div className={classes['product-location']}><img src={locationTag} alt="shja"/><span>{props.location || props.locality}</span></div>
                </div>
            </div>
        </Link>
    )
}

export default PopularNearProducts;