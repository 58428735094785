import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    otp: null
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser(state, action){
        
        },

        getOtp(state, action){
           state.otp = action.payload
        }
    }
})

export const userActions = userSlice.actions

export default userSlice.reducer;