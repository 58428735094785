import { useEffect, useState } from 'react';
import classes from './Store.module.css';
import {getAuthToken} from '../../utility/utility';
import { Link } from 'react-router-dom';

const Stores =()=>{
    const token = getAuthToken()
    const [stores, setStores]= useState([])
    useEffect(()=>{
        const loadStores=async()=>{
            const response = await fetch('https://productservice.click/api/v1/store/list', {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            })

            const data = await response.json()
            console.log(data)
            setStores(data.results.docs)
        }

        loadStores()
    }, [token])

    console.log(stores);

    return(
        <>
            {<div className={classes.container}>
                <h1>STORES</h1>
                <p>Explore and shop from various merchant stores</p>
                <div className={classes.stores}>
                    {stores.map((store)=><Link to={`${store.slug}`} key={store._id}>
                        <div className={classes.store}>
                            <div><img src={store.image_url} alt={store.name}/></div>
                            <span>{store.name}</span>
                        </div></Link>)}
                </div>
            </div>}
        </>
    )
}

export default Stores;