import { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { GetStore } from '../../Actions/GetStore';
import { AppContext } from '../../AppContexts/AppContext';
import { DisplayContext } from '../../AppContexts/DisplayContext';

import InfinityScroll from "react-infinite-scroll-component"
import Loader from '../../utility/Loader';
import "./store.css";

const StoreDetail = () => {
    const {loading_store, store, store_error} = useSelector(state => state.GetStoreReducer);
    const dispatch = useDispatch(null);
    const {numberSeperator} = useContext(AppContext);
    const [storeData, setStoreData] = useState([]);
    const [currentPage] = useState(1);
    const [itemPerPage,setItemPerPage] = useState(8);
    const [hasMore, setHasMore] = useState(true)
    const {search} = useContext(DisplayContext)
    const {slug} = useParams();
    const name = storeData && storeData.results && storeData.results.name;
    const description = storeData && storeData.results && storeData.results.description;
    const storeProducts = storeData && storeData.results && storeData.results.products;
    const storePicture = storeProducts && storeProducts[0] && storeProducts && storeProducts[0].store && storeProducts[0].store.picture 
   
    const lastPageIndex = currentPage * itemPerPage;
    const firstPageIndex = lastPageIndex - itemPerPage
    const currentData = storeProducts && storeProducts.slice(firstPageIndex,lastPageIndex);
    // const sortedProduct = currentData && currentData.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
    const totalItem = storeProducts && storeProducts.length;

    const filteredProduct =
    storeProducts &&
    currentData.filter((product) =>
      product.productName.toLowerCase().includes(search.toLowerCase()) || product.categoryName.toLowerCase().includes(search.toLowerCase())
    );
  const dataToDisplay = search ? filteredProduct :  currentData;

 
  const fetchMoreData = () => {
        if(( itemPerPage < totalItem)){
            setTimeout(() => {
                setItemPerPage(prev => prev + 2)
            }, 500)
        }else {
            setHasMore(false)
        }
       
  }
  console.log("description length", description?.length, currentData && currentData.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)))

    useEffect(() => {
         dispatch(GetStore(slug))
    }, [dispatch, slug]);
    useEffect(() => {
        if(store){
            setStoreData(store)
        }
    }, [store])

    return (
        <>
           {
             store_error ? <div className="failed-alert">
                {store_error.message || store_error}
             </div> :
            loading_store ?        
                <div className="tracking-loading">                           
                    <Loader />
                </div> : 
               
                
                <div>
                    <section className="store_section">
                        <div className="store__banner"></div>
                            <div className="store_banner_img">
                                <img src={storePicture} alt="store-logo" className="store__logo"/>
                            </div>
                            <div className="store_owner_details">
                                <div className={name && name.length > 15 ? "store__details_len" : "store__details"}>
                                    <h3>{name}</h3>
                                </div>
                            
                                <p className="store__description">
                                    {description}
                                </p>
                        </div>
                    </section>
                    {/* <section className="delivery_info">
                        <div className="container">
                            <div className="delivery_info_container">
                                <div className="services-desc"><div className="service-img"><img src="/fast-delivery.png" alt="fast-delivery"/></div><div className="service-rendered">Fast Delivery</div></div>
                                <div className="services-desc"><div className="service-img"><img src="/customer-care.png" alt="fast-delivery"/></div><div className="service-rendered">Customer Support</div></div>
                                <div className="services-desc"><div className="service-img"><img src="/seamless-payment.png" alt="fast-delivery"/></div><div className="service-rendered">Seamless payment</div></div>
                                <div className="services-desc"><div className="service-img"><img src="/best-price.png" alt="fast-delivery"/></div><div className="service-rendered">Best Prices</div></div>
                            </div>
                        </div>
                    </section> */}
                    <section className="prod-from-store">
                        <div className="container">
                            <div>Products from this Store</div>
                        </div>
                    </section>
                    {dataToDisplay && dataToDisplay.length > 0 ? 
                          <section className="store_products">
                            <InfinityScroll dataLength={dataToDisplay.length} next={fetchMoreData} hasMore={hasMore} loader={<p className="text-center">Loading...</p>} endMessage={<p className="text-center">No more item to show!</p>}>
                                <div className="container">
                            
                              <div className="row mt-4">
                                
                                    {dataToDisplay && dataToDisplay.map(product => (
                                        <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-height" key={product._id}> 
                                        {console.log("All products", product)}   
                                            <Link to={`/${product._id}`} className="store_link_alpha" target="__blank">      
                                                <div className="product_content">
                                                        <div className="shop_img">
                                                            <img src={product.front_view} className="product_shop_img" alt="product img"/>
                                                        </div> 
                                                        <p>{product.productName}</p>
                                                        <span>₦ {numberSeperator(product.price)}</span>
                                                
                                                </div>
                                            </Link>
                                        </div>
                                        ))}
                                   
                              </div>
                           
                                </div>  
                          </InfinityScroll> 
                      </section>: <div className="unavailable-product">Item is not available on this store</div>     
                    }
                   
            </div>
          
            }
           
         {/* <AppDownloadSection /> */}
        </>

    )
}
export default StoreDetail