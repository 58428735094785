import React, { useContext } from 'react';
import classes from './ValidationModal.module.css';
import { BsPerson, BsArchive, BsHeart } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContexts/AppContext';
import { getAuthToken } from '../../utility/utility';

const ValidationModal=()=>{
    const {setRecentUrl} = useContext(AppContext)
    const token = getAuthToken() && JSON.parse(getAuthToken())

    const logoutHandler = () => {
        localStorage.removeItem('cred')
        localStorage.removeItem('token')
    }

    return(
        <div className={classes['login-modal']}>
            <div className={classes['btn-container']}>
                {!token && <Link onClick={()=> setRecentUrl("/")} to='/login'><button>SIGN IN</button></Link>}
                {token && <Link to='/home'><button onClick={logoutHandler}>SIGN OUT</button></Link>}
            </div>
            <div className={classes['cat-container']}>
                <div className={classes['login-modal-cat']}>
                    <div><BsPerson/></div>
                    <div>My Account</div>
                </div>
                <div className={classes['login-modal-cat']}>
                    <div><BsArchive/></div>
                    <div>Orders</div>
                </div>
                <div className={classes['login-modal-cat']}>
                    <div><BsHeart/></div>
                    <div>Saved items</div>
                </div>
            </div>
        </div>
    )
}

export default ValidationModal;
