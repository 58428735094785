import { useContext, useEffect, useState } from 'react';
import {FaArrowLeft} from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md";
import "./mobile.css"
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
  } from 'react-places-autocomplete';
import { DisplayContext } from '../../../AppContexts/DisplayContext';
import "./mobile.css"
import AppDownloadSection from './AppDownloadSection';
import { AppContext } from '../../../AppContexts/AppContext';
import { RegisterGuest } from '../../../Actions/RegisterGuest';
import { useDispatch, useSelector } from 'react-redux';
import { GetEta } from '../../../Actions/GetEta';
import { formatDate } from '../../../helpers/formatDate';
const DeliveryDetailMobile = ({product}) => {
    const [localityErrorMsg, setLocalityErrorMsg] = useState("");
    const [allError, setAllError] = useState("")

    const {showMobileDeliveryOption, fullName, setFullName, email, setEmail, phone, setPhone, address, setAddress, state, setState, home_delivery_loading, home_delivery, home_delivery_error, local_station_error, local_station, setReceiverCoordinate, setStationId, handleMobileMenuSwitch, emailValidation, phoneValidation,setRecieverLGA, setRecieverCity}   = useContext(DisplayContext)
    const {mobileeDoorDeliveryPhoneRef, mobileeDoorDeliveryEmailRef,  handleErrorForMobileDoorDelivery, genPassword,isEmpty} = useContext(AppContext);
    const dispatch = useDispatch(null);
    const {get_eta_loading,eta} =useSelector (state => state.getEta);
    const merchant_state = product?.results?.locality;
    const getNames = fullName && fullName.trim().split(/\s+/);
    const first_name = getNames && getNames.length > 0 && getNames[0]
    const last_name = getNames && getNames.length > 2 ? getNames[1] + " " + getNames[2] : getNames && getNames.length > 1 ? getNames[1] : "";

    const handleSelect = async  value => {
        const result = await geocodeByAddress(value);
        const latlng = await getLatLng(result[0])
        setAddress(value)
        setState(result)
        setReceiverCoordinate(latlng)
     
   }

   
   // Convert array of address to a single array from the google places result

   const getName = state && state.map(state => state.address_components).flat();

   // get delivery home addresses
   const getLocality = home_delivery && home_delivery.results && home_delivery.results.Object;

   const LGAName = []
   getLocality && getLocality.map(locality => {
     return LGAName.push(locality.LGAName)
    });

      // Get delivery locality from google
   let localityName, locality;



   if(getName) {
        for (let name of getName) {
            locality = name["types"].includes("administrative_area_level_2")
            if(locality){
             localityName = name.long_name
            }
        
        }
   }

 
 // Get delivery state from google
   let stateName, locality2;
    if(getName){
        for (let name of getName) {
            locality2 = name["types"].includes("administrative_area_level_1")
            if(locality2){
             stateName = name.long_name
            }
    
    }
    }
 
   const getStationData = local_station && local_station.results && local_station.results.Object;

  
   const stationStateName = getStationData && getStationData.find(station => station?.StationName?.toLowerCase() === stateName?.toLowerCase());

   const destination_state = stationStateName && stationStateName.StateName;
   const departure_state = merchant_state === "Lekki" ? "Lagos" : merchant_state

   let receiverStationId = stationStateName && stationStateName.StationId;


const handleDelivery = () => {
    
    if(!first_name || !last_name || !email || !phone || !address){
         setAllError("All inputs fields are required*")
        
     }
    
    if(!emailValidation || !phoneValidation){
        setAllError("Invalid email or phone number");
        return
    }
    dispatch(RegisterGuest({first_name, last_name, email, phone, password: genPassword()}))
     if(stationStateName && stationStateName?.StateName !== undefined){
        handleMobileMenuSwitch(4)
      
     }else {
        setLocalityErrorMsg("This address is not within GIG home delivery locations, please use the GIG Pickup location (Click the back button)")
     }
}

   useEffect(() => {
      setStationId(receiverStationId);
      setRecieverLGA(localityName);
      setRecieverCity(stateName)
   }, [receiverStationId, setStationId, localityName, stateName,setRecieverCity, setRecieverLGA])
 
   useEffect(() => {
    if(destination_state !== undefined  && departure_state !== undefined ){
         dispatch(GetEta({ "departure_state": departure_state, 
         "destination_state":  destination_state}))
    }
}, [destination_state, departure_state])
    return <div className={showMobileDeliveryOption === 3 ? "d-block delivery__details__mobile" : "d-none"}>
            
            <div className="back__navigation__mobile"><span onClick={() => handleMobileMenuSwitch(2)}><FaArrowLeft /></span><span className="checkout__header">Delivery details</span></div>
            <div className="details_body">
             {localityErrorMsg && <><p className="text-center text-danger" style={{fontSize: "12px"}}><small>{localityErrorMsg}</small></p>
              <div className="back-navigation"   onClick={() => {handleMobileMenuSwitch(2); setLocalityErrorMsg("")}}>
                <button className="back-btn-navigate" ><FaArrowLeft />&nbsp;Go back</button>
            </div>
             </>}
                {
                    home_delivery_error && <div className="container alert alert-danger" role="alert">
                    {home_delivery_error}
                </div>
                }
                   {
                    local_station_error && <div className="container alert alert-danger" role="alert">
                    {local_station_error}
                </div>
                }
                {home_delivery_loading ? <div style={{width: "2rem", height: "2rem"}}
                  className="spinner-border shipment-spinner loader-spinner"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div> :
                <div className="form-alignment">
                 <form>
                     <div className="delivery-details-mobile-input">
                         <label>Full Name</label>
                         <input type="text" placeholder="First Name" className="input-text-mobile" onChange={(e) => {setFullName(e.target.value); setLocalityErrorMsg(""); setAllError("")}} value={fullName}/>
                     </div>
                     <div className="delivery-details-mobile-input mt-2">
                         <label>Email address</label>
                         <input type="email" placeholder="example@giglogistics.ng" className="input-text-mobile" onChange={(e) => {setEmail(e.target.value); setLocalityErrorMsg("")}} value={email} ref={mobileeDoorDeliveryEmailRef} name="email" onKeyUp={() =>  handleErrorForMobileDoorDelivery(mobileeDoorDeliveryEmailRef.current, mobileeDoorDeliveryEmailRef.current.name)} required="required"/>
                         <p>Email is invalid</p>
                     </div>
                     <div className="delivery-details-mobile-input  mt-2">
                         <label>Phone Number</label>
                         <input type="tel" placeholder="080********" className="input-text-mobile" onChange={(e) => {setPhone(e.target.value); setLocalityErrorMsg(""); setAllError("")}} value={phone} required="required" ref={mobileeDoorDeliveryPhoneRef} onKeyUp={() =>  handleErrorForMobileDoorDelivery(mobileeDoorDeliveryPhoneRef.current, mobileeDoorDeliveryPhoneRef.current.name)} name="telephone" />
                         <p>Phone number must be an 11 digit number</p>
                     </div>
                     <div className="delivery-details-mobile-input  mt-2">
                         <label>Delivery Address</label>
                         <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
                             {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                 <>
                                <textarea {...getInputProps({placeholder: "Delivery address", className: "input-text-mobile"})}></textarea>
                              
                                   {loading ? <div>Loading...</div> : null}
                                   {suggestions.map((suggestion, index) => {
                                       const style = {
                                           backgroundColor: suggestion.active ? "#08ffc8": "#fff",
                                           cursor: "pointer",
                                           fontSize: "14px",
                                           margin: "5px 0",
                                           fontWeight: 600

                                       }
                                       
                                       return  <div key={index} {...getSuggestionItemProps(suggestion, {style})}>{suggestion.description}</div>
                                    })}
                                
                                </>
                             )}
                             
                         </PlacesAutocomplete>
                     </div>
                     <div className="delivery-details-input  mt-2">
                             <label>Service Station</label>
                             <div className="station-name">{stationStateName && stationStateName.StateName}</div>
                    </div>
                 
                 </form>
                  {allError && <p className="text-danger text-center">{allError}</p>}
                  {get_eta_loading && destination_state !== undefined && departure_state !== undefined && <div   className="loader-style-eta">                           
                                <div className="spinner-border loader-spinner-eta" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="loading-text">Please waiting...</div>
                            </div>}
                            {
                               !get_eta_loading && !isEmpty(eta) && destination_state !== undefined && departure_state !== undefined ?  <div className="eta-display"><MdInfoOutline /> {`Expected time of arrival is ${formatDate(eta.results)}`}</div> : null
                            }
                     <div className= "location-btn">
        
                        <button onClick={handleDelivery} disabled={get_eta_loading}>{get_eta_loading && eta  ? "Please wait..." : "Submit"}</button>
                     </div>
                </div>
               }
            </div>
            {/* <AppDownloadSection /> */}
    </div>
}



export default DeliveryDetailMobile