import React from "react";
import classes from './Categories.module.css';
import rightArrow from './Images/Vector.png';
import { BsArchive, BsHeart } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";

const Categories = () =>{
    const params = useParams()

    return (
        <div className={classes["categories-container"]}>
            <div className={classes["category-head"]}>
                <h3>Product Categories</h3>
            </div>
            <Link to={`category/${params.id}`}>
                <div className={classes.category}>
                    <div>Mobile Phones & tablets</div>
                    <span><img src={rightArrow} alt=""/></span>
                </div>
            </Link>
            <Link to={`category/${params.id}`}>
                <div className={classes.category}>
                    <div>Bags</div>
                    <span><img src={rightArrow} alt=""/></span>
                </div>
            </Link>
            <Link to={`category/${params.id}`}>
                <div className={classes.category}>
                    <div>Clothing</div>
                    <span><img src={rightArrow} alt=""/></span>
                </div>
            </Link>
            <Link to={`category/${params.id}`}>
                <div className={classes.category}>
                    <div>Hair</div>
                    <span><img src={rightArrow} alt=""/></span>
                </div>
            </Link>
            <Link to={`category/${params.id}`}></Link>
                <div className={classes.category}>
                    <div>Shoes</div>
                    <span><img src={rightArrow} alt=""/></span>
                </div>
            <Link/>
            <Link to={"category"}>
                <div className={classes.category}>
                    <div>More Categories</div>
                    <span><img src={rightArrow} alt=""/></span>
                </div>
            </Link>
            <div className={classes["cat-under"]}>
                <div className={classes["category-head"]}>
                    {/* <div><BsPerson/></div> */}
                    <h3>My Account</h3>
                </div>
                <div className={classes.category}>
                    <div className={classes["acc-list"]}>
                        <div><BsArchive/></div>
                        <div>Orders</div>
                    </div>
                    <span><img src={rightArrow} alt=""/></span>
                </div>
                <div className={classes.category}>
                    <div className={classes["acc-list"]}>
                        <div><BsHeart/></div>
                        <div>Saved items</div>
                    </div>
                    <span><img src={rightArrow} alt=""/></span>
                </div>
                <div className={classes["category-button"]}><Link to='/stores'><button>Stores</button></Link></div>
            </div>
        </div>
    );
}
 
export default Categories;