import React from 'react';
import classes from "./Footer.module.css";
import { FaFacebookSquare, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';


const Footer = () => {
    return (
        <div className={classes['footer-container']}>
            <div className={classes['footer-top']}>
                <div className={classes['footer-top_left']}>
                    <h4>Company</h4>
                    <p>About Us</p>
                    <p>Find a Store</p>
                    <p>Rules and Terms</p>
                    <p>Sitemap</p>
                </div>
                <div className={classes['footer-top_left']}>
                    <h4>Help</h4>
                    <p>Contact Us</p>
                    <p>Money Refund</p>
                    <p>Order Status</p>
                    <p>Shipping Info</p>
                    <p>Open Dispute</p>
                </div>
                <div className={classes['footer-top_left']}>
                    <h4>Account</h4>
                    <p>Login</p>
                    <p>Register</p>
                    <p>Account Setting</p>
                    <p>My Orders</p>
                </div>
                <div className={classes['footer-end']}>
                    <h4>Social</h4>
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer" className={classes.wrapper}>
                        <div><FaFacebookSquare className='social-icons'/></div><div>Facebook</div>
                    </a>
                    <a href='https://www.twitter.com' target="_blank" rel="noopener noreferrer" className={classes.wrapper}>
                        <div><FaTwitter className='social-icons'/></div><div>Twitter</div>
                    </a>
                    <a href='https://www.instagram.com' target="_blank" rel="noopener noreferrer" className={classes.wrapper}>
                        <div><FaInstagram className='social-icons'/></div><div>Instagram</div>
                    </a>
                    <a href='https://www.youtube.com' target="_blank" rel="noopener noreferrer" className={classes.wrapper}>
                        <div><FaYoutube className='social-icons'/></div><div>YouTube</div>
                    </a>
                </div>
            </div>
            <div className={classes['footer-bottom']}>
                <p>&copy;GIGAlpha. All rights reserved</p>
            </div>
        </div>
    );
}

export default Footer;