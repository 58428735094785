import React, { useState } from 'react';
import classes from'./PopularNearYou.module.css';
import locationTag from './Images/location.png';
import PopularNearProducts from './PopularNearProducts';
import { useGetAllProductsQuery } from '../../store/apiSlice';

const PopularNearYou = (props) => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(12)
    const {data, isLoading, isError, isFetching, isSuccess} = useGetAllProductsQuery({page, limit})
    // const nextPage = data?.nextPage;
    // const hasNext = data?.hasNextPage
    // const currentPage = data?.page
    // const offset = data?.offset

    const moveToNextPage =()=>{
            // setPage(prev => prev + 1)

            setLimit(prev=> prev + 10)
    }
    console.log(data);
    console.log(limit);

    const products = data?.results?.docs
    console.log("next", data?.results);
    
    return(
        <div className={classes['location-container']}>
            {/* <button onClick={moveToNextPage}>Load More</button> */}
            <div className={classes.location}>
                <div><img src={locationTag} alt=""/></div>
                <div>Popular near you</div>
            </div>
            <div className={classes.cover}>
                {isLoading && <p>Loading...</p>}
                {isFetching && <p>Fetching...</p>}
                {isError && <p>Error</p>}
                {isSuccess && products.length > 0 && products.map((product)=><PopularNearProducts key={product._id} id={product._id} image={product.front_view} name={product.productName} amount={product.price} location={product.neighborhood}/>)}
            </div>
        </div>
    )
}

export default PopularNearYou;