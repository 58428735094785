import { useContext } from 'react';
import { FaArrowLeft, FaBuilding } from 'react-icons/fa';
import { DisplayContext } from '../../../AppContexts/DisplayContext';
import { RiEBike2Fill } from 'react-icons/ri';
const MobileDeliveryOptions = () => {
    const {showMobileDeliveryOption, setToggle, handleCheck, changeDeliveryOptions, handleMobileMenuSwitch, check } = useContext(DisplayContext)
    return(
      <div className={showMobileDeliveryOption === 2  ? "d-block mt-5": "d-none"}>
          <div className="delivery-section-mobile">
              <div onClick={() => handleMobileMenuSwitch(1)}><FaArrowLeft /></div> 
              <div className="delivery-title-mobile">
                    Select a delivery method
              </div>
          </div>
          <div className="select-options-mobile">
              <div className="option-delivery-select">
                    <div className="img-container-mobile">
                        < RiEBike2Fill  className="delivery-bike"/>
                    </div>
               
                    <label htmlFor="delivery-select" className="radio">
                         <div className="delivery_details">
                            <div className="delivery_title">Door delivery</div>
                            <div className="delivery_note">Choose this option to have a partner rider deliver to your provided address</div>
                         </div>   
                         <input type="radio" name="delivery-mobile" value="door-delivery-mobile" onClick={(e) => {e.stopPropagation();setToggle(false)}} id="delivery-select" className="radio__input" onChange={handleCheck}/>
                         <div className="radio__radio"></div>
                         
                    </label>
                   
                </div>      
                <div className="mobile-delivery-hr"></div>
                <div className="option-delivery-select">
                    <div className="img-container-mobile">
                        < FaBuilding className="delivery-bike"/>
                    </div>
               
                    <label htmlFor="pickup-select" className="radio">
                         <div className="delivery_details">
                            <div className="delivery_title">GIGL Experience center</div>
                            <div className="delivery_note">Choose this option to pick-up from any GIGL experience center close to you</div>
                         </div>   
                         <input type="radio" name="delivery-mobile" value="pickup-station-mobile" onClick={(e) => {e.stopPropagation();setToggle(true)}} id="pickup-select" className="radio__input" onChange={handleCheck}/>
                         <div className="radio__radio"></div>
                         
                    </label>
                   
                </div> 
                <div className={check === "door-delivery-mobile" || check=== "pickup-station-mobile" ? "proceed-to-pay" : "proceed-to-pay checkout-btn-disabled"} onClick={changeDeliveryOptions}>
                    <button>Proceed</button>
                </div>     
          </div>
      </div>
    )
}
export default MobileDeliveryOptions