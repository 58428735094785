import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
    totalQuantity: 0,
    cartId: ''
}

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addItemToCart(state, action){
            const newItem = action.payload
            const existingItem = state.items.find(item=>item.id === newItem.id)
            state.totalQuantity++
            if(!existingItem){
                state.items.push({
                    id: newItem.id,
                    price: newItem.price,
                    quantity: 1,
                    totalPrice: newItem.price,
                    name: newItem.name,
                    alphaCode: newItem.alphaCode,
                    size: newItem.size,
                    location: newItem.location,
                })
            } else{
                existingItem.quantity++
                existingItem.totalPrice = existingItem.totalPrice + newItem.price
            }
        },
        removeItemFromCart(state, action){
            const id = action.payload
            const existingItem = state.items.find(item => item.id === id)
            state.totalQuantity--
            if(existingItem.quantity === 1){
                state.items = state.items.filter(item=> item !== id)
            } else {
                existingItem.quantity--
                existingItem.totalPrice = existingItem.totalPrice - existingItem.price
            }
        },
        getCartId(state, action){
            state.cartId = action.payload
        }
    }
})

export const cartActions = cartSlice.actions

export default cartSlice.reducer;