// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewPassword_page__AZ9g\\+{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.NewPassword_container__Nni6h{
    width: 70%;
    margin: auto;
    text-align: center;
    color: #192150;
}

.NewPassword_container__Nni6h div{
    margin-bottom: 1rem;
}

.NewPassword_container__Nni6h p{
    text-align: center;
    margin: auto;
    color: #192150;
}

.NewPassword_container__Nni6h label{
    margin-bottom: .5rem;
    font-weight: 500;
}

.NewPassword_container__Nni6h input{
    width: 80%;
    margin-bottom: 1rem;
    padding: 0.5rem;
}

.NewPassword_container__Nni6h button{
    width: 50%;
    padding: .8rem;
    border-radius: .5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/vaidationPage/NewPassword.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,oBAAoB;AACxB","sourcesContent":[".page{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    min-height: 100vh;\n}\n\n.container{\n    width: 70%;\n    margin: auto;\n    text-align: center;\n    color: #192150;\n}\n\n.container div{\n    margin-bottom: 1rem;\n}\n\n.container p{\n    text-align: center;\n    margin: auto;\n    color: #192150;\n}\n\n.container label{\n    margin-bottom: .5rem;\n    font-weight: 500;\n}\n\n.container input{\n    width: 80%;\n    margin-bottom: 1rem;\n    padding: 0.5rem;\n}\n\n.container button{\n    width: 50%;\n    padding: .8rem;\n    border-radius: .5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `NewPassword_page__AZ9g+`,
	"container": `NewPassword_container__Nni6h`
};
export default ___CSS_LOADER_EXPORT___;
