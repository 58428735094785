import { useContext, useEffect, useCallback, useState } from 'react';
import {FaArrowLeft, FaBuilding} from "react-icons/fa"
import { RiEBike2Fill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { ShipmentPrice } from '../../../Actions/ShipmentPrice';
import { DisplayContext } from '../../../AppContexts/DisplayContext';
import { getUrl } from '../../../helpers/getUrl';
import AppDownloadSection from './AppDownloadSection';
import "./mobile.css";

const DoorDeliveryMobile = ({vehicleType}) => {
    const [locationError, setLocationError] = useState("");
    const [errorLocation, setErrorLocation] = useState(false)
    const {handleCheck, fullName, email, phone, address,  stationId, senderLat,senderLong, senderName, senderPhoneNumber, senderStationId, senderAddress, itemWeight,itemName, receiverCoordinate, price, qty, senderLocality, showMobileDeliveryOption, handleMobileMenuSwitch, check} = useContext(DisplayContext)
    const dispatch = useDispatch()
    const { shipment_price_loading, shipment_price,shipment_price_error} = useSelector(state => state.ShipmentPriceReducer);
    const checkShipmentCode = shipment_price && shipment_price.code;
    const checkShipCodeSuccess = shipment_price && shipment_price.results && shipment_price.results.Code
    const dataToSubmit = {
        "ReceiverName": fullName,
        "ReceiverPhoneNumber": phone,
        "ReceiverEmail": email,
        "ReceiverAddress": address,
        "ReceiverStationId": stationId,
        "ReceiverLocation": {
            "Latitude": receiverCoordinate?.lat && receiverCoordinate.lat.toString(), 
            "Longitude": receiverCoordinate?.lng && receiverCoordinate.lng.toString()
        },
        "Merchants": [
            {
                "CustomerCode": getUrl("ACC001164", "IND265936"),  
                "SenderName": senderName,
                "SenderPhoneNumber": senderPhoneNumber,
                "SenderStationId": +senderStationId,
                "SenderLocality": senderLocality,
                "SenderAddress": senderAddress, 
                "SenderLocation": {
                    "Latitude": senderLat &&  senderLat.toString(), 
                    "Longitude": senderLong && senderLong.toString()
                },
                "PaymentType": "Cash",
                "PreShipmentItems": [
                    { "SpecialPackageId": "0", "Quantity": qty && qty.toString(), "Weight": itemWeight, "ItemType": "Normal", "WeightRange": "0", "ItemName": itemName, "Value": price, "ShipmentType": "Regular" }
                ]
            },
            
        ],
        "PickupOptions": "HOMEDELIVERY",
        "IsHomeDelivery": true,
        "VehicleType": vehicleType?.toUpperCase() || "BIKE",
        "PaymentType": "Cash"
    }

    const getShippingFee = () => {
        if(check !== ""){
           dispatch(ShipmentPrice(dataToSubmit));
        }
    }
 
    const goToPaymentMobile = useCallback(() => {
        if(checkShipmentCode === 200 &&  checkShipCodeSuccess === "200" && showMobileDeliveryOption === 4){
            handleMobileMenuSwitch(5)
        }
    }, [checkShipmentCode, checkShipCodeSuccess,showMobileDeliveryOption]);
    const locationErrors = useCallback(() => {
        if(shipment_price_error && shipment_price_error === "The Mapping of Route to Zone does not exist"){
            setLocationError("This address is not within GIGL home delivery locations, please use the GIGL Pickup location or choose another location");
            setErrorLocation(true)
        }
    }, [shipment_price_error])
useEffect(() => {
    locationErrors()
}, [locationErrors])
    useEffect(() => {
        goToPaymentMobile()
    }, [goToPaymentMobile]);    
    return (
        <div className={showMobileDeliveryOption === 4 ? "d-block" : "d-none"}>
            <div className="door-delivery-details">
                <div className="delivery-section-mobile">
                    <div onClick={() => handleMobileMenuSwitch(3)}><FaArrowLeft /></div> 
                    <div className="delivery-title-mobile">
                            Select a delivery method
                    </div>
                </div>
                    <div className="option-delivery-details">
                           <div className="door-img-container-mobile">
                               < RiEBike2Fill  className="delivery-bike"/>
                           </div>
                            <label className="door_delivery_radio_label" htmlFor="home_delivery_info">
                            <div className="delivery_details">
                                <div className="delivery_title">{fullName}</div>
                                <div className="delivery_note">{address}</div>
                                <div className="delivery_note">{phone} | {email}</div>
                            </div>
                               <input type="radio" name="delivery-details-door-mobile" value="door-mobile-delivery-values" checked className="door_delivery_radio"  onChange={handleCheck} id="home_delivery_info"/>
                               <div className="door_mobile_delivery__radio_btn"></div>
                            
                            </label>
                        </div>
                    <div className="mobile-delivery-hr"></div>
                        <div className="option-delivery-details">
                       
                            <div className={showMobileDeliveryOption === 4 ? "door-img-container-disabled door-img-container-disabled-mobile" : ""}>
                                <FaBuilding className={showMobileDeliveryOption === 4 ? "door-media-img-disabled" : "delivery-bike"}/>
                            </div>
                            <label className="door_delivery_radio_label" htmlFor="home_delivery_details">
                                <div className="delivery-details-inactive">
                                    <div className={showMobileDeliveryOption === 4 ? "delivery_title door-delivery-title-disabled" : "delivery_title"}>GIGL Experience center</div>
                                    <div className={showMobileDeliveryOption === 4 ? "door-delivery-title-disabled delivery_note" : "delivery_note"}>Choose this option to pick-up from any GIGL experience center close to you</div>
                                </div>
                                <div>
                                <input type="radio" value="pick-up-value" name="delivery-details-door-mobile" className="door_delivery_radio_disabled" onChange={handleCheck} disabled={showMobileDeliveryOption === 4}/>
                                </div>
                            </label>
                        </div>
                 
                     <div className="process-btn process-btn-width" onClick={getShippingFee}>
                       <button>
                       {shipment_price_loading ? <div className="fetch-shipment"><div style={{width: "1rem !important", height: "1rem !important"}}
                      className="spinner-border shipment-spinner" role="status"
                    ></div> <span>Fetching shipment price</span></div> : "Proceed to Payment"}
                       </button>
                    </div>
                    {locationError && errorLocation &&
                      <div id="error-location">
                        <div className="text-center error-location-text" style={{fontSize: "12px"}}>{locationError}</div>
                        <div className="back-navigation"onClick={() => {handleMobileMenuSwitch(3); setLocationError(""); setErrorLocation(false)}}>
                            <button className="back-btn-navigate-address-one" >Enter Alternative Address</button>
                        </div>
                        <div className="back-navigation"   onClick={() => {handleMobileMenuSwitch(9); setLocationError(""); setErrorLocation(false)}}>
                            <button className="back-btn-navigate-address-two" >Use GIGL pickup centres</button>
                      </div>
                    
                </div>
                }
                </div>
                {/* <AppDownloadSection /> */}
        </div>
    )
}

export default DoorDeliveryMobile;