import { useContext, useEffect, useState } from 'react';
import {Link, useNavigate, useNavigation} from 'react-router-dom';
import classes from './Signup.module.css';
import registerImg from '../../assets/signup-image.png';
import { useSignupMutation, useUserLoginMutation } from '../../store/apiSlice';
import { getUrl } from '../../helpers/getUrl';
import { AppContext } from '../../AppContexts/AppContext';

const Signup = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [cPassword, setCpassword] = useState('')

    const navigate = useNavigate();
    const navigation = useNavigation()

    const [userSignup, {data, isLoading, isError, error, isSuccess}]= useSignupMutation();
    const [userSignIn, {data: loginData, isSuccess: loginSuccess}] = useUserLoginMutation()

    const {recentUrl} = useContext(AppContext)

    const registerUser = async(event) => {
        event.preventDefault()
        await userSignup({
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            phone: phoneNumber
        })
    }

    const login = async()=>{
        await userSignIn({
            email,
            password,
            user_type: "user"
        })
    }

    useEffect(()=>{
        if(!isError && isSuccess){
            login()
        }
    }, [isError, isSuccess])

    useEffect(()=>{
        if(loginSuccess){
                localStorage.setItem('token', JSON.stringify(loginData?.results?.token))
                localStorage.setItem('cred', JSON.stringify(loginData?.results?.user))
                if(recentUrl === "cart"){
                    navigate('/cart')
                } else{
                    navigate('/')
                }
            } 
    }, [loginSuccess])
    console.log(loginSuccess, loginData);
    return (
        <div className={classes.container}>
            <form className={classes["register-form"]} onSubmit={registerUser}>
                <div className={classes["email-field"]}>
                    <label htmlFor="Fname">First Name</label><br/>
                    <input className={classes["input-box"]} name="text" type="text" id="Fname" placeholder="First Name" value={firstName} required onChange={e=> setFirstName(e.target.value)}/><br/>
                </div>
                <div className={classes["email-field"]}>
                    <label htmlFor="Lname">Last Name</label><br/>
                    <input className={classes["input-box"]} name="text" type="text" id="Lname" placeholder="Last Name" value={lastName} required onChange={e=> setLastName(e.target.value)}/><br/>
                </div>
                <div className={classes["email-field"]}>
                    <label htmlFor="email">Email Address</label><br/>
                    <input className={classes["input-box"]} name="email" type="email" id="email" placeholder="Email Address" value={email} required onChange={e=> setEmail(e.target.value)}/><br/>
                </div>
                <div className={classes["email-field"]}>
                    <label htmlFor="phone-number">Phone number</label><br/>
                    <input className={classes["input-box"]} name="number" type="tel" id="phone-number" placeholder="Phone Number" value={phoneNumber} required onChange={e=> setPhoneNumber(e.target.value)}/><br/>
                </div>
                <div className={classes["password-field"]}>
                    <label htmlFor="password">Password</label><br/>
                    <input className={classes["input-box"]} name="password" type="password" id="password" placeholder="Password" value={password} required onChange={e=> setPassword(e.target.value)}/><br/>
                </div>
                <div className={classes["password-field"]}>
                    <label htmlFor="Cpassword">Confirm Password</label><br/>
                    <input className={classes["input-box"]} name="Cpassword" type="password" id="Cpassword" placeholder="Confirm Password" value={cPassword} required onChange={e=> setCpassword(e.target.value)}/><br/>
                </div>
                <button type="submit" disabled={isLoading}>{isLoading? 'SUBMITTING...': 'SUBMIT'}</button>
                <div className={classes["form-bottom"]}>
                <p>Already have an account? <Link to="/login">Login</Link></p>
                </div>
            </form>
            <div className={classes['sign-img']}>
                <img src={registerImg} alt="signup"/>
            </div>
        </div>
    );
}
 
export default Signup;