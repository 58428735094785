// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar-container{
    width: 100%;
}

.search-bar{
    position: relative;
    width: 100%;
}

.search-bar input{
    width: 100%;
    height: 1.5rem;
    border-radius: 1rem;
    border: none;
    padding-left: 1.5rem;
    outline: none;
    box-sizing: border-box;
    font-size: 0.75rem;
}

@media screen and (min-width: 480px) {
    .search-bar input{
        height: 2rem;
        padding-left: 2rem;
        font-size: 1rem;
    }
}

@media screen and (min-width: 600px) {
    .search-bar input{
        height: 2.2rem;
        padding-left: 2.5rem;
        font-size: 1rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/shared/Search.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,cAAc;IACd,mBAAmB;IACnB,YAAY;IACZ,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI;QACI,YAAY;QACZ,kBAAkB;QAClB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,cAAc;QACd,oBAAoB;QACpB,eAAe;IACnB;AACJ","sourcesContent":[".search-bar-container{\n    width: 100%;\n}\n\n.search-bar{\n    position: relative;\n    width: 100%;\n}\n\n.search-bar input{\n    width: 100%;\n    height: 1.5rem;\n    border-radius: 1rem;\n    border: none;\n    padding-left: 1.5rem;\n    outline: none;\n    box-sizing: border-box;\n    font-size: 0.75rem;\n}\n\n@media screen and (min-width: 480px) {\n    .search-bar input{\n        height: 2rem;\n        padding-left: 2rem;\n        font-size: 1rem;\n    }\n}\n\n@media screen and (min-width: 600px) {\n    .search-bar input{\n        height: 2.2rem;\n        padding-left: 2.5rem;\n        font-size: 1rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
