import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom';
import './App.css';
import Layout from './Components/UI/Layout';
import Login from './pages/vaidationPage/Login';
import Signup from './pages/vaidationPage/Signup';
import HomePage from './pages/HomePage/HomePage';
import ResetPassword from './pages/vaidationPage/ResetPassword';
// import AccountActivation from './pages/vaidationPage/AccountActivation';
import Cart from './pages/Cart/Cart';
import ResetPasswordOtp from './pages/vaidationPage/ResetPasswordOtp';
import NewPassword from './pages/vaidationPage/NewPassword';
import ErrorPage from './pages/HomePage/ErrorPage';
import CategoriesPage from './pages/Category/Categories';
import ProductDetails from './pages/Product/ProductDetails';
import CategoryDetails from './pages/Category/CategoryDetails';
import Stores from './pages/Store/Store';
import StoreDetail from './pages/Store/StoreDetail';
import { useDispatch } from "react-redux";
import { DisplayContext } from "./AppContexts/DisplayContext";
import { clearPaymentAndOrder } from "./Actions/CreateOrder";
import Loader from './utility/Loader';
import Error from './helpers/Error';
import { useContext } from 'react';

const routeDefinitions = createRoutesFromElements(
  <Route>
    <Route path='/' element={<Layout/>}>
      <Route index element={<HomePage/>}/>
      <Route path='home' element={<Navigate replace to='/'/>}/>
      <Route path='cart' element={<Cart/>}/>
      <Route path='categories' element={<CategoriesPage/>}/>
      <Route path='category/:categoryName' element={<CategoryDetails/>}/>
      <Route path=':id' element={<ProductDetails/>}/>
      <Route path='/stores' element={<Stores/>}/>
      <Route path="stores/:slug" element={<StoreDetail/>} />
      <Route path='*' element={<ErrorPage/>}/>
    </Route>
    <Route path='/login' element={<Login/>}/>
    <Route path='/signup' element={<Signup/>}/>
    <Route path='/reset' element={<ResetPassword/>}/>
    {/* <Route path='/account-activation' element={<AccountActivation/>}/> */}
    <Route path='/reset-otp' element={<ResetPasswordOtp/>}/>
    <Route path='/new-password' element={<NewPassword/>}/>
  </Route>
)

const routes = createBrowserRouter(routeDefinitions)

function App() {
  const dispatch = useDispatch()
  const {showDeliveryOption, closeModal, showMobileDeliveryOption, handleMobileMenuSwitch} = useContext(DisplayContext);
  return (
    <>
      <RouterProvider router={routes}/>
      {
            showMobileDeliveryOption === 6 || showMobileDeliveryOption === 7 ? null : <div className={showDeliveryOption === 1 ? "overlay" : "hide-overlay"} onClick={closeModal}></div>  
            
      }
      {
              showMobileDeliveryOption === 6 || showMobileDeliveryOption === 7 ? null : <div className={showDeliveryOption === 2 ? "overlay" : "hide-overlay"} onClick={closeModal}></div> 
      }
        {
              showMobileDeliveryOption === 6 || showMobileDeliveryOption === 7 ? null : <div className={showDeliveryOption === 3 ? "overlay" : "hide-overlay"} onClick={closeModal}></div> 
      }
      {
              showMobileDeliveryOption === 6 || showMobileDeliveryOption === 7 ? null : <div className={showDeliveryOption === 4 ? "overlay" : "hide-overlay"} onClick={closeModal}></div> 
      }
      {
            showMobileDeliveryOption === 6 || showMobileDeliveryOption === 7 ? null :  <div className={showDeliveryOption === 5 ? "overlay" : "hide-overlay"} onClick={closeModal}></div> 
      }
        {
            showMobileDeliveryOption === 6 || showMobileDeliveryOption === 7 ? null :  <div className={showDeliveryOption === 6 ? "overlay" : "hide-overlay"} onClick={closeModal}></div>
      }
      <div className={showDeliveryOption === 7 ? "overlay" : "hide-overlay"} onClick={() => {closeModal(); dispatch(clearPaymentAndOrder())}}></div> 
      
        <div className={showDeliveryOption === 8 ? "overlay" : "hide-overlay"} onClick={()=> {closeModal(); dispatch(clearPaymentAndOrder())}}></div> 
      
        {
            showMobileDeliveryOption === 6 || showMobileDeliveryOption === 7 ? null : <div className={showDeliveryOption === 9 ? "overlay" : "hide-overlay"} onClick={closeModal}></div> 
      }
      {
            showMobileDeliveryOption === 6 || showMobileDeliveryOption === 7 ? null :  <div className={showDeliveryOption === 10 ? "overlay" : "hide-overlay"} onClick={closeModal}></div> 
      }
      
      {
            showMobileDeliveryOption === 6 || showMobileDeliveryOption === 7 ? null :  <div className={showMobileDeliveryOption === 1   ? "mobile-overlay" : "hide-overlay"} onClick={() => handleMobileMenuSwitch(0)}></div> 
      }
    </>
  );
}


export default App;
