import React, {useState, useRef, useEffect} from 'react';
import Alphalogo from './Images/Alpha-logo.png';
import Userprofile from './Images/user-profile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import classes from './Navbar.module.css';
import Hamburger from 'hamburger-react';
import SearchBar from './Search';
import Categories from '../../pages/HomePage/Categories';
import ValidationModal from '../UI/ValidationModal';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import SearchLarge from './SearchLarge';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthToken, getUserCred } from '../../utility/utility';
import { useGetCartItemsQuery } from '../../store/apiSlice';
import {getCartId} from '../../utility/utility';
    
const Navbar = () => {
    const [openHamburger, setOpenHamburger]= useState(false);
    const [searchDisplay, setSearchDisplay] = useState(false)
    const [valModal, setValModal] = useState(false)
    const closeMenu = useRef(null)
    // const isAuthenticated = useSelector(state=> state.auth.isAuthenticated)
    const cartId = getCartId() 
    const token = getAuthToken() && JSON.parse(getAuthToken())
    const {data, error, isSuccess} = useGetCartItemsQuery({cartId})

    const results = data && data?.results
    const cartItems = results && results?.cart?.items
    console.log("Layout" ,data?.results);

    useEffect(()=>{
        if(data && !error && isSuccess){
            console.log(data, totalQuantity);
        }
    },[data, error, isSuccess])
    let totalQuantity = 0
    for(let i=0; i < cartItems?.length; i++){
        let itemqty = cartItems[i].quantity
        totalQuantity += itemqty
    }


    const userCred = getUserCred() && JSON.parse(getUserCred())
    const firstName = userCred?.first_name
    console.log(userCred, firstName);

    const onHamburgerHandler=()=>{
        setOpenHamburger(prev=>!prev)
    }

    const onSearchDisplay=()=>{
        setSearchDisplay(prev=>!prev)
    }

    const onDisplayValModal=()=>{
        setValModal(prev=>!prev)
    }

    const closeOpenMenu = (e) => {
        if(closeMenu.current && valModal && !closeMenu.current.contains(e.target)){
            setValModal(false)
          }
    }

    document.addEventListener('mousedown',closeOpenMenu)

    return(
        <div className={classes['nav-bar']}>
            <div className={classes['nav-container']}>
                <div className={classes['nav-container_top']}>
                    <div className={classes['nav-container_left']}>
                        <div onClick={onHamburgerHandler} className={classes['nav-hamburger']}><Hamburger size={20}/></div>
                        <div><Link to="/"><img src={Alphalogo} alt=""/></Link></div>
                    </div>
                    {openHamburger && <div className={classes['nav-cat']}><Categories/></div>}
                    <div className={classes['search-large']}><SearchLarge/></div>
                    <div className={classes['nav-container_right']}>
                        <div className={classes['search-btn']}><FontAwesomeIcon icon={faMagnifyingGlass} className={classes['search-img']} onClick={onSearchDisplay} /></div>
                        <div className={classes['user-profile']}><img src={Userprofile} alt=""/></div>
                        <div className={classes['small-profile']}><Link to="/login"><img src={Userprofile} alt=""/></Link></div>
                        <div className={classes['login']}>
                            {token && token? <div>Hi, {firstName}</div>: <div>My Profile</div>}
                            <div onClick={onDisplayValModal}>{!valModal? <IoMdArrowDropdown/>: <IoMdArrowDropup/>}</div> 
                        </div>
                        <div className={classes['cart-cover']}>
                            <Link to="/cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "white"}}/></Link>
                            <div>{totalQuantity}</div>
                        </div>
                        <Link className={classes['nav-btn']} to="/categories">Shop Now</Link>
                    </div>
                    {valModal && <div ref={closeMenu} className={classes['validation']}><ValidationModal/></div>}
                </div>
                {searchDisplay && <div className={classes['search']}><SearchBar/></div>}
            </div>
        </div>
    )
}

export default Navbar;