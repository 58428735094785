import { useState, useEffect, useContext} from "react";
import {FaTimes, FaArrowLeft} from "react-icons/fa"
import { useSelector, useDispatch } from 'react-redux';
import { usePaystackPayment } from 'react-paystack';
import { InitiateTransaction } from "../../Actions/InitiateTransaction";
import { VerifyOrder } from './../../Actions/VerifyOrder';
import { clearPaymentAndOrder, CreateOrder } from './../../Actions/CreateOrder';
import { DisplayContext } from "../../AppContexts/DisplayContext";
import { AppContext } from "../../AppContexts/AppContext";
import { getUrl } from "../../helpers/getUrl";
import { getUserCred } from "../../utility/utility";
 
const PaystackPaymentModal = ({showDeliveryOption, closeModal, totalPrice, item, email, phone, handleMenuSwitch, price, qty,  address, senderLat,senderLong, senderName, senderPhoneNumber, senderStationId, senderAddress, itemWeight, itemName, receiverCoordinate, senderLocality, size, color, prodImage, prodId, setShowDeliveryOption,product, channelName,vehicleType,id}) => {
  const {userId,fullName,recieverLGA,recieverCity,stationId,handleCheck, check} = useContext(DisplayContext);
  const {numberSeperator} = useContext(AppContext)
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [trxResult, setTrxResult] = useState("")
  const {payment_method_loading, payment_method_error, payment_method} = useSelector(state => state.PaymentMethodReducer);
  const {  shipment_price_error, shipment_price} = useSelector(state => state.ShipmentPriceReducer)
  const shipmentPrice = shipment_price && shipment_price.results && shipment_price.results.Object;
  const {initiate_loading, transaction_initiate,initiate_success} = useSelector(state => state.InitiateTransactionReducer);
  const {  verify_order_loading,verify_order_success,verify_order_error} = useSelector(state => state.VerifyOrderReducer);
  const {create_order_error,create_order_success,create_order_loading} = useSelector(state => state.CreateOrderReducer)
  const shippingCost = shipmentPrice && Math.ceil(shipmentPrice.GrandTotal);
  const mainDeliveryFee = shipmentPrice && shipmentPrice.Merchants && shipmentPrice.Merchants[0] && Math.ceil(shipmentPrice.Merchants[0].DeliveryPrice)
  const totalAmount = (price * qty) + shippingCost;
//  const storeId = product && product.results && product.results.store && product.results.store.storeId;
  const trnxRef = transaction_initiate && transaction_initiate.results && transaction_initiate.results.trxnRef; 
  const buyerId = getUserCred() && JSON.parse(getUserCred())._id

  const deliveryCost = shipmentPrice?.Merchants?.map(({GrandTotal}) => {
    return GrandTotal
  });

  console.log(shippingCost, shipmentPrice);

  const dispatch = useDispatch();
  const getTrxId = paymentMethod.name === "flutterwave" ? trxResult.tx && trxResult.tx.id : "";
  const getTrxRef = paymentMethod.name === "flutterwave" ? trxResult.tx && trxResult.tx.txRef : trxResult && trxResult.reference;
  const dataToSubmit = {
  "IsHomeDelivery": true,
  "src_channel": channelName || "",
  "device_platform" : "web_app",
  "Merchants": item?.map((items, index)=> {
    return{
      "user_id": items?.merchant_id,
      "store_id": items?.store_id,
      "ShippingFee": deliveryCost && deliveryCost[index],
      "DestinationStationId": stationId,
      "LGA": recieverLGA,
      "SenderCity": items?.locality,
      "ReceiverCity": recieverCity,
      "CustomerCode": getUrl("ACC001164", "IND265936"),
      "PaymentType": "Cash",
      "CashOnDeliveryAmount": 0,
      "IsCashOnDelivery": false,
      "PreShipmentItems": [
        {
          "buyer_id": buyerId,
          "ItemName": items?.productName,
          "ItemValue": items?.price,
          "ItemType": "Normal",
          "product_id": items?.productId,
          "Quantity": items?.quantity,
          "ShipmentType": "Regular",
          "size": items?.size.size || "",
          "SpecialPackageId": "0",
          "Value": items?.price * items?.quantity,
          "product_image": items?.front_view,
          "Weight": items?.weight,
          "WeightRange": "0",
          "color": color || ""
        }
      ],
      "SenderAddress": items?.product_address,
      "SenderEmail": items?.merchant_email,
      "SenderLocality": items?.locality,
      "SenderLocation": {
          "Latitude": items?.lat?.toString(), 
          "Longitude": items?.long?.toString()
      },
      "SenderName": items?.merchant_first_name + " " + items?.merchant_last_name,
      "SenderPhoneNumber": items?.merchant_phone_number,
      "SenderStationId": +items?.merchant_station_id
    }
  }),
  "PaymentType": "Cash", 
  "PickupOptions": "HOMEDELIVERY",
  "ReceiverAddress": address,
  "ReceiverEmail": email,
  "ReceiverLocation": {
      "Latitude": receiverCoordinate?.lat && receiverCoordinate.lat.toString(), 
      "Longitude": receiverCoordinate?.lng && receiverCoordinate.lng.toString()
  },
  "ReceiverName": fullName,
  "ReceiverPhoneNumber": phone,
  "ReceiverStationId": +stationId,
  // "DepartureStationId" : +senderStationId,
  "totalCost": +totalPrice + shippingCost,
  "trxnRef": trnxRef && trnxRef,
  "VehicleType": vehicleType?.toUpperCase() || "BIKE"
}

console.log(dataToSubmit);
    
    const paystackConfig = {
        reference: transaction_initiate && transaction_initiate.results && transaction_initiate.results.trxnRef,
        email: email,
        amount: Math.ceil(totalAmount * 100),
        publicKey: process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PAYSTACK_PUBKEY_LIVE : process.env.REACT_APP_PAYSTACK_PUBKEY,
        // channels: ['card']
    };
    const createNewOrder = (payment) => {
        setPaymentMethod(payment) 
    } 
    // you can call this function anything
    const onPaystackSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      setTrxResult(reference)
    };

  
    // you can call this function anything
    const onPaystackClose = () => {
      //  dispatch(Product(id))
       dispatch(clearPaymentAndOrder())
      handleMenuSwitch(0);
      setPaymentMethod("")

    }

    const initializePaystackPayment =  usePaystackPayment(paystackConfig);
   
    const allPaymentMethod = payment_method && payment_method.results;

const handlePaystackPayment = () => {
  if(verify_order_success && create_order_success){
    return
}else {
  dispatch(CreateOrder(dataToSubmit)); 
}

}
useEffect(() => {
  if(!verify_order_success && create_order_success &&  check === "paystack-home-desktop"){
    initializePaystackPayment(onPaystackSuccess, onPaystackClose)
}
}, [verify_order_success,create_order_success, check])
   
    useEffect(() => {
        if(getTrxRef && paymentMethod.name === "Paystack"){
            dispatch(VerifyOrder( {
              "trxnRef": trnxRef && trnxRef,
              "id": getTrxId, 
              "totalCost": +totalAmount
            }))
          
        }
      
    }, [getTrxRef, paymentMethod.name, trnxRef, totalAmount, getTrxId]);

  
    return (
        <>
        {verify_order_success && initiate_success ? setShowDeliveryOption(7) : verify_order_error ? setShowDeliveryOption(8) : null}
         <div className={showDeliveryOption === 6 ? "modals-container-payment" : "modals-container-hide"}>
            <div className="card card-modal-payment">
                     {
                        initiate_loading === false && create_order_error &&
                            <div className="container alert alert-danger" role="alert">
                                {create_order_error}
                            </div>
                      }
              
                      {
                        verify_order_loading ?  <div className="loader-page">
                              <div 
                                className="spinner-border shipment-spinner text-center"
                                role="status"
                              ></div>
                              <p className= "text-center text-dark" >
                                  Verifying your order... 
                              </p></div> : <>
                          <div className="card-header card__header"><span onClick={() => {handleMenuSwitch(4);}}><FaArrowLeft /></span><span className="checkout-header text-uppercase">payment</span><span className="close-modal"><FaTimes  onClick={closeModal}/></span></div>
                          <div className="card-body card__body__payment">
                              <p className="card__title p-3">
                                  Choose payment method
                              </p>
                                    {
                                          payment_method_error &&
                                              <div className="container alert alert-danger" role="alert">
                                                  {payment_method_error}
                                              </div>
                                      }
                                      {
                                          shipment_price_error &&
                                              <div className="container alert alert-danger" role="alert">
                                                  {shipment_price_error}
                                              </div>
                                      }
                                      {payment_method_loading ? <p className="text-center">Loading payment gateways...</p> :
                                      <>
                                  <div className="payment__type" >
                                      {allPaymentMethod && allPaymentMethod.map(payment => (
                                        <label  key={payment._id}  className={payment.name === "Paystack" ? "payment_section": ""}>
                                          {payment.name === "Paystack" && <img src={payment.image_url} alt={payment.name}/>}
                                          
                                          {payment.name === "Paystack" && <p className="paystack-text">{payment.name}</p>}
                                          {payment.name === "Cash on Delivery" && <p className="paystack-text">{payment.name}</p>}
                                          <input type="radio" name="payment" className={payment.name === "GIGGO Wallet" ? "d-none" : payment.name === "Paystack" ? "paystack_input_desktop_home" : payment.name==="flutterwave" ? "flutterwave_input_desktop d-none" : payment.name === "Cash on Delivery" ? "cod_input_desktop" : ""} onClick={() => {createNewOrder(payment); } } onChange={(e) => {dispatch(InitiateTransaction({amount: totalAmount, payment_gateway: payment._id,  payment_title: `Purchase of ${itemName}`})); handleCheck(e)}} id={payment.name === "Paystack" ? "paystack__radio" : payment.name==="flutterwave" ? "flutterwave__radio d-none" : payment.name === "Cash on Delivery" ? "cod_radio d-none" : ""} value="paystack-home-desktop"/>
                                          <div className={payment.name === "Paystack" ? "radio__paystack" : payment.name === "Cash on Delivery"? "radio__paystack" : ""}></div> 
                                        </label>
                                      ))}
                               
                                  </div>            
                              <div>
                                
                                  <hr className="hr-color payment-hr mt-3"/>
                                  <div className= "card__info">
                                      <div className="items">
                                          <p className="item-key">Product Price</p>
                                          <p className="item-value">NGN {numberSeperator(price)}</p>
                                      </div>
                                      <div className="items">
                                          <p className="item-key">Quantity</p>
                                          <p className="item-value">{qty}</p>
                                      </div>
                                    
                                      <div className="items">
                                          <p className="item-key">Delivery Fee</p>
                                          {shippingCost !== mainDeliveryFee ? <div className="delivery-fee-values"><p className="item-value main-delivery-price">&#8358;{numberSeperator(mainDeliveryFee)}</p><p className="item-value">&#8358;{numberSeperator(shippingCost)}</p></div> : <div><p className="item-value">&#8358;{numberSeperator(mainDeliveryFee)}</p></div> }
                                      </div>
                                      <div className="items">
                                          <p className="item-key total-key">Total</p>
                                          {totalAmount && <p className="item-value total-value">&#8358; {numberSeperator(totalAmount)}</p>}  
                                      </div> 
                                  </div>
                                  {/* <div>
                                    {paymentMethod.length === 0 && <div className= {paymentMethod.length === 0 ? "payment__btn-homedelivery__disabled" : "payment-btn card__info"}>
                                      <button disabled={paymentMethod.length === 0}>Pay</button>   
                                    </div>}
                                  </div> */}
                                  

                                  <div>
                                   <div className= { paymentMethod.name !== "Paystack" || check !== "paystack-home-desktop" || trnxRef === ""  ? "payment__btn-homedelivery__disabled" : "payment-btn card__info"}>
                                     <button disabled={paymentMethod.name !== "Paystack"  || initiate_loading || check !== "paystack-home-desktop"} onClick={handlePaystackPayment}>{create_order_loading ? "Please wait..." : "Pay"}</button> 
                                  </div>
                                  </div>
                                   
                              </div>
                            </>
                            }
                          </div>
              
                          </>
                      }
            </div>
           
        </div>
        
        </>
    )
}

export default PaystackPaymentModal;