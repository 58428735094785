import React, {useContext, useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContexts/AppContext';
import "./categories.css";
import "./categorydetails.css";
import { useSelector, useDispatch } from 'react-redux';
import { AlphaSearchAction } from '../../Actions/AlphaSearchResult';
import { BsCaretLeftFill,BsSortUp,BsSortDown, BsCaretRightFill} from 'react-icons/bs';
import {CiFilter,CiSearch} from "react-icons/ci";
import Card from './Card';
import delivery from "../../assets/fast-delivery.png";
import customerCare from "../../assets/customer-care.png";
import payment from "../../assets/seamless-payment.png";
import best from "../../assets//best-price.png";

import MultiRangeSlider from "multi-range-slider-react";
import Error from '../../helpers/Error';
import Loader from '../../utility/Loader';


const CategoryDetails = () => {
  const [search, setSearch] = useState("");
  const [sortChanged,setSortChanged] = useState(false)
  const navigate = useNavigate()
  const [sort, setSort] = useState("A-Z");
  const [openSort, setOpenSort] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  
  const {search_results,search_results_docs, searchProductError,searchProductLoading} = useSelector(state => state.searchAlphaProducts);
  const dispatch = useDispatch()
  const {categoryName} = useParams();
  const {removeSlug,normalizeCase} = useContext(AppContext);
  const sortedData = (array, sort, desc) => {
      array?.sort(function(a,b){
          if(a[sort] < b[sort]) return -1;
          if(a[sort] > b[sort]) return 1;
          return 0
      })
      if(desc === "Z-A") array.reverse();
      return array
  };
  
  const prices = search_results_docs && search_results_docs?.map(({price}) => {
    return price
  });
  

  const numArray = prices && prices.sort( function( a , b){
    if(a > b) return 1;
    if(a < b) return -1;
    return 0;
});
  // const min = numArray && numArray !== undefined && numArray[0];
 
  const max =  1000000
  const [minVal, set_minValue] = useState(0);
  const [maxVal, set_maxValue] = useState(max);
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
    setSortChanged(true)
  };


//  ```` const search_results_docs = sortChanged === true ? search_results_docs?.filter((item) => item.price >= minVal && item.price <= maxVal) : search !== "" ? search_results_docs?.filter((item) => item?.productName?.toLowerCase().includes(search?.toLowerCase())) : search_results_docs````
  const alphaCode = categoryName && removeSlug(categoryName);

  const searchTerm = alphaCode && alphaCode

  const handleOpenSort = () => {
       setOpenSort(prev =>!prev);
       setOpenFilter(false)
  }
  const handleOpenFilter = () => {
    setOpenFilter(prev =>!prev);
    setOpenSort(false);
}

  useEffect(() => {
    const limit = 8
    const page = 1;
    const data = {alphaCode: searchTerm, page, limit }
    dispatch(AlphaSearchAction(data))
   
}, [dispatch, searchTerm]);
const handleNextPage = (page_number) => {
  const limit = 8
  const page = page_number;
  const data = {alphaCode:searchTerm, page, limit }
  dispatch(AlphaSearchAction(data))
};

const totalPages = search_results && search_results.totalPages;
const nextPage = search_results && search_results.nextPage;
const hasNextPage = search_results && search_results.hasNextPage;
const page = search_results && search_results.page;
const prevPage = search_results && search_results.prevPage;
const hasPrevPage = search_results && search_results.hasPrevPage;

  return (
    <div className='category__details-page'>
        <div className='category__details-bg'>
            
              <div className='category__details-container'>
                  <div className='back__to-page' onClick={() => navigate(-1)}><BsCaretLeftFill size={16}/>Back</div>
                   <div className='category__name-display'>
                       <div className='category-title' title={categoryName}>{normalizeCase(removeSlug(categoryName))}</div>
                       <div className='category-breadcrumb'>Categories/<span>{normalizeCase(removeSlug(categoryName))}</span></div>
                   </div>
              </div>
            
        </div>
        <div className='category__filter-section'>
              <div className='category__search-form'>
                   <form>
                       <CiSearch className='category__search-icon' size={16}/> 
                       <input type='text' placeholder='Search for products...'  className="category__details-search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                   </form>
              </div> 
              <div className='category__filter'>
                   <div className='filter' onClick={handleOpenFilter}><CiFilter size={18}/><span>Filter</span></div>
                   <div className='sort' onClick={handleOpenSort}>{sort === "Z-A" ? <BsSortDown size={18}/> : <BsSortUp size={18}/>}<span>Sort</span></div>
              </div>
              
        </div>
        <div className={openFilter ? 'category__detail-modal': 'd-none'}>
            <div className='sort__modal filter-price'>
                <h6 >Price range</h6>
                <div className='text-center minmax-sec'><span>&#8358;{minVal }</span> - <span>&#8358;{ maxVal }</span></div>
                <div className="price-input">
                  <div className='field-input'>
                   <div className="fields">
                   <MultiRangeSlider
                        min={50}
                        max={1000000}
                        step={5}
                        barInnerColor='rgb(25,33,80)'
                        minValue={minVal}
                        maxValue={maxVal}
                        onInput={(e) => {
                          handleInput(e);
                        }}
                  
                      />  
                   </div>
              
                   </div>
                   <div className='apply__and_clear-btn'>  
                      <button className='apply-filter' onClick={() => {handleOpenFilter(); set_maxValue(max); set_minValue(0)}}>Clear</button>    
                   </div>
                             
                </div>
                
            </div>
        </div>
        <div className={openSort ? 'category__detail-modal' : 'd-none'}>
            <div className='sort__modal sort-section'>
                 <div className='sort-div'>
                    
                        <div onClick={() => {setSort("A-Z"); handleOpenSort(); sortedData(search_results_docs,"productName", "A-Z")}} className={sort === "A-Z" ? "active-sorted" : ""}>
                            <span>A-Z</span>
                        </div>
                        <div onClick={() => {setSort("Z-A"); handleOpenSort(); sortedData(search_results_docs,"productName", "Z-A")}} className={sort === "Z-A" ? "active-sorted" : ""}>
                            <span>Z-A</span>
                        </div>
                 </div>
            </div>
        </div>
        <section className="delivery_info category_delivery">  
            <div className="services-desc"><img src={delivery} alt="fast-delivery"/><div className="service-rendered">Fast Delivery</div></div>
            <div className="services-desc"><img src={customerCare} alt="fast-delivery"/><div className="service-rendered">Customer Support</div></div>
            <div className="services-desc"><img src={payment} alt="fast-delivery"/><div className="service-rendered">Seamless payment</div></div>
            <div className="services-desc"><img src={best} alt="fast-delivery"/><div className="service-rendered">Best Prices</div></div>
        </section>
           {searchProductError && <Error customError={searchProductError} customErrorCode={searchProductError?.toLowerCase().includes("Failed to".toLowerCase()) ? "503" : searchProductError?.toLowerCase().includes("Not found".toLowerCase()) ? "404" : "400"}/>}
           {searchProductLoading && <Loader />}
           <section className="store_products category__product-cards">
                {
                   search_results_docs && search_results_docs.length > 0 &&   <div className="row mt-4">
                   
                    {search_results_docs && search_results_docs?.filter((item) => item?.productName?.toLowerCase().includes(search?.toLowerCase())).filter((item) => item.price >= minVal && item.price <= maxVal).map(product => (
                      
                        <Card product={product} key={product._id}/>
                    ))}
                   
                 </div>  
           
                }

                {
                  !searchProductError && !searchProductLoading && search_results_docs && search_results_docs.length < 1 && <div className='d-flex align-items-center justify-content-center mt-4' style={{fontWeight:700, fontSize: "18px", color: "#333333"}}>No product found!</div>
                }   
              
           
          
        </section>
       

        {
            totalPages > 1 && <div className='category__page-pagination'>
                <button className={hasPrevPage && "prev__category-btn"} disabled={!hasPrevPage} onClick={() => {handleNextPage(prevPage);setSortChanged(false); setSearch(""); set_maxValue(0)}}><BsCaretLeftFill /></button><button disabled>{page}</button><button disabled={!hasNextPage} className={hasNextPage && "next__category-btn"} onClick={() => {handleNextPage(nextPage); setSortChanged(false); setSearch(""); set_minValue(0)}}><BsCaretRightFill /></button>
            </div>
        }
       
     
        
    </div>
  )
}

export default CategoryDetails