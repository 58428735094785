import React, { useContext } from 'react';
import { AppContext } from '../../AppContexts/AppContext';
import placeholder from "../../assets/placeholder-images/default-placeholder.png"
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "./Card.module.css"

const Card = ({product}) => {
    const {numberSeperator} = useContext(AppContext);
    const navigate = useNavigate()
  return (
    <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col col-height product__store-cards" > 
                                        
        <Link to={`/product/guest/${product._id}`} className="store_link_alpha">      
            <div className="product_content">
                    <div className="shop_img">
                        <img loading="lazy" src={product.front_view || placeholder} className="product_shop_img" alt="product img"/>
                    </div> 
                    <p title={product.productName}>{product.productName}</p>
                    <span className='mt-2'>&#x20A6; {numberSeperator(product.price)}</span>
            
            </div>
            
        </Link>
        <button className='d-block add__to-cartBtn' onClick={() => navigate(`/product/guest/${product._id}`)}>View details</button>
        {/* <button className='d-block add__to-cartBtn'>Add to cart</button> */}
    </div>
  )
}

export default Card