import { createContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HomeDelivery } from '../Actions/HomeDelivery';
import { localStation } from '../Actions/LocationStations';
import { PaymentMethod } from '../Actions/PaymentMethod';
import { checkValidation, patterns } from '../utility/Validations';

export const DisplayContext = createContext(null);

const DisplayContextProvider = ({children}) => {
     const [showDeliveryOption, setShowDeliveryOption] = useState(0)
     const [showMobileDeliveryOption, setShowMobileDeliveryOption] = useState(0)
     const [check, setCheck] = useState("");
     const [fullName, setFullName] = useState("");
     const [email, setEmail] = useState("");
     const [phone, setPhone] = useState("");
     const [address, setAddress] = useState("");
     const [state, setState] = useState("");
     const [qty, setQty] = useState(0);
     const [color, setColor] = useState("");
     const [size, setSize] = useState("");
     const [stationId, setStationId] = useState("");
     const [styleSize, setStyleSize] = useState(null);
     const [styleColor, setStyleColor] = useState(null)
     const [customerCode, setCustomerCode] = useState("");
     const [senderLat, setSenderLat] = useState("");
     const [senderLong, setSenderLong] = useState("");
     const [senderName, setSenderName] = useState("")
     const [senderPhoneNumber, setSenderPhoneNumber] = useState("");
     const [senderStationId, setSenderStationId] = useState("");
     const [senderLocality, setSenderLocality] = useState("");
     const [senderNeighborhood, setSenderNeighborhood] = useState("");
     const [recieverLGA, setRecieverLGA] = useState("");
     const [recieverCity, setRecieverCity] = useState("");
     const [destinationServiceCenterId, setDestinationServiceCenterId] = useState(null)
     const [itemName, setItemName] = useState("");
     const [itemPrice, setItemPrice] = useState("");
     const [senderAddress, setSenderAddress] = useState("")
     const [itemWeight, setItemWeight] = useState("");
     const [receiverCoordinate, setReceiverCoordinate] = useState({lat: null, lng: null});
     const [locationValues, setLocationValues] = useState([]);
     const [productImg, setProductImg] = useState("");
     const [productId, setProductId] = useState("");
     const [itemColor, setItemColor] = useState("");
     const [verifyOrder, setVerifyOrder] = useState("");
     const [initialQty, setInitialQty] = useState(0)
     const [buyerId, setBuyerId] = useState("");
     const [userId, setUserId] = useState("");
     const [prodQuantity, setProdQuantity] = useState(0)
     const [toggle, setToggle] = useState(false);
     const [search, setSearch] = useState("");
     const [verifyCodeService, setVerifyCodeService] = useState(false);
     const [initiatePaystackService, setInitiatePaystackService] = useState(false)
     const closeModal = () => {
         setShowDeliveryOption(0);
         setShowMobileDeliveryOption(0)
    }
 
   
 
    const handleMenuSwitch = (index) => {
         setShowDeliveryOption(index)
    }
    const handleMobileMenuSwitch = (index) => {
       setShowMobileDeliveryOption(index)
    }
    const handleCheck = (e) => {
        setCheck(e.target.value)
    }
   const emailValidation = checkValidation(patterns["email"], email);
   const phoneValidation = checkValidation(patterns["telephone"], phone)
    const changeDoorDeliveryOption = () => {
          if(check === "door-delivery-modal"){
               setShowDeliveryOption(2)
          }
          else if(check === "pick-up-modal"){
               setShowDeliveryOption(3)
          }   
    }

    const changeDeliveryOptions = () => {
           if(check === "pickup-station-mobile"){
               setShowMobileDeliveryOption(8)
          }else {
               setShowMobileDeliveryOption(3)
          }
     }

     const clearHistory = () => {
          setCheck("")
          setFullName("");
          setAddress("")
          setBuyerId("");
          setColor("");
          setEmail("");
          setInitialQty(0);
          setQty(1);
          setItemColor("");
          setItemPrice("")
          setItemName("");
          setItemWeight("");
          setCustomerCode("");
          setLocationValues("");
          setPhone("");
          setProdQuantity("");
          setProductId("");
          setProductImg("");
          setReceiverCoordinate("");
          setSenderAddress("");
          setSenderLat("");
          setSenderLocality("");
          setSenderLong("");
          setSenderName("");
          setSenderNeighborhood("");
          setSenderPhoneNumber("");
          setSenderStationId("")
     }

    const {home_delivery_loading, home_delivery, home_delivery_error} = useSelector(state => state.GetHomeDeliveryReducer);
    const {local_station_error, local_station, local_station_loading} = useSelector(state => state.LocalStationReducer);

    const dispatch = useDispatch()

    useEffect(() => {
       dispatch(HomeDelivery())
    }, [dispatch]);
    useEffect(() => {
         dispatch(localStation())
    }, [dispatch]);
    useEffect(() => {
       dispatch(PaymentMethod())
    }, [dispatch])


   const {Provider} = DisplayContext;

     return <Provider value={{showDeliveryOption, setShowDeliveryOption, check, setCheck, fullName, setFullName,email, setEmail, phone, setPhone, address, setAddress, state, setState, qty, setQty, color, setColor, size, setSize, stationId, setStationId, styleSize, setStyleSize, styleColor, setStyleColor, customerCode, setCustomerCode, senderLat, setSenderLat, senderLong, setSenderLong, senderPhoneNumber, setSenderPhoneNumber, senderName, setSenderName, senderStationId, setSenderStationId, senderLocality, setSenderLocality, senderNeighborhood, setSenderNeighborhood, itemName, setItemName, itemPrice, setItemPrice, senderAddress, setSenderAddress, itemWeight, setItemWeight, receiverCoordinate, setReceiverCoordinate, locationValues, setLocationValues, productImg, setProductImg, productId, setProductId, itemColor, setItemColor, verifyOrder, setVerifyOrder, closeModal, handleCheck, handleMenuSwitch, changeDoorDeliveryOption, home_delivery_loading, home_delivery, home_delivery_error, local_station_error, local_station, local_station_loading, toggle, setToggle, changeDeliveryOptions, showMobileDeliveryOption, setShowMobileDeliveryOption, handleMobileMenuSwitch, emailValidation, phoneValidation, userId, setUserId, initialQty, setInitialQty, buyerId, setBuyerId, prodQuantity, setProdQuantity, clearHistory, search, setSearch,recieverLGA, setRecieverLGA,setRecieverCity,recieverCity,verifyCodeService, setVerifyCodeService,initiatePaystackService, setInitiatePaystackService,destinationServiceCenterId, setDestinationServiceCenterId}}>
           {children}
     </Provider>
}


export default DisplayContextProvider