import React, {useContext, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ListCategories } from '../../Actions/Category';
import classes from "./Categories.module.css"
import Error from '../../helpers/Error';
import { Link } from 'react-router-dom';
import Loader from '../../utility/Loader';
import { AppContext } from '../../AppContexts/AppContext';
const CategoriesPage = () => {
  const dispatch = useDispatch(null)
  const {category_error, list_categories,category_loading} = useSelector(state => state.allCategories);
  const {slugify} = useContext(AppContext)
  useEffect(() => {
       dispatch(ListCategories())
  }, [dispatch])
  return (
    <div className={classes['list__categories-page']}>
         <div className={classes['list__category-banner']}>
              <div className={classes['list__categories_banner-container']}>
                  <div className={classes['category__placeholder-sect']}><span>Alpha shops</span>/categories</div>
              </div>
         </div>
         <div className={classes['categories__card-list overflow-hidden']}>
               {
                   category_error && <Error customError={category_error || category_error.message} customErrorCode="Error"/>
               }
               {
                 list_categories && list_categories.length > 0 && !category_loading ? <div className={classes['category__cards-grid']}>
                      {
                          list_categories && list_categories.map(({_id, name, image_url}) => (
                            
                              <Link to={`/category/${slugify(name)}`} key={_id} style={{
                                        backgroundImage: `url(${image_url})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '140px',
                                        borderRadius: "8px"
                                 }}>
                                 
                                     <span>{name}</span>
                                 
                              </Link>
                            
                          ))
                      }
                  
                 </div>: !category_error && <>
                    <Loader />
                 </>
               }
               
         </div>
    </div>
  )
}

export default CategoriesPage