// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecentlyViewed_head__hZ302{
    padding-left: 0.75rem;
    border-left: 3px solid #C2D068;
    margin-bottom: 1.625rem;
}

.RecentlyViewed_items__SePQb{
    display: grid;
    grid-template-columns: 23.41% 23.41% 23.41% 23.41%;
    grid-gap: 2.12%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Cart/RecentlyViewed.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,kDAAkD;IAClD,eAAe;AACnB","sourcesContent":[".head{\n    padding-left: 0.75rem;\n    border-left: 3px solid #C2D068;\n    margin-bottom: 1.625rem;\n}\n\n.items{\n    display: grid;\n    grid-template-columns: 23.41% 23.41% 23.41% 23.41%;\n    grid-gap: 2.12%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `RecentlyViewed_head__hZ302`,
	"items": `RecentlyViewed_items__SePQb`
};
export default ___CSS_LOADER_EXPORT___;
