import { CLEAR_INITIATE_TRANSACTION, CREATE_ORDER_FAIL, CREATE_ORDER_REQUEST, CREATE_ORDER_SUCCESS } from "../constants/constants";
import { getUrl } from './../helpers/getUrl';
import { CLEAR_CREATE_ORDER, CLEAR_VERIFY_ORDER } from './../constants/constants';
import { getAuthToken } from "../utility/utility";

export function CreateOrder(dataToSubmit){
   const token = getAuthToken() && JSON.parse(getAuthToken())

   return async (dispatch) => {
    dispatch({type: CREATE_ORDER_REQUEST});
    try {
       const res = await fetch(`${getUrl("https://order.thealphashops.com/api/v1","https://orderservice.click/api/v1")}/order/new`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            ACCEPT: "application/json",
            "Authorization" : "Bearer " + token
         },
         body: JSON.stringify(dataToSubmit)
       })
        
       const data = await res.json();
       if(data.error){
           return dispatch({type: CREATE_ORDER_FAIL, payload: data.message})
       }
       dispatch({type: CREATE_ORDER_SUCCESS, payload: data})    
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data.message
       : error.message;
       dispatch({type: CREATE_ORDER_FAIL, payload: message})
    }
   }
 }



 export const clearPaymentAndOrder = () => async (dispatch) => {
   dispatch({type: CLEAR_INITIATE_TRANSACTION});
   dispatch({type: CLEAR_CREATE_ORDER});
   dispatch({type: CLEAR_VERIFY_ORDER})
}