import { useContext, useEffect, useCallback, useState } from 'react';
import {FaTimes, FaBuilding,FaArrowLeft} from "react-icons/fa"
import { RiEBike2Fill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { ShipmentPrice } from "../../Actions/ShipmentPrice";
import { DisplayContext } from '../../AppContexts/DisplayContext';
import { getUrl } from '../../helpers/getUrl';

const DoorDeliveryModal = ({price, vehicleType,check, item}) => {
    const [locationError, setLocationError] = useState("")
    const {handleCheck, showDeliveryOption, closeModal, email, phone, address, handleMenuSwitch, stationId, senderLat,senderLong, senderName, senderPhoneNumber, senderStationId, senderAddress, itemWeight,itemName, receiverCoordinate, qty, senderLocality, fullName} = useContext(DisplayContext)
    const dispatch = useDispatch()
    const { shipment_price_loading, shipment_price,shipment_price_error} = useSelector(state => state.ShipmentPriceReducer);
    const checkShipmentCode = shipment_price && shipment_price.code;
    const checkShipCodeSuccess = shipment_price && shipment_price.results && shipment_price.results.Code
    
    const dataToSubmit = {
        "ReceiverName":  fullName,
        "ReceiverPhoneNumber": phone && phone,
        "ReceiverEmail": email && email,
        "ReceiverAddress": address && address,
        "ReceiverStationId": stationId && stationId,
        "ReceiverLocation": {
            "Latitude": receiverCoordinate?.lat && receiverCoordinate.lat.toString(), 
            "Longitude": receiverCoordinate?.lng && receiverCoordinate.lng.toString()
        },
        "Merchants": item?.map((product)=> {
             return    {
                "CustomerCode": getUrl("ACC001164", "IND265936") ,  
                "SenderName": product?.merchant_first_name + " " + product?.merchant_last_name,
                "SenderPhoneNumber": product?.merchant_phone_number,
                "SenderStationId": product?.merchant_station_id,
                "SenderLocality": product?.locality,
                "SenderAddress": product?.product_address, 
                "SenderLocation": {
                    "Latitude": product?.lat.toString(), 
                    "Longitude": product?.long.toString()
                },
                "PaymentType": "Cash",
                "PreShipmentItems": [
                    { "SpecialPackageId": "0", "Quantity": product?.quantity.toString(), "Weight": product?.weight || "0.5", "ItemType": "Normal", "WeightRange": "0", "ItemName": product?.productName, "Value": (product?.price) * (product?.quantity), "ShipmentType": "Regular" }
                ]
            }
        }),
        "PickupOptions": "HOMEDELIVERY",
        "IsHomeDelivery": true,
        "VehicleType": vehicleType?.toUpperCase() ||  "BIKE",
        "PaymentType": "Cash"
    }

    const getShippingFee = () => {
        if(check !== ""){
           dispatch(ShipmentPrice(dataToSubmit));
        }
    }
    const locationErrors = useCallback(() => {
        if(shipment_price_error && shipment_price_error === "The Mapping of Route to Zone does not exist"){
            setLocationError("This address is not within GIG home delivery locations, please use the GIG Pickup location or choose another location")
        }
    }, [shipment_price_error])
useEffect(() => {
    locationErrors()
}, [locationErrors])
    const goToPaymentHome = useCallback(() => {
        if(checkShipmentCode === 200 &&  checkShipCodeSuccess === "200" && showDeliveryOption === 4){
            handleMenuSwitch(6)
        }
    }, [checkShipCodeSuccess,checkShipmentCode,showDeliveryOption]);
    useEffect(() => {
        goToPaymentHome()
    }, [goToPaymentHome])
  
    return (
        <div className={showDeliveryOption === 4 ? "modals-container door-delivery-modal" : "modals-container-hide"}>
            <div className="card card-modal-content">
                <div className="card-header card__header"><span onClick={() => handleMenuSwitch(1)}><FaArrowLeft /></span><span className="checkout-header">Checkout</span><span className="close-modal"><FaTimes  onClick={closeModal}/></span></div>
                <div className="card-body">
                    <p className="card__title">
                        Select a delivery address
                    </p>
                        <div className="delivery-container">
                            <div className="icon_container">
                                <RiEBike2Fill className="radio__icon"/>
                            </div>
                            <label className="radio-c" htmlFor="home_delivery_details">
                            <div className="delivery_details_holder">
                                <div className="delivery_name">{fullName}</div>
                                <div className="delivery_address">{address}</div>
                                <div className="delivery_email">{phone} | {email}</div>
                            </div>
                               <input type="radio" checked name="delivery-details-door" value="door-delivery-values" className="delivery_radio"  onChange={handleCheck} id="home_delivery_details"/>
                               <div className="delivery__radio_btn"></div>
                            
                            </label>
                        </div>
                    <div className="mobile-delivery-hr"></div>
                        <div className="delivery-container">
                       
                            <div className={showDeliveryOption === 4 ? "img-container-disabled delivery_icon_container" : "delivery_icon_container"}>
                                <FaBuilding className={showDeliveryOption === 4 ? "media-img-disabled " : ""}/>
                            </div>
                            <label className="radio-c" htmlFor="home_delivery_details">
                                <div className="delivery-details">
                                    <div className={showDeliveryOption === 4 ? "delivery_title_name delivery-title-disabled" : "delivery_title name"}>GIGL Experience center</div>
                                    <div className={showDeliveryOption === 4 ? "delivery-title-disabled delivery_title_address" : "delivery_title_address"}>Choose this option to pick-up from any GIGL experience center close to you</div>
                                </div>
                                <div>
                                <input type="radio" value="pick-up-value" name="delivery-details" className="delivery_radio_disabled" onChange={handleCheck} disabled={showDeliveryOption === 4}/>
                                </div>
                            </label>
                        </div>
                  
                    
                    <div className="process-btn" onClick={getShippingFee}>
                       <button>{shipment_price_loading ? <div className="fetch-shipment"><div style={{width: "1rem !important", height: "1rem !important"}}
                      className="spinner-border shipment-spinner" role="status"
                    ></div> <span>Fetching shipment price</span></div> : "Proceed to Payment"}</button> 
                    </div>
                    {locationError && 
                      <div id="error-location">
                        <div className="text-center error-location-text" style={{fontSize: "12px"}}>{locationError}</div>
                        <div className="back-navigation"onClick={() => {handleMenuSwitch(2); setLocationError("")}}>
                            <button className="back-btn-navigate-address-one" >Enter Alternative Address</button>
                        </div>
                        <div className="back-navigation"   onClick={() => {handleMenuSwitch(3); setLocationError("")}}>
                            <button className="back-btn-navigate-address-two" >Use GIGL pickup centres</button>
                      </div>
                    
                </div>
                }
                </div>
            </div>
        </div>
    )
}

export default DoorDeliveryModal;