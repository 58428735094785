import { useContext, useState, useEffect } from 'react';
import {FaArrowLeft} from "react-icons/fa";
import "./mobile.css"
import { DisplayContext } from '../../../AppContexts/DisplayContext';
import "./mobile.css"
import AppDownloadSection from './AppDownloadSection';
import { AppContext } from '../../../AppContexts/AppContext';
import { useSelector, useDispatch } from 'react-redux';
import { RegisterGuest } from '../../../Actions/RegisterGuest';
const PickupDeliveryDetailsMobile = () => {
    const [allError, setAllError] = useState("")
    const {showMobileDeliveryOption, email, setEmail, phone, setPhone, handleMobileMenuSwitch, setShowMobileDeliveryOption,emailValidation, phoneValidation, setBuyerId, fullName,setFullName}   = useContext(DisplayContext);
    const { mobilePickupPhoneRef, mobilePickupEmailRef,handleErrorForMobilePickup, genPassword}  = useContext(AppContext)
    const { guest_registered} = useSelector(state => state.RegisterGuestReducer);
    const dispatch = useDispatch(null)
    const getNames = fullName && fullName.trim().split(/\s+/);
    const first_name = getNames && getNames.length > 0 && getNames[0]
    const last_name = getNames && getNames.length > 2 ? getNames[1] + " " + getNames[2] : getNames && getNames.length > 1 ? getNames[1] : "";

const handleDelivery = (e) => {
    e.preventDefault()
    if(!fullName || !email || !phone){
         setAllError("All inputs fields are required*");
         return
        
     }
      
    if(!emailValidation || !phoneValidation){
        setAllError("Invalid email or phone number");
        return
    }

    dispatch(RegisterGuest({first_name, last_name, email, phone, password: genPassword()}))
    
    setShowMobileDeliveryOption(9)
     
}

useEffect(() => {
    const timeout = setTimeout(() => {
        setAllError("")
    }, 3000);
return () => clearTimeout(timeout)
}, [])

    return <div className={showMobileDeliveryOption === 8 ? "d-block delivery__details__mobile" : "d-none"}>
        {  guest_registered && guest_registered.results ? setBuyerId(guest_registered.results._id) : ""}
            
            <div className="back__navigation__mobile"><span onClick={() => handleMobileMenuSwitch(2)}><FaArrowLeft /></span><span className="checkout__header">Delivery details</span></div>
            <div className="details_body">
                <div className="form-alignment">
                 <form>
                     <div className="delivery-details-mobile-input">
                         <label>Full Name</label>
                         <input type="text" placeholder="First Name" className="input-text-mobile" onChange={(e) => setFullName(e.target.value)} value={fullName}/>
                     </div>
                     <div className="delivery-details-mobile-input mt-2">
                         <label>Email address</label>
                         <input type="email" placeholder="example@giglogistics.ng" className="input-text-mobile" onChange={(e) => setEmail(e.target.value)} value={email} name="email"   ref={mobilePickupEmailRef} onKeyUp={() => handleErrorForMobilePickup(mobilePickupEmailRef.current, mobilePickupEmailRef.current?.name)} required="required"/>
                         <p>Email is invalid</p>
                     </div>
                     <div className="delivery-details-mobile-input  mt-2">
                         <label>Phone Number</label>
                         <input type="tel" placeholder="080********" className="input-text-mobile" onChange={(e) => setPhone(e.target.value)} value={phone} required="required"  name="telephone" ref={ mobilePickupPhoneRef} onKeyUp={() => handleErrorForMobilePickup( mobilePickupPhoneRef.current,  mobilePickupPhoneRef.current?.name)}/>
                         <p>Phone number must be an 11 digit number</p>
                     </div>
                 
                 </form>
                 {allError && <p className="text-danger text-center">{allError}</p>}
                <div className= "location-btn">
                     <button onClick={handleDelivery}>Submit</button>
                </div>
                </div>
            </div>
            {/* <AppDownloadSection /> */}
    </div>
}



export default PickupDeliveryDetailsMobile