// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Signup_container__S3jzj{
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;
}

.Signup_register-form__CQ8FG{
    width: 100%;
    margin: auto;
    margin-left: 1rem;

}

.Signup_register-form__CQ8FG label{
    /* margin-left: .5rem; */
    color: #192150;
}

.Signup_input-box__CKJtI{
    /* border: none; */
    border: 1px solid #B3B3B3;
    border-radius: 5px;
    width: 80%;
    /* margin-left: .5rem; */
    margin-bottom: 1rem;
    padding: .5rem .5rem;
    padding-right: 0px !important;
    outline: none;
}

.Signup_register-form__CQ8FG button{
    /* margin-left: .5rem; */
    border-radius: 0.625rem;
    padding: 0.5rem;
    width: 20%;
}

.Signup_sign-img__5FwhX{
    display: none;
}

.Signup_register-form__CQ8FG p{
    color: #192150;
    text-align: start;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

@media screen and (min-width: 768px) {
    .Signup_container__S3jzj{
        display: grid;
    }

    .Signup_sign-img__5FwhX{
        display: block;
    }

    .Signup_sign-img__5FwhX img{
        width: 70%;
        margin-top: 4rem;
    }

    .Signup_register-form__CQ8FG p{
        font-size: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/vaidationPage/Signup.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;;AAErB;;AAEA;IACI,wBAAwB;IACxB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;IACV,wBAAwB;IACxB,mBAAmB;IACnB,oBAAoB;IACpB,6BAA6B;IAC7B,aAAa;AACjB;;AAEA;IACI,wBAAwB;IACxB,uBAAuB;IACvB,eAAe;IACf,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,UAAU;QACV,gBAAgB;IACpB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container{\n    grid-template-columns: 1fr 1fr;\n    margin-top: 3rem;\n}\n\n.register-form{\n    width: 100%;\n    margin: auto;\n    margin-left: 1rem;\n\n}\n\n.register-form label{\n    /* margin-left: .5rem; */\n    color: #192150;\n}\n\n.input-box{\n    /* border: none; */\n    border: 1px solid #B3B3B3;\n    border-radius: 5px;\n    width: 80%;\n    /* margin-left: .5rem; */\n    margin-bottom: 1rem;\n    padding: .5rem .5rem;\n    padding-right: 0px !important;\n    outline: none;\n}\n\n.register-form button{\n    /* margin-left: .5rem; */\n    border-radius: 0.625rem;\n    padding: 0.5rem;\n    width: 20%;\n}\n\n.sign-img{\n    display: none;\n}\n\n.register-form p{\n    color: #192150;\n    text-align: start;\n    font-size: 0.875rem;\n    margin-top: 0.5rem;\n}\n\n@media screen and (min-width: 768px) {\n    .container{\n        display: grid;\n    }\n\n    .sign-img{\n        display: block;\n    }\n\n    .sign-img img{\n        width: 70%;\n        margin-top: 4rem;\n    }\n\n    .register-form p{\n        font-size: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Signup_container__S3jzj`,
	"register-form": `Signup_register-form__CQ8FG`,
	"input-box": `Signup_input-box__CKJtI`,
	"sign-img": `Signup_sign-img__5FwhX`
};
export default ___CSS_LOADER_EXPORT___;
