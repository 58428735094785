import { useSelector } from 'react-redux';

import { useEffect, useState, useRef, useContext } from 'react';
import ScrollToTop from '../../Components/UI/ScrollToTop';
import {getCartId} from '../../utility/utility';
import classes from './Cart.module.css';
import CartItem from './CartItem';
import Checkout from './Checkout';
import RecentViewed from './RecentlyViewed';
import { useGetCartItemsQuery } from '../../store/apiSlice';
import { Spinner } from 'react-bootstrap';
import { DisplayContext } from '../../AppContexts/DisplayContext';
import MobileDeliveryOptions from '../Product/MobileDevice/MobileDeliveryOptions';
import CheckoutModal from '../Product/MobileDevice/CheckoutModal';

const Cart = () => {
    const [showCheckout, setShowCheckout] = useState(false)
    // const cartId = JSON.parse(getCartId());
    const {handleMobileMenuSwitch}=useContext(DisplayContext)
    const cartId = getCartId();
    const {data, isLoading, isError, error} = useGetCartItemsQuery({cartId});
    const newError = error && JSON.stringify(error?.data)

    console.log(data, cartId);

    const checkoutModal=()=>{
        setShowCheckout(true)
        if(showCheckout){
            handleMobileMenuSwitch(2);

        }
        console.log("Hey, what's your name");
    }
  
    const results = data && data?.results
    const cartItems = results && results?.cart?.items
    const totalPrice = results && results?.cart?.totalCost
    
    let totalQuantity = 0
    for(let i=0; i < cartItems?.length; i++){
        let itemqty = cartItems[i].quantity
        totalQuantity += itemqty
    }
    return (
        <>
            {showCheckout? <MobileDeliveryOptions/>: <div>
            {isError && error && !cartId ? (
            <div className="error_detail_page">
                <div className="container alert alert-danger text-center" role="alert">
                {error  &&  JSON.parse(newError) !== undefined && JSON.parse(newError)["message"]?.includes("Cast to ObjectId failed for value") ? <div>Could not fetch cart data</div> : JSON.parse(newError)["message"]}
                </div>
            </div>
            ) : (
            <div className={classes.container}>
                <h1>Shopping Cart</h1>
                <div className={classes["container-top"]}>
                <div className={classes.cart}>
                    {isLoading && <div className={classes.spinner}><Spinner/></div>}
                    {!cartItems && !isLoading && <div className='text-center'>
                        <div>Your cart is empty</div>
                        <button>Start shopping</button>
                        </div>}
                    {cartItems && cartItems?.map((item)=> <CartItem item={item} key={item._id} />)}
                </div>
                <div className={classes.checkout}>
                    <Checkout totalCost={totalPrice} item={cartItems} totalQuantity={totalQuantity} handleClick={checkoutModal}/>
                </div>
                </div>
                <div className={classes["container-bottom"]}>
                <RecentViewed />
                </div>
                <ScrollToTop />
            </div>
            )}
            </div>}
            <CheckoutModal/>
            <MobileDeliveryOptions/>
        </>

    );
};

export default Cart;
