import { REGISTER_GUEST_USER_FAIL, REGISTER_GUEST_USER_REQUEST, REGISTER_GUEST_USER_SUCCESS } from '../constants/constants';
import { getUrl } from '../helpers/getUrl';

export function RegisterGuest (dataToSubmit) {
   return async (dispatch) => {
      try {
      dispatch({type: REGISTER_GUEST_USER_REQUEST});
       const res = await fetch(`${getUrl("https://user.thealphashops.com/api/v1", "https://userservice.click/api/v1")}/user/guest/new`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ACCEPT: "application/json"
          },
          body: JSON.stringify(dataToSubmit)
       })
        const data  = await res.json();
        if(data.error){
             return dispatch({type: REGISTER_GUEST_USER_FAIL, payload: data.message})
        }
        dispatch({type: REGISTER_GUEST_USER_SUCCESS, payload: data})    
      } catch (error) {
      const message =
      error.response && error.response.data
        ? error.response.data.message
        : error.message;
        dispatch({type: REGISTER_GUEST_USER_FAIL, payload: message})
      }
  }
}