import { VERIFY_ORDER_FAIL, VERIFY_ORDER_REQUEST, VERIFY_ORDER_SUCCESS } from "../constants/constants";
import { getUrl } from "../helpers/getUrl";
import { getAuthToken } from "../utility/utility";

export function VerifyOrder(dataToSubmit) {
  const token = getAuthToken() && JSON.parse(getAuthToken())

    return async (dispatch) => {
        dispatch({type: VERIFY_ORDER_REQUEST});
          try {
           const res = await fetch(`${getUrl("https://order.thealphashops.com/api/v1","https://orderservice.click/api/v1")}/transaction/verify`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": "Bearer " + token
              },
              body: JSON.stringify(dataToSubmit)
           })
            const data = await res.json();
            if(data.error){
                 return dispatch({type: VERIFY_ORDER_FAIL, payload: data.message})
            }
            dispatch({type: VERIFY_ORDER_SUCCESS, payload: data})    
          } catch (error) {
          const message =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
            dispatch({type: VERIFY_ORDER_FAIL, payload: message})
          }
    }
}