import {useEffect, useState, useContext} from "react"
import {FaTimes, FaArrowLeft} from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
  } from 'react-places-autocomplete';
import { AppContext } from "../../AppContexts/AppContext";
import { DisplayContext } from "../../AppContexts/DisplayContext";
import { useSelector, useDispatch } from 'react-redux';
import { RegisterGuest } from "../../Actions/RegisterGuest";
import { GetEta } from "../../Actions/GetEta";
import { formatDate } from "../../helpers/formatDate";

const DeliveryDetails = ({product}) => {
    const [localityErrorMsg, setLocalityErrorMsg] = useState("");
    const { loading, guest_registered} = useSelector(state => state.RegisterGuestReducer);
    const {get_eta_loading,get_eta_error,eta} =useSelector (state => state.getEta)
    const {showDeliveryOption, closeModal, handleMenuSwitch,  email, setEmail, phone, setPhone, address, setAddress, state, setState, home_delivery_loading, home_delivery, home_delivery_error, local_station_error, local_station, setReceiverCoordinate, setStationId,  emailValidation, phoneValidation, fullName, setFullName, setBuyerId, setRecieverLGA,setRecieverCity} = useContext(DisplayContext);
    const {phoneDoorDeliveryRef, emailDoorDeliveryRef, handleErrorForDesktopDoorDelivery, genPassword, isEmpty} = useContext(AppContext)
    const departure_state = product?.results?.locality;
    const getNames = fullName && fullName.trim().split(/\s+/);
    const first_name = getNames && getNames.length > 0 && getNames[0]
    const last_name = getNames && getNames.length > 2 ? getNames[1] + " " + getNames[2] : getNames && getNames.length > 1 ? getNames[1] : "";
    const dispatch = useDispatch(null);
    
    const [allError, setAllError] = useState("")
    const handleSelect = async  value => {
        const result = await geocodeByAddress(value);
        const latlng = await getLatLng(result[0])
        setAddress(value)
        setState(result)
        setReceiverCoordinate(latlng);
     
   }

//    const errMsg = validate(email)

   // Convert array of address to a single array from the google places result

   const getName = state && state.map(state => state.address_components).flat();

   // get delivery home addresses
   const getLocality = home_delivery && home_delivery.results && home_delivery.results.Object;
   const LGAName = []
   getLocality && getLocality.map(locality => {
     return LGAName.push(locality.LGAName)
    });

      // Get delivery locality from google
   let localityName, locality;

   if(getName) {
        for (let name of getName) {
            locality = name["types"].includes("administrative_area_level_2")
            if(locality){
               localityName = name.long_name
            }
        
        }
   }

 
 // Get delivery state from google
   let stateName, locality2;
    if(getName){
        for (let name of getName) {
            locality2 = name["types"].includes("administrative_area_level_1")
            if(locality2){
            stateName = name.long_name
            }
    
    }
    }
 
   const getStationData = local_station && local_station.results && local_station.results.Object;

   const stationStateName = getStationData && getStationData.find(station => station?.StationName?.toLowerCase() === stateName?.toLowerCase());
   const destination_state = stationStateName && stationStateName.StateName

   let receiverStationId = stationStateName && stationStateName.StationId;

const handleDelivery = () => {
    if(!fullName || !email || !phone || !address){
         setAllError("All inputs fields are required*");
         return;
    }
    if(!emailValidation || !phoneValidation){
        setAllError("Invalid email or phone number");
        return
    }
    dispatch(RegisterGuest({first_name, last_name, email, phone, password: genPassword()}));
    if(!loading){
        if(stationStateName && stationStateName?.StateName !== undefined){
            handleMenuSwitch(4)    
        }else {
            setLocalityErrorMsg("This address is not within GIGL home delivery locations, please use alternative address or use the GIGL Pickup location (Click the back button)")
        }
    }
}

const handleAddressChange = ()=> {
    setLocalityErrorMsg("");
   
}

   useEffect(() => {
      setStationId(receiverStationId)
      setRecieverLGA(localityName);
      setRecieverCity(stateName)
   }, [receiverStationId, setStationId, localityName, stateName, setRecieverCity, setRecieverLGA]);
   useEffect(() => {
        if(destination_state !== undefined  && departure_state !== undefined ){
             dispatch(GetEta({ "departure_state": departure_state, 
             "destination_state":  destination_state}))
        }
   }, [destination_state, departure_state])

    return (
        <div className={showDeliveryOption === 2 ? "modals-container delivery-modals" : "modals-container-hide"}>  
           {  guest_registered && guest_registered.results ? setBuyerId(guest_registered.results._id) : ""}
             
            <div className="card card-modal-content-location">
            
                <div className="card-header card__header"><span onClick={() => {handleMenuSwitch(1)}}><FaArrowLeft /></span><span className="checkout-header">ENTER DELIVERY DETAILS</span><span className="close-modal"><FaTimes  onClick={closeModal}/></span></div>
                <div className="card-body">
                 
                    {
                        home_delivery_error && <div className="container alert alert-danger" role="alert">
                        {home_delivery_error}
                    </div>
                    }
                    {home_delivery_loading ? <div style={{width: "2rem", height: "2rem"}}
                      className="spinner-border shipment-spinner delivery-spinner"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div> : home_delivery_error ? <div className="container alert alert-danger" role="alert">
                        {local_station_error}
                    </div> :
                    <>
                     <form>
                         <div className="delivery-details-input">
                             <label>Full Name</label>
                             <input type="text" placeholder="Fisrt Name" className="input-text" onChange={(e) => {setFullName(e.target.value);  setAllError("");setLocalityErrorMsg("")}} value={fullName}/>
                         </div>
                         <div className="delivery-details-input mt-2">
                             <label>Email address</label>
                             <input type="email" placeholder="example@giglogistics.ng" className="input-text" onChange={(e) => {setEmail(e.target.value);  setAllError("");setLocalityErrorMsg("")}} value={email} ref={emailDoorDeliveryRef} name="email" onKeyUp={() => handleErrorForDesktopDoorDelivery(emailDoorDeliveryRef.current, emailDoorDeliveryRef.current.name)} required="required"/>
                             <p>Email is invalid</p>
                         </div>
                         <div className="delivery-details-input  mt-2">
                             <label>Phone Number</label>
                             <input type="tel" placeholder="080********" className="input-text" onChange={(e) => setPhone(e.target.value)} value={phone} ref={phoneDoorDeliveryRef} required="required" name="telephone" onKeyUp={() => handleErrorForDesktopDoorDelivery(phoneDoorDeliveryRef.current, phoneDoorDeliveryRef.current.name)}/>
                             <p>Phone number must be an 11 digit number</p>
                         </div>
                         <div className="delivery-details-input  mt-2">
                             <label>Delivery Address</label>
                             <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
                                 {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                     <>
                                    <textarea {...getInputProps({placeholder: "Delivery address", className: "input-text"})} onFocus={handleAddressChange}></textarea>
                                  
                                       {loading ? <div>Loading...</div> : null}
                                       {suggestions.map((suggestion, index) => {
                                           const style = {
                                               backgroundColor: suggestion.active ? "#08ffc8": "#fff",
                                               cursor: "pointer",
                                               fontSize: "14px",
                                               margin: "2px 0px"

                                           }
                                           
                                           return  <div key={index} {...getSuggestionItemProps(suggestion, {style})}>{suggestion.description}</div>
                                        })}
                                    
                                    </>
                                 )}
                                 
                             </PlacesAutocomplete>
                         </div>
                         <div className="delivery-details-input  mt-2">
                             <label>Service Station</label>
                             <div className="station-name">{stationStateName && stationStateName.StateName}</div>
                         </div>
                     
                     </form>
                     {allError && <p className="text-danger text-center">{allError}</p>}
                     {get_eta_loading && destination_state !== undefined && departure_state !== undefined && <div   className="loader-style-eta">                           
                                <div className="spinner-border loader-spinner-eta" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="loading-text">Please waiting...</div>
                            </div>}
                            {
                               !get_eta_loading && !isEmpty(eta) && destination_state !== undefined && departure_state !== undefined ?  <div className="eta-display"><MdInfoOutline /> {`Expected time of arrival is ${formatDate(eta.results)}`}</div>  : null
                            }
                    <div className= "location-btn" onClick={handleDelivery}>
                        <button disabled={get_eta_loading}>{get_eta_loading && eta  ? "Please wait..." : "Submit"}</button>
                    </div>
                       </>
                   }


                   {localityErrorMsg && <><div className="text-center error-location-text" style={{fontSize: "12px"}}><small>{localityErrorMsg}</small></div>
                    <div className="back-navigation"   onClick={() => {handleMenuSwitch(1); setLocalityErrorMsg("")}}>
                        <button className="back-btn-navigate" ><FaArrowLeft />&nbsp;Go back</button>
                    </div>
                    </>}
                </div>
            </div>
        </div>
    )
}

export default DeliveryDetails;