import { useState, useEffect } from 'react';
import classes from './NewPassword.module.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useNewPasswordMutation } from '../../store/apiSlice';
import { getOtp } from '../../utility/utility';

const NewPassword = () => {
const [password, setPassword] = useState("")

const navigate = useNavigate()

const otp = getOtp()

const [getNewPassword, {data, isLoading, isError, error, isSuccess}] = useNewPasswordMutation()

const submitPassword =(event)=>{
    event.preventDefault()
    getNewPassword({
        otp: otp,
        password: password,
        user_type: "user"
    });
}

useEffect(() => {
    if(!isError && isSuccess && data){
        navigate('/login')
    }
}, [isError, isSuccess, data])

    return(
        <div className={classes.page}>
            <div className={classes.container}>
                <div>
                    <h1>Password</h1>
                    <p>Create your new password here to secure your account</p>
                </div>
                <form onSubmit={submitPassword}>
                    <label htmlFor="password">Password</label><br/>
                    <input type="password" name="password" id="password" placeholder="Password" onChange={e=> setPassword(e.target.value)}/><br/>
                    <label htmlFor="Cpassword">Confirm Password</label><br/>
                    <input type="password" name="Cpassword" id="Cpassword" placeholder="Confirm Password" onChange={e=> setPassword(e.target.value)}/><br/>
                    <button type="submit">Create Password</button>
                </form>
            </div>
        </div>
    )
}

export default NewPassword;