import { Link } from "react-router-dom";
import classes from "./ErrorPage.module.css";

const ErrorPage =()=>{
    return(
        <div className={classes.container}>
            <h2>Page Not Found</h2>
            <h3>We could not find the page you are Looking for</h3>
            <p>Go To <Link to='/'>HomePage</Link></p>
        </div>
    )
}

export default ErrorPage;