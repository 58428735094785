import { useContext, useEffect, useRef, useState } from 'react';
import classes from './Checkout.module.css';
import { AppContext } from '../../AppContexts/AppContext';
import { DisplayContext } from '../../AppContexts/DisplayContext';
import { useDispatch, useSelector } from 'react-redux';
import numbro from 'numbro';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../Product/Modal';
import DeliveryDetailMobile from '../Product/MobileDevice/DeliveryDetailMobile';
import DoorDeliveryModal from '../Product/DoorDeliveryModal';
import SelectLocation from '../Product/SelectLocation';
import PickUpModal from '../Product/PickUpModal';
import PaystackPaymentModal from '../Product/PaystackPayment';
import ServiceCenterPayment from '../Product/ServiceCenterPayment';
import SuccessModal from '../Product/SuccessModal';
import FailedModal from '../Product/FailedModal';
import DeliveryDetails from '../Product/DeliveryDetails';
import CheckoutModal from '../Product/MobileDevice/CheckoutModal';
import MobileDeliveryOptions from "../Product/MobileDevice/MobileDeliveryOptions";
import DoorDeliveryMobile from "../Product/MobileDevice/DoorDeliveryMobile";

import { GetServiceCenterDetails } from '../../Actions/ServiceCenterDetails';
import { getAuthToken } from '../../utility/utility';
import PickupDeliveryDetailsMobile from '../Product/MobileDevice/PickupDeliveryDetailsMobile';
import ServiceCenterMobile from '../Product/MobileDevice/ServiceCenterMobile';
import PickupDelivery from '../Product/MobileDevice/PickupDelivery';
import ServiceCenterPaymentSection from '../Product/MobileDevice/ServiceCenterPaymentSection';
import Payment from '../Product/MobileDevice/Payment';
import TransactionSuccess from '../Product/MobileDevice/TransactionSuccess';
import TransactionFailed from '../Product/MobileDevice/TransactionFailed';

const Checkout =({totalCost, item, totalQuantity, handleClick})=>{
    const [validateError, setValidateError] = useState("");
    // const {local_station_error, local_station, local_station_loading} = useSelector(state => state.LocalStationReducer);

    const ref = useRef();
    const myVideo = useRef()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {numberSeperator,copyToClipBoard,copySuccess,copied,setOpenShareModal,shareOnSocialMedia, setRecentUrl} = useContext(AppContext);
    const {showDeliveryOption, setShowDeliveryOption, check, handleCheck, handleMenuSwitch, closeModal, name, setName, email, setEmail, phone, setPhone, address, setAddress, qty, setQty, color, setColor, size, setSize,  local_station_error, local_station,  local_station_loading, stationId, styleColor, setStyleColor, styleSize, setStyleSize, senderLat,setSenderLat,  senderLong, setSenderLong, senderName, setSenderName, senderPhoneNumber, setSenderPhoneNumber, senderStationId, setSenderStationId, senderLocality, setSenderLocality, senderAddress, setSenderAddress, itemWeight, setItemWeight, customerCode, senderNeighborhood, setSenderNeighborhood,itemName, setItemName, setCustomerCode, receiverCoordinate, setReceiverCoordinate, locationValues, setLocationValues, setProductImg, setProductId, itemColor, setItemColor, verifyOrder, setVerifyOrder, handleMobileMenuSwitch, showMobileDeliveryOption,  setUserId, initialQty, setInitialQty } = useContext(DisplayContext)
    const token = getAuthToken() && JSON.parse(getAuthToken())

    const totalPrice = totalCost
    const product = item

    console.log("checkout " ,product);
    const originalUrl =  window.location.origin;
    const results = product && product.results;
    const prodId = results && results._id;
    const store = results && results.store;
    const storeName = store && store.name;
    const storeId = store && store.storeId
    const slug = store && store.slug;
    const storeLogo = store && store.picture;
    const alphaCode = results && results.alphaCode
    const productName = results && results.productName;
    const price = results && results.price;
    const newColor = itemColor && itemColor.split(",")
    const itemSize = results && results.size;
    const sizeCheck = itemSize && itemSize[0] && itemSize[0].value
    const frontView = results && results.front_view;
    const rightView = results && results.right_view;
    const leftView = results && results.left_view;
    const rearView = results && results.rear_view;
    const quantity = results && results.quantity;
    const merchantAddress = results && results.address;
    const merchantCode = results && results.customer_code;
    const merchantFirstName = results && results.merchant_first_name
    const merchantLastName = results && results.merchant_last_name
    const merchantEmail = results && results.merchant_email
    const merchantPhoneNumber = results && results.merchant_phone_number
    const merchantStationId = results && results.merchant_station_id
    const merchantLocality = results && results.locality;
    const merchantNeighborhood = results && results.neighborhood;
    const merchantLat = results && results.lat
    const merchantLong = results && results.long
    const merchantItemWeight = results && results.weight && results.weight.replace(/[^\d.-]/g, '');
    const user_id = results && results.createdBy
    const availableColor = results && results.color
    const prodImage = results && results.front_view;
    const prodDescription = results && results.description;
    const getReviews = results && results.ratings_reviews;
    const vehicleType = results && results.vehicleType
    const giftWrapping = results && results.gift_wrapping;
    const channelName = results && results.channel_links && results.channel_links[0] && results.channel_links[0].name;
    const videoUrl = results && results.watermark_video;
    const storeLink = `${originalUrl}/stores/${slug}`;
    const productLink = `${originalUrl}/${prodId}`;
    const category = results && results.category;
    const categoryColor = category && category.color;
    const categorySize = category && category.sizet

    const isActiveSize = index => {
        return styleSize === index ? "selected-active-size-btn" : "selected-size-btn"
    }

    const isActiveColor = index => {
        return styleColor ===  index ? "show-active-color" : "hide-active-color"
    }
    const colorVariation = (color) => {
        return color === "#FFFFFF" ? "color-check-white-bg"  : color === "#F7FBFF" ? "color-check-white-bg" : color === "#F5F5F5" ? "color-check-white-bg" : color === "#E5E5E5"  ? "color-check-white-bg" : color === "#FFEA00" ? "color-check-white-bg" : color === "#FFBF00" ? "color-check-white-bg" : color === "#FBCEB1" ? "color-check-white-bg" : color === "#F5F5DC" ? "color-check-white-bg"  : color === "#EADDCA" ? "color-check-white-bg"  : color === "#F5F5DC" ? "color-check-white-bg" : color === "#E1C16E" ? "color-check-white-bg" : color === "#FDDA0D" ? "color-check-white-bg"  : color === "#FFFF8F" ? "color-check-white-bg" : color === "#DFFF00" ? "color-check-white-bg"  : color === "#E4D00A" ? "color-check-white-bg" : color === "#FFF8DC" ? "color-check-white-bg" : color === "#FFFDD0" ? "color-check-white-bg" : color === "#FCF55F" ? "color-check-white-bg"  : color === "#FFFFF0" ? "color-check-white-bg" : color === "#F8DE7E" ? "color-check-white-bg" : color === "#F0E68C" ? "color-check-white-bg" : color === "#FAFA33" ? "color-check-white-bg"  : color === "#FBEC5D" ? "color-check-white-bg" : color === "#F4BB44" ? "color-check-white-bg" : color === "#FADA5E" ? "color-check-white-bg" : color === "#FFDEAD" ? "color-check-white-bg" : color === "#ECFFDC" ? "color-check-white-bg" : color === "#FFFAA0" ? "color-check-white-bg" : color === "#FFE5B4" ? "color-check-white-bg" : color === "#C9CC3F" ? "color-check-white-bg" : color === "#B4C424" ? "color-check-white-bg" : color === "#93C572" ? "color-check-white-bg" : color === "#F3E5AB" ? "color-check-white-bg" : color === "#C4B454" ? "color-check-white-bg"  : color === "#F5DEB3" ? "color-check-white-bg" : color === "#FFFF00" ? "color-check-white-bg" : color === "#FFFF99" ? "color-check-white-bg" : color === "#FFAA33" ? "color-check-white-bg" : color === "#FFFF00"  ? "color-check-white-bg"  : "color-check-other-bg" 
    }

    const validateOrderCriteria = (func,index) => {
        if(itemSize.length > 0 && itemSize[0].value !== "" && categorySize && size === ""){
        setValidateError("Please choose a size.*");
        return;
        }else if(categorySize && itemSize.length > 0 && color === ""){
        setValidateError("Please choose a color.*");
        return;
        }else if(qty === 0){
        setValidateError("select item quantity.*");
        return;
        }else {
            func(index)
        }
    }

    // useEffect(() => {
    //     if(id){
    //         dispatch(Product(id))
    //     }
     
    // }, [dispatch, id]);
    // useEffect(() => {
    //     const data = {id,qty};
    //      dispatch(ShipmentPriceByQty(data))
    // }, [dispatch,qty,id]);

    const showCheckoutModal =(e)=>{
        e.preventDefault();
        if(token === '' || token === undefined || token === null || !token){
            setRecentUrl('cart')
           navigate('/login') 
        }else{
            handleMenuSwitch(1)
        }
    }

    

    
    return(
        <>
            <div className={classes.container}>
                <div className={classes.cover}>
                    <button onClick={showCheckoutModal}>CHECKOUT</button>
                    <button onClick={() => handleClick()}>CHECKOUT</button>
                    <button><Link to='/'>CONTINUE SHOPPING</Link></button>
                </div>
                <div className={classes.figure}>
                    <div>
                        <span>Item ({totalQuantity})</span>
                        <span>&#x20A6; {numbro(totalPrice).format({thousandSeparated: true})}</span>
                    </div>
                    {/* <div>
                        <span>Delivery fee</span>
                        <span>&#x20A6; {numbro(3500).format({thousandSeparated: true})}</span>
                    </div> */}
                </div>
                <div className={classes.total}>
                    <h3>Total</h3>
                    <h3>&#x20A6; {numbro(totalPrice).format({thousandSeparated: true})}</h3>
                </div>
            </div>
            {/* <CheckoutModal itemSize={itemSize} isActiveSize={isActiveSize}  quantity={quantity} colorVariation={colorVariation} isActiveColor={isActiveColor} newColor={newColor} giftWrapping={giftWrapping} availableColor={availableColor} color={color} initialQty={initialQty} setInitialQty={setInitialQty} price={price} categoryColor={categoryColor} categorySize={categorySize} validateOrderCriteria={validateOrderCriteria} setValidateError={setValidateError} validateError={validateError}/> */}
            <MobileDeliveryOptions price={price} qty={qty} vehicleType={vehicleType}/>
            <DeliveryDetailMobile product={product}/>
            <DoorDeliveryMobile  vehicleType={vehicleType}/>
            <Payment price={price} product={product} results={results} channelName={channelName} vehicleType={vehicleType}/>
            <PickupDeliveryDetailsMobile vehicleType={vehicleType} product={product}/>
            <ServiceCenterMobile vehicleType={vehicleType} product={product}/>
            <PickupDelivery vehicleType={vehicleType} product={product}/>
            <ServiceCenterPaymentSection price={price} product={product} results={results} channelName={channelName} vehicleType={vehicleType}/>
            {showMobileDeliveryOption === 6 && <TransactionSuccess product={product} />}
            {showMobileDeliveryOption === 7 && <TransactionFailed product={product} />}

            <Modal/> 
            {/* <VideoModal setShowVideoModal={setShowVideoModal} showVideoModal={showVideoModal}>
                <VideoCard setShowVideoModal={setShowVideoModal} showVideoModal={showVideoModal} videoUrl={videoUrl}/>
            </VideoModal> */}
            <DeliveryDetails product={product}/> 
            <DoorDeliveryModal price={price}  vehicleType={vehicleType} item={product}/>
            <PaystackPaymentModal handleCheck={handleCheck} check={check} handleMenuSwitch={handleMenuSwitch} setShowDeliveryOption={setShowDeliveryOption} showDeliveryOption={showDeliveryOption} closeModal={closeModal} price={price} quantity={quantity} name={name} email={email} phone={phone} qty={qty} address={address}  senderLat={senderLat} senderLong={senderLong} senderName={senderName} senderPhoneNumber={senderPhoneNumber} senderStationId={senderStationId} senderLocality={senderLocality} senderAddress={senderAddress} itemWeight={itemWeight} itemName={itemName} customerCode={customerCode} senderNeighborhood={senderNeighborhood}  receiverCoordinate={receiverCoordinate} setReceiverCoordinate={setReceiverCoordinate} color={color} size={size}  prodImage={frontView} prodId={prodId} verifyOrder={verifyOrder} setVerifyOrder={setVerifyOrder} locationValues={locationValues} product={product} channelName={channelName} vehicleType={vehicleType} item={product} totalPrice={totalCost}/>
            <SelectLocation handleCheck={handleCheck} check={check} handleMenuSwitch={handleMenuSwitch} setShowDeliveryOption={setShowDeliveryOption} showDeliveryOption={showDeliveryOption} closeModal={closeModal}  name={name} setName={setName} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} address={address} setAddress={setAddress} price={price} quantity={quantity} qty={qty} local_station_error={local_station_error} local_station={local_station} local_station_loading={local_station_loading}  senderLat={senderLat} senderLong={senderLong} senderName={senderName} senderPhoneNumber={senderPhoneNumber} senderStationId={senderStationId} senderLocality={senderLocality} senderAddress={senderAddress} itemWeight={itemWeight} itemName={itemName} customerCode={customerCode} senderNeighborhood={senderNeighborhood} receiverCoordinate={receiverCoordinate} setReceiverCoordinate={setReceiverCoordinate}  locationValues={locationValues} setLocationValues={setLocationValues} vehicleType={vehicleType} product={product}/>
            <PickUpModal handleCheck={handleCheck} check={check} handleMenuSwitch={handleMenuSwitch} setShowDeliveryOption={setShowDeliveryOption} showDeliveryOption={showDeliveryOption} closeModal={closeModal} price={price} quantity={quantity} qty={qty} address={address}  senderLat={senderLat} senderLong={senderLong} senderName={senderName} senderPhoneNumber={senderPhoneNumber} senderStationId={senderStationId} senderLocality={senderLocality} senderAddress={senderAddress} itemWeight={itemWeight} itemName={itemName} customerCode={customerCode} senderNeighborhood={senderNeighborhood} receiverCoordinate={receiverCoordinate} setReceiverCoordinate={setReceiverCoordinate} name={name} email={email} phone={phone} locationValues={locationValues}/>
            <ServiceCenterPayment handleCheck={handleCheck} check={check} handleMenuSwitch={handleMenuSwitch} setShowDeliveryOption={setShowDeliveryOption} showDeliveryOption={showDeliveryOption} closeModal={closeModal} price={price} quantity={quantity} name={name} email={email} phone={phone} qty={qty} address={address}  senderLat={senderLat} senderLong={senderLong} senderName={senderName} senderPhoneNumber={senderPhoneNumber} senderStationId={senderStationId} senderLocality={senderLocality} senderAddress={senderAddress} itemWeight={itemWeight} itemName={itemName} customerCode={customerCode} senderNeighborhood={senderNeighborhood}  receiverCoordinate={receiverCoordinate} setReceiverCoordinate={setReceiverCoordinate} color={color} size={size}  prodImage={frontView} prodId={prodId} stationId={stationId} verifyOrder={verifyOrder} setVerifyOrder={setVerifyOrder} locationValues={locationValues} product={product} channelName={channelName} vehicleType={vehicleType}/>
            <SuccessModal showDeliveryOption={showDeliveryOption} closeModal={closeModal} />
            <FailedModal showDeliveryOption={showDeliveryOption} closeModal={closeModal}/>
        </>
    )
}

export default Checkout